import React, { useEffect, useState, useMemo, useCallback } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import ViewServices from '../../core/services/view-service/view-service';
import { evalJSONContext, evalJSONDomain } from '../../util/util';
import useHeaderStore from '../../store/header';
import useSidebar from '../../store/sidebar';
import { useQuery } from '@tanstack/react-query';
import useSelectStore from '../../store/select';
import Many2ManyView from '../form/view/many2many-view';
import ModalDetail from '../modal/ModalDetail';

const CustomMenuList = (props) => {
  const { options, children, selectProps } = props;
  const { setIsShowModalMany2Many, searchable } = selectProps;
  const { t } = useTranslation()

  const limitedChildren = React.Children.toArray(children).slice(0, 10);
  return (
    <components.MenuList {...props}>
      {limitedChildren}
      {options?.length > 0 && (options?.length > 10 || searchable) && (
        <button
          type="button"
          onClick={() => setIsShowModalMany2Many(true)}
          className="text-primary text-sm text-left w-full hover:bg-blue-100 italic font-medium py-1 px-3"
        >
          {t("search_more")}...
        </button>
      )}
    </components.MenuList>
  );
};

const Many2OneField = (props) => {
  const {
    index,
    name,
    methods,
    value: propValue,
    placeholder,
    required,
    relation,
    domain,
    context,
    handleOnchange,
    onchangeData,
    string,
    lang,
    isForm,
    searchable,
  } = props;
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [isShowModalMany2Many, setIsShowModalMany2Many] = useState(false);
  const [tempSelectedOption, setTempSelectedOption] = useState(null); // Biến tạm để lưu option được chọn
  const actionDataString = sessionStorage.getItem("actionData");
  const { menuList } = useSidebar();
  const { rootContext } = useHeaderStore();
  const { setListSubject } = useSelectStore();
  const [domainModal, setDomainModal] = useState([]);
  const initValue = methods?.getValues(name)

  const actionData = actionDataString && actionDataString !== "undefined" ? JSON.parse(actionDataString) : {};
  const _context = useMemo(() => ({ ...onchangeData, ...evalJSONContext(actionData?.context) || {} }), [onchangeData, actionData]);
  const domainObject = useMemo(() => evalJSONDomain(domain, _context), [domain, _context]);
  const contextObject = useMemo(() => evalJSONContext(context, _context) || {}, [context, _context]);
  const actionId = useMemo(
    () =>
      menuList
        ?.flatMap((item) => item?.child_id.filter((childItem) => childItem?.is_display && childItem?.action?.res_model === relation))
        ?.[0]?.action?.id,
    [menuList, relation]
  );

  const { data: dataOfSelection, refetch, isFetching } = useQuery({
    queryKey: [`data_${relation}`, domainObject, lang],
    queryFn: () =>
      ViewServices.getSelectionItem({
        model: relation,
        domain: domainObject,
        context: { ...contextObject, ...rootContext },
        specification: {
          id: {},
          name: {},
          display_name: {},
        },
      }),
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const selectOptions = useMemo(() => {
    return (
      dataOfSelection?.records?.map((val) => ({
        value: val?.id,
        label: val?.name || val?.display_name,
      })) || []
    );
  }, [dataOfSelection]);

  useEffect(() => {
    setOptions(selectOptions);
    setDomainModal(domainObject);
    if (relation === 'student.subject') setListSubject(selectOptions);
  }, [selectOptions]);

  useEffect(() => {
    if (actionId) {
      localStorage.setItem('vid', actionId);
    }
  }, [actionId]);

  const fetchMoreOptions = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleChooseRecord = useCallback(
    (idRecord) => {
      const newOption = options.find((option) => option.value === idRecord);
      if (newOption) {
        methods.setValue(name, newOption.value, { shouldDirty: true });
        setTempSelectedOption(newOption); // Lưu vào biến tạm
        handleOnchange(name, newOption.value);
        methods.trigger(name);
      }
      setIsShowModalMany2Many(false);
    },
    [options, methods, name, handleOnchange]
  );

  const handleClose = useCallback(() => setIsShowModalMany2Many(false), []);

  const handleSelectChange = useCallback(
    (selectedOption) => {
      const newValue = selectedOption ? selectedOption.value : null;
      methods.setValue(name, newValue, { shouldDirty: true });
      setTempSelectedOption(selectedOption); // Lưu option được chọn vào biến tạm
      handleOnchange(name, newValue);
      methods.trigger(name);
    },
    [methods, name, handleOnchange]
  );

  if (!isForm) {
    return (
      <>
        <ModalDetail
          idToolTip={name + index}
          title={t('detail_button')}
          idForm={propValue?.id}
          resModel={relation}
          vid={actionId}
          context={{ ...contextObject, ...rootContext }}
          place="top-start"
        />
        <span data-tooltip-id={name + index} id={name}>
          {propValue?.display_name}
        </span>
      </>
    );
  }

  return (
    <>
      {isShowModalMany2Many && (
        <Many2ManyView
          id={initValue?.id || initValue}
          isShow={isShowModalMany2Many}
          showCheckBox={false}
          relation={relation}
          domain={domainModal}
          setDomain={setDomainModal}
          context={{ ...contextObject, ...rootContext }}
          tab={{}}
          title={`${t('search_more')}: ${string}`}
          funcChoose={handleChooseRecord}
          funcClose={handleClose}
          onchangeData={onchangeData}
        />
      )}
      {initValue && (
        <ModalDetail
          idToolTip={name}
          title={t('detail_button')}
          idForm={initValue?.id || initValue}
          resModel={relation}
          vid={actionId}
          context={{ ...contextObject, ...rootContext }}
          place="top-start"
        />
      )}
      <div id={name} data-tooltip-id={name} className="inline-block w-full">
        <Controller
          name={name}
          control={methods.control}
          rules={{
            required: required ? { value: true, message: `${string} ${t('must_required')}` } : false,
          }}
          render={({ field, fieldState: { error } }) => {
            const currentValue = field?.value ? { value: field?.value?.id, label: field?.value?.display_name } : null;
            const selectedOption = tempSelectedOption
              ? options.find((option) => option.value === tempSelectedOption?.value)
              : (currentValue ? currentValue : null);

            return (
              <>
                <Select
                  options={options}
                  classNames={{
                    control: () => (isForm ? 'field' : 'field-in-tree'),
                    valueContainer: () => 'm-0 p-0',
                    singleValue: () => 'm-0',
                    input: () => 'm-0 p-0',
                    dropdownIndicator: () => 'm-0 p-0',
                  }}
                  setIsShowModalMany2Many={setIsShowModalMany2Many}
                  searchable={searchable}
                  placeholder={placeholder || t('choose_place')}
                  onMenuOpen={fetchMoreOptions}
                  onChange={handleSelectChange}
                  value={selectedOption} // Dùng selectedOption đã tính toán
                  isSearchable
                  isClearable
                  menuPlacement="auto"
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: !isForm ? 'unset' : baseStyles.borderColor,
                      borderWidth: !isForm ? 0 : baseStyles.borderWidth,
                      backgroundColor: !isForm ? 'transparent' : baseStyles.backgroundColor,
                    }),
                    clearIndicator: (base) => ({
                      ...base,
                      padding: '0',
                    }),
                  }}
                  components={{ MenuList: CustomMenuList }}
                  noOptionsMessage={() => t(isFetching ? 'loading...' : 'no_option')}
                />
                {error && <p className="text-[#de4747] text-sm mt-1">{error.message}</p>}
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default Many2OneField;