import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import useFormStore from '../../store/form'
import TranslateService from '../../core/services/translate-service'
import useHeaderStore from '../../store/header'
import { useForm, useFormContext } from 'react-hook-form'
import ButtonLoading from '../general/ButtonLoading'
import ModelService from '../../core/services/model-service/model-service'

const ModalTranslation = ({ refetchForm, specification }) => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const id = parseInt(searchParams.get("id"))
    const model = searchParams.get("model")
    const { isShowModalTranslate, setIsShowModalTranslate, fieldTranslation, setFieldTranslate } = useFormStore()
    const { rootContext } = useHeaderStore()
    const [isLoading, setIsLoading] = useState(false)
    const [isEnableGetFieldTranslate, setIsEnableGetFieldTranslate] = useState(false)
    const [idWebSave, setIdWebSave] = useState()
    const { getValues, formState: { dirtyFields: dirty }, reset: resetFormParent } = useFormContext();
    const isFieldDirty = !!dirty[fieldTranslation?.name];

    const { data: dataWebSave, isSuccess: isSuccessSaveForm, isFetched } = useQuery({
        queryKey: [`web-save`, isFieldDirty, getValues(fieldTranslation?.name)],
        queryFn: async () => {
            const onchangeResponse = await ModelService.saveForm({
                ids: id ? [id] : [],
                resModel: model,
                data: { [fieldTranslation?.name]: getValues(fieldTranslation?.name) },
                specification: specification,
                context: rootContext
            })

            const objectResponse = onchangeResponse?.value ?? onchangeResponse?.[0]
            return objectResponse
        },
        enabled: isFieldDirty,
        refetchOnMount: true,
    })

    const { register, handleSubmit, formState: { dirtyFields }, reset } = useForm();

    useEffect(() => {
        reset();
    }, [])

    useEffect(() => {
        if (isFieldDirty && isFetched && dataWebSave && dataWebSave?.id) {
            refetchForm()
            resetFormParent(dataWebSave)
            setIdWebSave(dataWebSave?.id)
            setIsEnableGetFieldTranslate(isFetched);
            return
        }

        if (!isFieldDirty) {
            setIdWebSave(id)
            setIsEnableGetFieldTranslate(true)
        }
    }, [isSuccessSaveForm, dataWebSave, isFieldDirty]);

    const { data: installedLang, isSuccess: isSuccessGetInstalledLang } = useQuery({
        queryKey: [`translate-modal-form`, isShowModalTranslate],
        queryFn: () => TranslateService.getInstalledLang({
            context: rootContext,
        }),
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    })

    const { data: fieldTranslate, isSuccess: isSuccessGetTranslationsField } = useQuery({
        queryKey: [`fields-translation-form`, isShowModalTranslate],
        queryFn: () => TranslateService.getFieldTranslations({
            model: model,
            field: [fieldTranslation?.name],
            id: idWebSave,
            context: rootContext,
        }),

        enabled: isEnableGetFieldTranslate && !!fieldTranslation?.name,
        refetchOnWindowFocus: false,
        refetchOnMount: true,
    })

    const handleClose = () => {
        setIsShowModalTranslate(false)
        setIsEnableGetFieldTranslate(false)
        setFieldTranslate(null)
        reset()
    }

    const handleFormSubmit = async (data, event) => {
        event?.preventDefault()
        event?.stopPropagation()
        setIsLoading(true)
        const formData = Object.keys(dirtyFields).reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
        }, {});

        try {
            await TranslateService.updateFieldTranslations({
                model: model,
                context: rootContext,
                field: fieldTranslation?.name,
                value: formData,
                id: idWebSave
            })

            if (typeof refetchForm === "function") {
                refetchForm()
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
            setFieldTranslate(null)
            handleClose()
        }
    }

    return (
        <Transition
            show={isSuccessGetInstalledLang && isSuccessGetTranslationsField}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleClose}>
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 overflow-auto px-6 flex justify-center items-center'>
                            <div className='relative z-[1] p-4 flex flex-col gap-2 max-w-full lg:max-w-[1000px] lg:min-w-[1000px] transform rounded-3xl bg-[#FFF] min-h-fit max-h-[90%] h-fit'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div className='text-[20px] font-semibold'>
                                        {t("translate_title")}: {fieldTranslation?.string}
                                    </div>
                                    <button onClick={handleClose}>
                                        <X size={20} />
                                    </button>
                                </div>

                                <div className='flex flex-1 flex-col w-full overflow-auto relative mt-4 gap-4'>
                                    {
                                        isSuccessGetInstalledLang && isSuccessGetTranslationsField &&
                                        <form className='flex flex-col gap-4' onSubmit={(e) => { e.preventDefault(); e.stopPropagation(); handleSubmit(handleFormSubmit)(e); }} onClick={(e) => e.stopPropagation()}>
                                            <div className='flex flex-col gap-3'>
                                                {
                                                    installedLang?.map((langItem, index) => {
                                                        return (
                                                            <div className='grid grid-cols-4 items-center'>
                                                                <label className='col-span-1 font-medium'>{langItem[1]}</label>
                                                                <input
                                                                    {...register(langItem[0])}
                                                                    type='text'
                                                                    className='col-span-3 field'
                                                                    defaultValue={fieldTranslate[0]?.find(lang => lang.lang === langItem[0])?.value}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>

                                            <div className='flex items-center gap-2 mt-auto border-t border-[rgba(0,0,0,0.1)] pt-3'>
                                                <button type='button' onClick={handleClose} className='button-secondary'>{t("cancel_button")}</button>
                                                <ButtonLoading
                                                    type='submit'
                                                    loading={isLoading}
                                                    className={`button-primary tracking-[-0.5%] ${isLoading ? 'opacity-50' : 'hover:opacity-85'}`}
                                                    content={"save"}
                                                />
                                            </div>
                                        </form>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default ModalTranslation
