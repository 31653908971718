import React, { useState } from 'react';
import ImageWithBasePath from '../../general/ImageCustom';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { useTranslation } from 'react-i18next';

const listViewsTypeIcon = {
    kanban: {
        icon: "assets/img/icons/kanban.svg"
    },
    list: {
        icon: "assets/img/icons/list.svg"
    },
    calendar: {
        icon: "assets/img/icons/calendars.svg"
    },
}

const ViewTypeItem = ({ title, type, handelSelect, className }) => {
    return (
        <div
            onClick={() => {
                if (typeof handelSelect === "function") {
                    handelSelect()
                }
            }}
            className={`cursor-pointer px-[12px] py-[8px] hover:bg-[#f4f6f8] hover:rounded-[8px] flex items-center gap-[12px] min-w-fit ${className}`}
        >
            <ImageWithBasePath src={listViewsTypeIcon[type]?.icon} />
            <div className='whitespace-nowrap'>
                {title}
            </div>
        </div>
    )
}

const SwitchViewMode = ({ listTypeView, viewType, setViewType }) => {
    const { t } = useTranslation()
    const [openViewModal, setOpenViewModal] = useState(false)
    const viewsStyleRef = useClickOutside(() => {
        setOpenViewModal(false)
    })

    return (
        <div
            ref={viewsStyleRef}
            className='px-3 py-[6px] bg-white border relative cursor-pointer rounded-lg min-w-[80px] max-w-fit capitalize text-center text-sm min-h-11' onClick={() => setOpenViewModal(!openViewModal)}>
            <div className='flex gap-2 items-center'>
                <ViewTypeItem
                    title={viewType == "kanban" ? t("kanban") : t("list")}
                    type={viewType}
                    className="hover:bg-white px-0 py-1"
                />
                <ImageWithBasePath src="assets/img/header/chevrondown.svg" alt="chevrondown" width={16} height={16} />
            </div>
            {openViewModal && (
                <div
                    onClick={(e) => e.stopPropagation()}
                    className='absolute z-10 shadow-[0px_12px_16px_-1px_rgba(0,0,0,0.04),0px_1px_5px_0px_rgba(0,0,0,0.04)] border-[#dee2e6] border-solid border-[1px] top-[44px] bg-[#fff] rounded-[8px] w-auto min-w-max left-0 overflow-hidden p-2'
                >
                    {listTypeView.map((viewTypeItem) => {
                        return (
                            <ViewTypeItem
                                key={viewTypeItem.id}
                                title={viewTypeItem?.title}
                                type={viewTypeItem?.type}
                                handelSelect={() => {
                                    setViewType(viewTypeItem.type)
                                    setOpenViewModal(false)
                                    localStorage.setItem("viewType", viewTypeItem.type)
                                }}
                                className={`min-w-[164px] px-[16px] ${viewTypeItem?.type === viewType && "bg-[#f4f6f8] rounded-[8px]"}`}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default SwitchViewMode