import { useQuery } from '@tanstack/react-query'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useHandleField from '../../components/form/model/main'
import ActionsView from '../../components/form/view/action-view'
import MainForm from '../../components/form/view/main-form'
import Loading from '../../components/general/Loading'
import Breadcrumbs from '../../components/toolbar/view'
import ModelService from '../../core/services/model-service/model-service'
import { FIELD_TYPE } from '../../constant/field-type'
import { VIEW_TYPE } from '../../constant/view-type'
import { evalJSONContext, filterFieldDirty, getFieldsOnChange, getSpecificationByFields, isNumber, isObjectEmpty, mergeObjects } from '../../util/util'
import useFormStore from '../../store/form'
import useHeaderStore from '../../store/header'
import Chatter from '../chatter/page'
import { matchDomains } from '../../core/domain/domain'
import StatusbarDuration from '../../components/widget/status-bar/StatusbarDuration'
import ChatterAction from '../../core/services/chatter'
import ModalTranslation from '../../components/modal/ModalTranslation'
import { CustomToastMessage } from '../../util/toast/customToastMsg'

const Form = ({ resModel, actionData, viewData, id, vid, isDisplayBreadcrumbs = false, relationMappingData = {}, initialMappingData = {}, isNoteBookViewMode = false, isInModel = false, className, parentSpecification, parentFields, parentHandleOnChange, isMainForm, isButtonInside, handleCloseActions, objectValue = {}, appendParentForm, setLoadingParent, parentOnchangeData, isInForm }) => {
  const [searchParams] = useSearchParams()
  const [onchangeData, setOnchangeData] = useState()
  const [fieldChange, setFieldChange] = useState([])
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isFade, setIsFade] = useState(false)
  const nameAction = actionData?.name
  const contextAction = actionData?.context ? evalJSONContext(actionData?.context) : {}
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const { setFormSubmitComponent, isShowModalTranslate } = useFormStore()
  const { rootContext } = useHeaderStore()
  const formSubmitRef = useRef(null)
  const { lang } = useHeaderStore()
  const { data: listComment, refetch } = useQuery({
    queryKey: [`get-list-comment`, lang],
    queryFn: () => ChatterAction.getComment({
      thread_model: resModel,
      thread_id: id,
      limit: 100,
      lang: lang
    }),
    enabled: !!id && isNumber(id),
    refetchOnWindowFocus: false,
  })
  // fetch list field have onchange api and related
  const { data: fieldsOnchange } = useQuery({
    queryKey: [`field-onchange-${resModel}`, resModel],
    queryFn: () => ModelService.getFieldWithOnchange({ model: resModel }),
    refetchOnWindowFocus: false,
  })

  const chatter = viewData?.views?.form?.chatter

  const toolbar = viewData?.views?.list?.toolbar
  const dataModel = viewData?.models?.[resModel]
  const fields = parentFields ?? viewData?.views?.form?.fields?.map((field) => ({ ...dataModel?.[field?.name], ...field }))
  const fieldsViewOnChange = fields && getFieldsOnChange(fields)

  const oeTitle = viewData?.views?.form?.oe_title || []
  const headerFiled = viewData?.views?.form?.header ? viewData?.views?.form?.header.filter((comp) => comp?.type_co === 'field') : []
  const tabFields = viewData?.views?.form?.tabs?.flatMap((tab) => tab.fields || []) || []
  const specification =
    (viewData &&
      getSpecificationByFields(
        [...fields, ...oeTitle, ...headerFiled, ...tabFields, ...viewData?.views?.form?.button_box, ...(viewData?.views?.form?.button_box?.map(button => button.field))],
        {},
        viewData,
        resModel
      ))

  const initVal = ModelService?.toDataJS(onchangeData, viewData, resModel)
  const methods = useForm({
    mode: 'onBlur',
    values: isNoteBookViewMode ? { ...initVal, ...relationMappingData, ...initialMappingData } : initVal,
  })
  const { reset, watch, getValues } = methods

  const formValues = watch()
  const { dirtyFields } = methods.formState

  // fetch onchange default form data
  const fetchOnchange = async ({ id, model, specification, context, objVal, fieldChange }) => {
    try {
      const onchangeResponse = await ModelService.onChangeForm({
        ids: id ? [id] : [],
        model: model,
        specification: parentSpecification ?? specification,
        context: { ...rootContext, ...context },
        object: objVal,
        fieldChange: fieldChange,
      })
      return onchangeResponse?.value ?? onchangeResponse?.[0]
    } catch (err) {
      console.log(err)
    }
  }

  // fetch detail data record
  const fetchDetailData = async (id) => {
    try {
      const dataResponse = await ModelService.getDetailData({
        model: resModel,
        ids: id ? [id] : [],
        specification: parentSpecification ?? specification,
        context: { ...rootContext, ...contextAction },
      })
      if (dataResponse.length > 0) {
        return dataResponse[0]
      }

      return {}
    } catch (err) {
      console.log(err)
    }
  }


  // get default form value 
  const { isLoading: isLoadingFields, isPending, isFetched: isQueryFetched, isFetching, refetch: refetchDataForm } = useQuery({
    queryKey: [`formData-${resModel}-${id}`, id, vid, resModel],
    queryFn: async () => {
      if (!id || !isNumber(id)) {
        const onchangeResponse = await fetchOnchange({
          ids: id ? [id] : [],
          model: resModel,
          specification: specification,
          context: { ...rootContext, ...contextAction },
          objVal: objectValue,
          fieldChange: fieldChange,
        });
        setOnchangeData(onchangeResponse)
        reset(onchangeResponse)
      } else {
        const detailData = await fetchDetailData(id)
        setOnchangeData(detailData)
        reset(detailData)
      }

      return {}
    },
    enabled: !!specification && !parentOnchangeData,
    refetchOnWindowFocus: false,
  })

  // handle submit form
  const handleFormSubmit = async () => {
    try {
      setLoading(true)
      if (setLoadingParent && typeof setLoadingParent === "function") {
        setLoadingParent(true)
      }

      const onchangeDataCopy = JSON.parse(JSON.stringify(onchangeData))

      const data = ModelService.parseORMOdoo(
        filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeDataCopy)
      )

      for (const key in data) {
        if (typeof data[key] == "object" && data[key]?.id && data[key]?.display_name) {
          data[key] = data[key]?.id
        }
      }

      if (resModel === "ir.attachment" && data?.type === "url") {
        delete specification?.datas
      }

      const response = await ModelService.saveForm({
        ids: id ? [id] : [],
        resModel: resModel,
        data: data,
        specification: specification,
        context: { ...rootContext, ...contextAction },
      })
      if (response && response.length > 0 && isMainForm) {
        searchParams.set('id', response?.[0]?.id)
        if (isInModel) {
          refetchDataForm()
          const resDetail = await fetchDetailData(response?.[0]?.id)
          appendParentForm(resDetail)
        } else {
          const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
          navigate(_url)
        }
      }
      setLoading(false)
      if (!id) {
        CustomToastMessage.success(t('create_success'))
      } else {
        CustomToastMessage.success(t('update_success'))

      }
    } catch (error) {
      console.log(error)
      if (setLoadingParent && typeof setLoadingParent === "function") {
        setLoadingParent(false)
      }
      setLoading(false)
      // showErrorMessage(!id ? `${t('create_fail')}` : `${t('update_fail')}`)
      if (!id) {
        CustomToastMessage.error(t('create_fail'), error.message)
      } else {
        CustomToastMessage.error(t('update_fail'), error.message)
      }
      refetchDataForm()
    }
  }

  useEffect(() => {
    if (!isFetching && !isPending) {
      setTimeout(() => {
        setIsFade(true)
      }, 100)
    }
  }, [isFetching, isPending])
  useEffect(() => {
    const newInitVal = ModelService?.toDataJS(onchangeData, viewData, resModel)

    // Option 1: Reset entire form with new values while preserving other fields
    const updatedValues = isNoteBookViewMode
      ? { ...newInitVal, ...relationMappingData, ...initialMappingData }
      : newInitVal
    reset(updatedValues[0])

  }, [onchangeData])

  useEffect(() => {
    if (parentOnchangeData) {
      setOnchangeData(parentOnchangeData)
    }
  }, [parentOnchangeData])

  //handle delete record
  const handleDelete = async () => {
    const result = window.confirm("Bạn có chắc chắn muốn xóa không?");
    if (result) {
      setLoadingDelete(true)
      try {
        await ModelService.deleteByForm({ ids: [id], model: resModel })
        setLoadingDelete(false)
        CustomToastMessage.success(`${t('delete_success')}`)
        navigate(`/list?vid=${vid}&model=${resModel}`);
      } catch (err) {
        setLoadingDelete(false)
        CustomToastMessage.error(`${t('delete_fail')}`)
      }
    }
  }
  //handle onchange formValues
  const handleOnchange = (nameField, value) => {
    if (fieldsOnchange?.includes(nameField) || fieldsViewOnChange?.includes(nameField)) {
      const fetchData = async () => {
        const obj = ModelService.parseORMOdoo({
          ...objectValue,
          ...ModelService.parseORMOdoo(filterFieldDirty(id, viewData, formValues, dirtyFields, resModel, onchangeData)),
          [nameField]: value
        })

        // const filteredSpecifications = Object.keys(obj)
        //   .filter(key => key in specification)
        //   .reduce((acc, key) => {
        //     acc[key] = specification[key];
        //     return acc;
        //   }, {});

        const dataOnchange = await fetchOnchange({
          id: isNumber(id) ? id : null,
          model: resModel,
          specification: specification,
          context: { ...rootContext, ...contextAction },
          objVal: obj,
          fieldChange: [nameField]
        })

        // const updatedData = { ...formValues, [nameField]: value, ...ModelService.toDataJS(dataOnchange, viewData, resModel) };
        const updatedData = mergeObjects(ModelService.toDataJS(dataOnchange, viewData, resModel), { ...formValues, [nameField]: value });

        const dataOnchangeJS = ModelService.toDataJS(updatedData, viewData, resModel)

        if (dataOnchangeJS) {
          Object.keys(dataOnchangeJS).forEach((key) => {
            if ((viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.ONE2MANY) || (viewData?.models?.[resModel]?.[key]?.type === FIELD_TYPE.MANY2MANY)) {
              methods.setValue(key, (dataOnchangeJS[key] ??= []).map((item) => {
                if (isObjectEmpty(item)) return
                if (Array.isArray(item) && item?.length >= 3) {
                  return ModelService.toDataJS(item[2], viewData, resModel)
                }
                return item
              }).filter(Boolean), { shouldDirty: true })
            } else {
              methods.setValue(key, dataOnchangeJS[key], { shouldDirty: true })
            }
          })
          parentHandleOnChange && parentHandleOnChange(dataOnchangeJS)
        }
      }

      fetchData()
    } else if (typeof parentHandleOnChange === "function") {
      parentHandleOnChange({ ...formValues, [nameField]: getValues(nameField) })
    }
  }
  //get field items
  const { fieldList } = useHandleField({
    fields: (fields && oeTitle) && [...oeTitle, ...fields],
    viewData: viewData,
    resModel: resModel,
    onchangeData: formValues,
    setOnchangeData: setOnchangeData,
    handleOnchange: handleOnchange,
    specification: specification,
    contextAction: { ...rootContext, ...contextAction },
    state: formValues.kanban_state,
    id: id,
    refetch: () => {
      refetch()
      refetchDataForm()
    }
  })

  useEffect(() => {
    setFormSubmitComponent(resModel, formSubmitRef)
  }, [formSubmitRef?.current])

  const onSubmit = async (e) => {
    e.stopPropagation()
    methods.handleSubmit(handleFormSubmit)()
  }
  return (
    <>
      {
        ((!isLoadingFields && isQueryFetched) || parentOnchangeData) ?
          <FormProvider {...methods}>
            {isDisplayBreadcrumbs && <Breadcrumbs
              id={id}
              title={nameAction}
              subTitle={!onchangeData?.id ? t('new') : onchangeData?.name || onchangeData?.id}
              viewData={viewData}
              actionData={actionData}
              dataToolbar={toolbar}
              vid={vid}
              model={resModel}
              isInForm={true}
              funcReset={() => {
                if (!isObjectEmpty(dirtyFields)) {
                  methods.reset()
                  CustomToastMessage.success(t("success_rest"))
                }
              }}
              loading={loading}
              loadingDelete={loadingDelete}
              onDelete={handleDelete}
              formSubmitRef={formSubmitRef}
              nameActionSave={id ? t('update_button') : t('save')}
              refetch={refetchDataForm}
              formValues={onchangeData}
              formSpecification={specification}
              handleOnchange={handleOnchange}
            />
            }
            <form ref={formSubmitRef} className={`${isFade && "fade-in"} relative form w-full`} onSubmit={(event) => { onSubmit(event) }}
            >
              <div className={`flex flex-col gap-2 md:!gap-4 w-full`}>
                {viewData?.views?.form?.header[0]?.widget == "statusbar_duration" && !isObjectEmpty(formValues) && (
                  <div className='col-span-1'>
                    <StatusbarDuration
                      resModel={viewData?.models?.[resModel]?.stage_id?.relation}
                      name={viewData?.models?.[resModel]?.stage_id?.name}
                      defaultValue={formValues?.stage_id?.id}
                      domain={viewData?.models?.[resModel]?.stage_id?.domain}
                      value={formValues}
                      specifications={specification}
                      id={id}
                      model={resModel}
                      setOnchangeData={setOnchangeData}
                      refetch={() => {
                        refetch()
                        refetchDataForm()
                      }}
                    />
                  </div>
                )}
                {viewData?.views?.form?.header && viewData?.views?.form?.header?.length > 0 && <ActionsView
                  formSubmitRef={formSubmitRef}
                  isForm={true}
                  refetch={refetchDataForm}
                  actions={viewData?.views?.form?.header}
                  resModel={resModel}
                  viewData={viewData}
                  data={formValues}
                  isMainForm={isMainForm}
                  handleOnchange={handleOnchange}
                  formSpecification={specification}
                  isInForm={isInForm}
                  context={{ ...contextAction, ...rootContext }}
                />}
                <MainForm
                  id={id}
                  handleOnchange={handleOnchange}
                  fieldList={fieldList}
                  viewData={viewData}
                  onchangeData={formValues}
                  resModel={resModel}
                  context={actionData?.context}
                  title={nameAction}
                  className={className}
                  setOnchangeData={setOnchangeData}
                />
                {viewData?.views?.form?.footer && viewData?.views?.form?.footer?.length > 0 &&
                  <ActionsView
                    isForm={false}
                    specification={specification}
                    actions={viewData?.views?.form?.footer ?? []}
                    resModel={resModel}
                    viewData={viewData}
                    data={formValues}
                    formSubmitRef={formSubmitRef}
                    isMainForm={isMainForm}
                    isButtonInside={isButtonInside}
                    handleCloseActions={handleCloseActions}
                    context={contextAction}
                    isInForm={isInForm}
                  />
                }
              </div>
              {chatter && !matchDomains(chatter?.invisible) && id ? <Chatter messages={listComment?.messages || []} refetch={refetch} /> : null}
              {isShowModalTranslate && <ModalTranslation refetchForm={refetchDataForm} specification={specification} />}
            </form>
          </FormProvider> :
          <Loading />
      }
    </>
  )
}

export default Form
