import React, { useEffect, useState } from 'react'
import { Star } from 'react-feather'
import ModelService from '../../core/services/model-service/model-service'
import { useSearchParams } from 'react-router-dom'
import useHeaderStore from '../../store/header'
import { evalJSONContext, filterFieldDirty, getSpecificationByFields } from '../../util/util'
import { Tooltip } from "react-tooltip"
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CustomToastMessage } from '../../util/toast/customToastMsg'

const RatingStar = ({ label, priorities = [], defaultValue, onSelectPriority, id }) => {
    const [rating, setRating] = useState(defaultValue);
    const [hover, setHover] = useState(0);

    useEffect(() => {
        setRating(defaultValue)
    }, [defaultValue])

    const handleClick = (value) => {
        if (onSelectPriority) {
            if (parseInt(rating) === parseInt(value)) {
                handleResetPriority()
                onSelectPriority({
                    value: 0
                });
            } else {
                setRating(value);
                onSelectPriority({
                    value: parseInt(value),
                    resetPriority: handleResetPriority
                });
            }
        }
    };

    const handleHover = (value) => {
        setHover(value)
    }

    const handleResetPriority = () => {
        setHover(0)
        setRating(0)
    }

    return (
        <div className='flex gap-1 cursor-pointer w-fit' onMouseLeave={() => setHover(0)}>
            {Array.from({ length: priorities?.length }, (_, index) => {
                if (index === 0) return
                const starValue = index + 1;
                const fillColor = hover > 0 ? hover >= starValue ? '#f3cc00' : 'white' : rating >= starValue ? '#f3cc00' : 'white';
                const strokeColor = hover > 0 ? hover >= starValue ? '#f3cc00' : 'rgba(73,80,87,0.76)' : rating >= starValue ? '#f3cc00' : 'rgba(73,80,87,0.76)';
                return (
                    <>
                        <Star
                            key={starValue}
                            size={20}
                            strokeWidth={1.5}
                            fill={fillColor}
                            stroke={strokeColor}
                            onMouseEnter={() => handleHover(starValue)}
                            onClick={() => handleClick(starValue)}
                            data-tooltip-id={`${id}-${starValue}`}
                        />
                        <Tooltip className='z-50' id={`${id}-${starValue}`} content={`${label} : ${priorities[index]?.[1]}`} place='bottom-start' />
                    </>
                );
            })}
        </div>
    );
};

const Priority = (props) => {
    const { value, selection, isForm, name, methods, index: id, onchangeData, handleOnchange } = props
    const [searchParams, setSearchParams] = useSearchParams()
    const model = searchParams.get("model")
    const idForm = parseInt(searchParams.get("id"))
    const { rootContext } = useHeaderStore()
    const { t } = useTranslation()

    const actionDataString = sessionStorage.getItem("actionData");
    const viewData = JSON.parse(sessionStorage.getItem("viewData"));
    const actionData = actionDataString && actionDataString !== "undefined" ? JSON.parse(actionDataString) : {};
    const _context = { ...evalJSONContext(actionData?.context) || {} }
    const contextObject = { ...rootContext, ..._context }
    const specification = viewData && getSpecificationByFields(isForm ? viewData?.views?.form?.fields : viewData?.views?.list?.fields, {}, viewData, model)

    const defaultPriority = parseInt(value) + 1
    const label = viewData?.models?.[model]?.[name]?.string
    const idDefault = isForm ? idForm : id

    const pushDataForm = (name, value) => {
        const formValues = methods?.watch()
        const { dirtyFields } = methods.formState
        const obj = {
            ...ModelService.parseORMOdoo(filterFieldDirty(isForm, viewData, onchangeData, dirtyFields, model, formValues)),
            [name]: value
        }
        return ModelService.toDataJS(obj, viewData, model);
    }

    const savePriorities = async ({ value, resetPriority }) => {
        const priorityValue = value <= 0 ? 0 : value - 1

        const data = isForm ? pushDataForm(name, String(priorityValue)) : { [name]: String(priorityValue) }

        try {
            await ModelService.saveForm({
                ids: idDefault ? [idDefault] : [],
                resModel: model,
                data: data,
                specification: specification,
                context: contextObject,
            })

            // if (dataResponse && dataResponse?.[0] && isNaN(idDefault)) {
            //     searchParams.set('id', dataResponse?.[0]?.id);
            //     setSearchParams(searchParams);
            // }

            if (typeof handleOnchange === "function") {
                handleOnchange(name, String(priorityValue))
            }

        } catch (error) {
            CustomToastMessage.error(t("required_full_data"))
            if (resetPriority && typeof resetPriority === "function") {
                resetPriority()
            }
        }
    }

    if (!isForm) {
        return (
            <RatingStar label={label} id={id} priorities={selection} defaultValue={defaultPriority} onSelectPriority={savePriorities} />
        )
    }

    return (
        <Controller
            name={name}
            control={methods.control}
            render={({ field }) => {
                const handlePriorityChange = (props) => {
                    const { value } = props
                    // if (idDefault || !isNaN(idDefault)) {
                    //     savePriorities({
                    //         value: String(parseInt(value))
                    //     })
                    //     return
                    // }
                    field.onChange(String(parseInt(value) - 1 <= 0 ? 0 : parseInt(value) - 1))
                    handleOnchange(name, String(parseInt(value) - 1 <= 0 ? 0 : parseInt(value) - 1))
                }

                return (
                    <RatingStar
                        label={label}
                        id={id}
                        priorities={selection}
                        defaultValue={defaultPriority}
                        onSelectPriority={handlePriorityChange}
                    />
                )
            }}
        />
    )
}

export default Priority
