import React from "react";
import { useSearchParams } from "react-router-dom";
import useHeaderStore from "../../store/header";
import { evalJSONContext } from "../../util/util";
import ModelService from "../../core/services/model-service/model-service";
import ColorPicker from "../general/ColorPicker";
import { Controller } from "react-hook-form";

const PickColor = (props) => {
    const { value, isForm, name, methods, onchangeData, handleOnchange } = props
    const [searchParams] = useSearchParams()
    const model = searchParams.get("model")
    const idForm = parseInt(searchParams.get("id"))
    const { rootContext } = useHeaderStore()
    const actionDataString = sessionStorage.getItem("actionData");
    const actionData = actionDataString && actionDataString !== "undefined" ? JSON.parse(actionDataString) : {};
    const _context = { ...evalJSONContext(actionData?.context) || {} }
    const contextObject = { ...rootContext, ..._context }
    const idDefault = isForm ? idForm : onchangeData?.id

    const savePickColor = async (colorObject) => {
        const { id } = colorObject
        if (value === id) return

        try {
            await ModelService.saveForm({
                ids: idDefault !== null ? [idDefault] : [],
                resModel: model,
                data: { [name]: id },
                specification: {
                    name: {},
                    color: {}
                },
                context: contextObject,
            })
        } catch (error) {
            console.log(error);
        }
    }

    if (!isForm) {
        return (
            <ColorPicker
                savePickColor={savePickColor}
                defaultColor={value}
            />
        )
    }

    return (
        <Controller
            name={name}
            control={methods?.control}
            render={({ field }) => {
                const handlePickColorChange = (color) => {
                    field.onChange(color?.id)
                    handleOnchange(name, color?.id)
                }

                return (
                    <ColorPicker
                        savePickColor={handlePickColorChange}
                        defaultColor={value}
                    />
                )
            }}
        />

    );
};

export default PickColor;
