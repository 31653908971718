import React, { useContext, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { SidebarContext } from '../../util/context/SidebarContext'
import ButtonLoading from '../../components/general/ButtonLoading'
import ImageWithBasePath from '../../components/general/ImageCustom'
import UserService from '../../core/services/auth'
import { CustomToastMessage } from '../../util/toast/customToastMsg'
import { useTranslation } from 'react-i18next'
import Cookies from 'js-cookie'

const ResetPassword = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, watch, formState: { errors }, clearErrors } = useForm()
  const [showPassword, setShowPassword] = useState(false)
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isLoadingReset, setIsLoadingReset] = useState(false)

  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,24}$/

  const togglePasswordVisibility = (e) => {
    e.preventDefault()
    setShowPassword((prevState) => !prevState)
  }

  const toggleConfirmPasswordVisibility = (e) => {
    e.preventDefault()
    setShowConfirmPassword((prevState) => !prevState)
  }

  const onSubmit = (data) => {
    const cookieLange = Cookies.get("i18next")
    setIsLoadingReset(true)
    UserService.resetPassword({ data: data, token: token, lang: cookieLange === "vi" ? "vi_VN" : "en_US", })
      .then((res) => {
        CustomToastMessage.success(t("reset_password_success"))
        setIsLoadingReset(false)
      })
      .catch((err) => {
        CustomToastMessage.error(err?.error || t("reset_password_failure"))
        setIsLoadingReset(false)
      })
  }

  const password = watch('password')

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center">
          <div className="flex flex-col gap-[64px] bg-white p-10 shadow-lg rounded-md w-[479px]">
            <div className='flex flex-col gap-3'>
              <div className='flex flex-col items-center gap-3'>
                <ImageWithBasePath
                  src={'assets/img/forgot-password/lock.svg'}
                  className="object-cover w-[32px] h-[40px]"
                />
                <div className='text-[20px] text-center font-bold text-[rgb(38,38,38)]'>{t("new_password")}</div>
              </div>

              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  {t("new_password")}
                </label>
                <div className='relative'>
                  <input 
                    type={showPassword ? 'text' : 'password'}
                    {...register('password', {
                      required: t("required_new_password"),
                      pattern: {
                        value: passwordPattern,
                        message: t("new_password_rule")
                      }
                    })}
                    onChange={(e) => {
                      if (errors.password) {
                        clearErrors('password')
                      }
                      register('password').onChange(e)
                    }}
                    className={`border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded`}
                    placeholder={t("placeholder_new_password")}
                  />
                  <div onClick={togglePasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!showPassword ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                </div>
                {errors.password && (
                  <p className="text-red-500 text-[12px]">{errors.password.message}</p>
                )}
              </div>

              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  {t("confirm_new_password")}
                </label>
                <div className='relative'>
                  <input 
                    type={showConfirmPassword ? 'text' : 'password'}
                    {...register('confirmPassword', {
                      required: t("required_confirm_new_password"),
                      validate: value => value === password || t("new_password_not_match")
                    })}
                    onChange={(e) => {
                      if (errors.confirmPassword) {
                        clearErrors('confirmPassword')
                      }
                      register('confirmPassword').onChange(e)
                    }}
                    className={`border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded`}
                    placeholder={t("placeholder_confirm_new_password")}
                  />
                  <div onClick={toggleConfirmPasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!showConfirmPassword ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                </div>
                {errors.confirmPassword && (
                  <p className="text-red-500 text-[12px]">{errors.confirmPassword.message}</p>
                )}
              </div>
            </div>
            <ButtonLoading 
              loading={isLoadingReset} 
              content={'save'} 
              className='text-[16px] rounded font-semibold py-3 px-4 bg-primary' 
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword