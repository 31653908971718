import React, { Fragment } from 'react';

const MenuItem = ({ mainLabel, openMenus, toggleMenu, handleNavigation, menuFocus, setMenuFocus, setOpenMenus }) => {
    const renderMultiLevelMenu = (items, level = 0) => {
        return items?.child_id.map((item, index) => {
            return (
                <Fragment
                    key={`${level}-${item.id || index}`}
                >
                    {item.is_display ? (
                        <li
                            className={`submenu submenu-level-${level} ${!item.child_id || item.child_id.length === 0 ? '' : ''}`}
                        >
                            <button
                                className={`button-menu-item font-sans btn-transparent w-full ${menuFocus == item.id ? 'menu-focus' : ''}`}
                                onClick={() => {
                                    if (item.child_id && item.child_id.length > 0) {
                                        toggleMenu(item, level)
                                    } else {
                                        handleNavigation(item)
                                        setMenuFocus(item.id)
                                    }
                                    localStorage.setItem("viewType", "list")
                                }}
                            >
                                <div
                                    className={`multi-level-menu-item ${openMenus[`level-${level}-${item.id}`] ? 'subdrop' : ''}`}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '8px',
                                        }}
                                    >
                                        <span className='custom-active-span !text-[14px] font-medium leading-5 text-[rgba(255,255,255,1)]'>
                                            {item.name}
                                        </span>
                                    </div>

                                    {item.child_id && item.child_id.length > 0 && <span className='menu-arrow' />}
                                </div>
                            </button>
                            {item.child_id && item.child_id.length > 0 && (
                                <ul
                                    style={{
                                        display: openMenus[`level-${level}-${item.id}`] && item.is_display ? 'block' : 'none',
                                        marginLeft: level * 15,
                                    }}
                                    className={`mega-menu`}
                                >
                                    {renderMultiLevelMenu(item, level + 1)}
                                </ul>
                            )}
                        </li>
                    ) : (
                        <></>
                    )}
                </Fragment>
            )
        })
    }

    return (
        <li
            key={`menu-item-${mainLabel.id}`}
            className={`submenu-open submenu-level-${-1}`}
            style={{
                display: mainLabel.is_display ? 'block' : 'none',
            }}
        >
            <button
                className="btn-transparent button-menu-item w-full"
                onClick={() => {
                    if (mainLabel.child_id && mainLabel.child_id.length > 0) {
                        toggleMenu(mainLabel, -1);
                    } else {
                        handleNavigation(mainLabel);
                    }
                }}
            >
                <div
                    className={`multi-level-menu-item ${openMenus[`level-${-1}-${mainLabel.id}`] ? 'subdrop' : ''
                        }`}
                >
                    <div className="menu-item">
                        <img
                            className="icon submenu-hdr"
                            src={
                                mainLabel?.url_icon && mainLabel?.url_icon.startsWith('http')
                                    ? mainLabel.url_icon
                                    : process.env.REACT_APP_DOMAIN + mainLabel.url_icon
                            }
                            width={18}
                            height={18}
                            alt="img"
                        />
                        <h6 className="submenu-hdr whitespace-nowrap !text-[14px] font-medium leading-5 text-[rgba(255,255,255,1)]">
                            {mainLabel?.name}
                        </h6>
                        <span className="menu-arrow" />
                    </div>
                </div>
            </button>

            {mainLabel.child_id && mainLabel.child_id.length > 0 && (
                <ul
                    className={`mega-menu ${openMenus[`level-${-1}-${mainLabel.id}`] && mainLabel.is_display
                        ? 'open p-2'
                        : '!max-h-0 overflow-hidden !p-0'
                        }`}
                >
                    {renderMultiLevelMenu(mainLabel)}
                </ul>
            )}
        </li>
    );
};

export default MenuItem;
