import React, { useState } from 'react'
import { useClickOutside } from '../../hooks/useClickOutside'
import useTableStore from '../../store/table'
import ImageWithBasePath from './ImageCustom'
import { useTranslation } from 'react-i18next'

const listPageLimit = [
    {
        limit: 10,
    },
    {
        limit: 25,
    },
    {
        limit: 50,
    },
    {
        limit: 100,
    },
]

const defaultLimit = 10

const PaginationLimits = () => {
    const { t } = useTranslation()
    const [toggleDropDownListLimit, setToggleDropDownListLimit] = useState(false)
    const { pageLimit, setPageLimit } = useTableStore()
    const pageLimitsRef = useClickOutside(() => {
        setToggleDropDownListLimit(false)
    })

    const handleChangePageLimit = (newLimit) => {
        setPageLimit(newLimit)
    };

    return (
        <div className='flex items-center gap-[8px] ml-auto'>
            <div className='text-sm font-medium text-[#262626]'>{t("num_rows")}</div>
            <div ref={pageLimitsRef} onClick={() => setToggleDropDownListLimit(!toggleDropDownListLimit)} className="flex items-center gap-[8px] py-[6px] px-[12px] rounded-lg border bg-white !border-[#E8E8E8] cursor-pointer relative min-h-11">
                <span className="text-sm leading-5 font-medium">{listPageLimit.find((item) => item.limit === pageLimit).limit}</span>
                <ImageWithBasePath src="assets/img/header/chevrondown.svg" alt="down" width={16} height={16} />
                {toggleDropDownListLimit && <div className="absolute left-0 z-50 w-full rounded-lg border border-gray-primary bg-white top-[calc(100%_+_4px)] shadow-md">
                    {
                        listPageLimit.map((itemLimit, index) => (
                            <div key={`page-limit-${index}`} onClick={() => handleChangePageLimit(itemLimit.limit)} className={`${itemLimit.limit === pageLimit && "text-primary bg-[#08813C10]"} hover:text-primary text-sm leading-5 font-medium select-item hover:bg-[#08813C10] px-3 py-1 transition-all`}>{itemLimit.limit}</div>
                        ))
                    }
                </div>}
            </div>
        </div>
    )
}

export default PaginationLimits
