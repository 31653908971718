import moment from "moment";
import MessageItem from "./MessageItem";
import { checkIfHtmlHasContent } from "../../util/util";

const ListMessage = ({ messages, handleAfterSubmit }) => {
    const getDetailedFileItems = (ids, objects) => {
        return ids.map(id => {
            return objects.find(obj => obj.id === id);
        });
    };

    function groupMessagesByDate(messages) {
        // Helper để xử lý date string và +7 giờ 
        function processDate(dateStr) {
            const [date, time] = dateStr.split(' ');
            const [year, month, day] = date.split('-');
            const [hour, minute, second] = time.split(':');

            // Cộng 7 giờ và xử lý ngày mới nếu cần
            let newHour = parseInt(hour) + 7;
            let newDay = parseInt(day);
            let newMonth = parseInt(month);
            let newYear = parseInt(year);

            if (newHour >= 24) {
                newHour -= 24;
                newDay += 1;

                // Kiểm tra ngày cuối tháng
                const lastDayOfMonth = new Date(newYear, newMonth, 0).getDate();
                if (newDay > lastDayOfMonth) {
                    newDay = 1;
                    newMonth += 1;
                    if (newMonth > 12) {
                        newMonth = 1;
                        newYear += 1;
                    }
                }
            }

            // Format lại date string
            const dateKey = `${newYear}-${String(newMonth).padStart(2, '0')}-${String(newDay).padStart(2, '0')}`;
            const newTime = `${String(newHour).padStart(2, '0')}:${minute}:${second}`;
            return {
                dateKey,
                fullDateTime: `${dateKey} ${newTime}`
            };
        }

        // Xử lý và group messages
        const groupedByDate = {};

        messages && messages?.['mail.message']?.forEach(msg => {

            const files = getDetailedFileItems(msg?.attachment_ids, messages?.['ir.attachment'] ?? []);

            const { dateKey, fullDateTime } = processDate(msg.date);

            if (!groupedByDate[dateKey]) {
                groupedByDate[dateKey] = [];
            }

            groupedByDate[dateKey].push({
                ...msg, // Giữ lại tất cả thông tin gốc
                date: fullDateTime,// Cập nhật date mới
                files, // Thêm files vào message
            });

        });

        // Convert to array và sort
        return Object.entries(groupedByDate)
            .map(([date, messages]) => ({
                date,
                messages: messages.sort((a, b) => b.date.localeCompare(a.date))
            }))
            .sort((a, b) => b.date.localeCompare(a.date));
    }
    const groupedMessages = groupMessagesByDate(messages);

    return (
        <div className="flex flex-col gap-[24px] pt-[24px]">
            {
                groupedMessages && groupedMessages.map((item) => {

                    const filteredMessages = item.messages?.filter(
                        msg => msg?.trackingValues?.length > 0 || checkIfHtmlHasContent(msg?.body) || msg.attachment_ids?.length > 0
                    );
                    if (!filteredMessages?.length) return null;

                    return (
                        <div className="flex flex-col gap-[20px] w-full" key={item.date}>
                            <div className="w-full flex items-center justify-center gap-[16px]">
                                <div className="w-full h-[1px] bg-[rgba(192,194,197,0.58)]"></div>
                                <div className="font-bold text-[14px] leading-[21px] text-[#495057c2] min-w-[90px] text-center">
                                    {moment(item.date).format("DD-MM-YYYY")}
                                </div>
                                <div className="w-full h-[1px] bg-[rgba(192,194,197,0.58)]"></div>
                            </div>
                            {filteredMessages.map((subItem) => {
                                const author = messages?.["res.partner"]?.find(partner => partner.id === subItem?.author?.id)

                                return (
                                    <MessageItem
                                        key={subItem.id}
                                        comment={subItem.message_type}
                                        id={subItem.id}
                                        author={author}
                                        date={subItem.date}
                                        oldValue={subItem?.trackingValues[0]?.oldValue?.value}
                                        data={subItem?.trackingValues}
                                        newValue={subItem?.trackingValues[0]?.newValue?.value}
                                        status={subItem?.trackingValues[0]?.changedField}
                                        body={subItem?.subtype_description || subItem?.body}
                                        isNote={subItem?.subtype_id && subItem?.subtype_id[1] === 'Note'}
                                        listFiles={subItem?.files}
                                        handleAfterSubmit={handleAfterSubmit}
                                    />
                                )
                            })}
                        </div>
                    );
                })
            }
        </div>
    )
}
export default ListMessage