import { useTranslation } from 'react-i18next';

export const useFormatDate = () => {
    const { t } = useTranslation();

    const getRelativeTime = (dateString) => {
        const units = [
            { unit: 'year', seconds: 365 * 24 * 60 * 60, keyPast: 'year_ago', keyFuture: 'year_future' },
            { unit: 'month', seconds: 30 * 24 * 60 * 60, keyPast: 'month_ago', keyFuture: 'month_future' },
            { unit: 'day', seconds: 24 * 60 * 60, keyPast: 'day_ago', keyFuture: 'day_future' },
            { unit: 'hour', seconds: 60 * 60, keyPast: 'hour_ago', keyFuture: 'hour_future' },
            { unit: 'minute', seconds: 60, keyPast: 'minute_ago', keyFuture: 'minute_future' },
            { unit: 'second', seconds: 1, keyPast: 'second_ago', keyFuture: 'second_future' },
        ];

        const currentDate = new Date();
        let year, month, day, hours = 0, minutes = 0, seconds = 0;

        // Detect if the format is YYYY-MM-DD or DD-MM-YYYY
        let parts = dateString.split(/[- :]/).map(Number);

        if (parts[0] > 31) {
            // YYYY-MM-DD format
            [year, month, day] = parts;
        } else {
            // DD-MM-YYYY format
            [day, month, year] = parts;
        }

        if (parts.length > 3) [hours, minutes, seconds] = parts.slice(3);

        let givenDate = new Date(year, month - 1, day, hours, minutes, seconds);

        // Adjust to 23:59:59 if time is 00:00:00
        if (hours === 0 && minutes === 0 && seconds === 0) {
            givenDate.setHours(23, 59, 59);
        }

        const timeDifferenceInSeconds = Math.floor((givenDate.getTime() - currentDate.getTime()) / 1000);
        const isFuture = timeDifferenceInSeconds > 0;
        const absoluteDiff = Math.abs(timeDifferenceInSeconds);

        for (const { seconds, keyPast, keyFuture } of units) {
            let diff = absoluteDiff / seconds;
            if (diff >= 1) {
                diff = isFuture ? Math.ceil(diff) : Math.floor(diff);
                return isFuture
                    ? `${diff} ${t(keyFuture, { count: diff })}`
                    : `${diff} ${t(keyPast, { count: diff })}`;
            }
        }

        return t('now');
    };

    return { getRelativeTime };
};
