import { useEffect, useState } from "react";
import KanbanServices from "../../core/services/kanban/view-service";
import ReactStars from 'react-stars';


const KanbanItem = ({ data, resModel, specification, width_context, handleClick }) => {
    const [dataResponse, setDataResponse] = useState()
    const domain = data?.__domain
    const fetchListData = async (resModel, specification, domain, width_context) => {
        await KanbanServices.getListData({ resModel, specification, domain, width_context }).then((res) => {
            setDataResponse(res?.records)
        }).catch((error) => {
        })
    }
    useEffect(() => {
        if (resModel, specification, domain.length > 0, width_context) {
            fetchListData(resModel, specification, domain, width_context)
        }
    }, [domain]);
    return (
        <div className="w-full min-w-[332px] max-w-[332px] flex flex-col px-[16px]">
            <div className="flex flex-col gap-[8px] w-full">
                <div className="w-full flex items-center justify-between">
                    <div className="font-semibold text-[#212529] text-[16px]">{data?.stage_id[1]}</div>
                    <div className="font-bold">+</div>
                </div>
                <div className="w-full flex items-center justify-between">
                    <div className="w-full max-w-[225px] bg-[#DEE2E6] rounded-[8px] h-[13px]"></div>
                    <div>{data?.stage_id_count}</div>
                </div>
            </div>
            <div className="flex flex-col w-full">{dataResponse && dataResponse.map((item) => {
                return (
                    <div key={item.id} onClick={() => handleClick(item)} className="w-full border-solid border-[1px] cursor-pointer border-t-0 first:border-t-[1px] border-[rgb(222,226,230)] p-[8px] text-[#495057]">
                        <div className="font-medium text-[16px]">{item.name}</div>
                        <div>{item?.commercial_partner_id?.display_name}</div>
                        <div className="flex items-center justify-between">
                            {item.priority && (
                                <div>

                                    <ReactStars
                                        count={3}
                                        defaultValue={item.priority}
                                        value={item.priority}
                                        size={16}
                                        color2={'#ffd700'}
                                        color1={'#ddd'} />
                                </div>
                            )}
                            <div className="flex items-center justify-center gap-[6px]">
                                <div>{item?.user_id?.display_name}</div>
                                <div className={`w-[12px] h-[12px] rounded-full ${item?.kanban_state == "done" ? "bg-[#28A745]" : "bg-[#E9ECEF]"} [box-shadow:_inset_0_0_0_1px_rgba(0,0,0,0.2)]`}></div>
                            </div>
                        </div>

                    </div>
                )
            })}</div>
        </div>

    )
}
export default KanbanItem