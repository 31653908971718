import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import ButtonLoading from '../../general/ButtonLoading'
import ModalLayer from '../../modal/ModalLayer'
import { useState } from 'react'

const RightToolbar = (props) => {
  const {
    id,
    dataToolbar,
    isInForm,
    onClickBack,
    loading,
    loadingDelete,
    onDelete,
    nameActionSave,
    onClickAddNew,
    title,
    vid,
    formSubmitRef,
  } = props
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search);
  const model = queryParams.get('model');
  const rootStyle = getComputedStyle(document.documentElement);
  const colorSecondary = rootStyle.getPropertyValue('--secondary').trim();
  const [isOpenSaveConfirm, setOpenSaveConfirm] = useState(false)

  const submitForm = () => {
    if (formSubmitRef.current) {
      formSubmitRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return (
    <>
      <ModalLayer isOpen={isOpenSaveConfirm} onClose={() => setOpenSaveConfirm(false)}>
        <div className='mx-auto flex flex-col items-center justify-center gap-4'>
          <div className='flex flex-col gap-[8px] items-center'>
            <div className='text-[18px] font-bold'>{id ? t("confirm_update") : t("confirm_create")}</div>
            <p className='text-[16px]'>{t(id ? "update_confirm_title" : "create_confirm_title")}</p>
          </div>
          <div className='flex justify-center items-center gap-2 w-full' >
            <button type='button' onClick={() => setOpenSaveConfirm(false)} className='button-secondary flex-1'>{t("cancel")}</button>
            <button type='button' onClick={() => {
              submitForm()
              setOpenSaveConfirm(false)
            }} className='button-primary flex-1'>{t("confirm_button")}</button>
          </div>
        </div>
      </ModalLayer>

      <div className='flex items-center gap-[16px] !order-2 xl:!order-3 flex-1 justify-end'>
        <div className='page-btn flex gap-3'>
          {isInForm && (
            <button type='button' className='button-secondary flex items-center gap-1 text-[14px]' onClick={onClickBack}>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                <path
                  className='fill-secondary'
                  d='M9.2045 9.99956L12.917 13.7121L11.8565 14.7726L7.0835 9.99956L11.8565 5.22656L12.917 6.28706L9.2045 9.99956Z'
                />
              </svg>
              {t('back')}
            </button>
          )}

          {dataToolbar?.root?.create && !isInForm && (
            <button className='button-primary flex items-center gap-1 text-[14px]' onClick={onClickAddNew}>
              <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
                <path
                  d='M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z'
                  fill='white'
                />
                <path
                  d='M10 15.625C9.65833 15.625 9.375 15.3417 9.375 15V5C9.375 4.65833 9.65833 4.375 10 4.375C10.3417 4.375 10.625 4.65833 10.625 5V15C10.625 15.3417 10.3417 15.625 10 15.625Z'
                  fill='white'
                />
              </svg>
              {t('add')}
            </button>
          )}

          {!isInForm && (
            <button style={{
              boxShadow: " 0px 1px 2px 0px rgba(55,93,251,0.08)"
            }} type="button" onClick={() => {
              sessionStorage.setItem("title", title)
              sessionStorage.setItem("vid", vid)
              navigate(`/list?vid=action&model=${model}`)
            }} className={'button-secondary flex items-center gap-1 max-w-max w-max'}>
              <svg className='!text-button_primary' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M12.7167 18.5417H7.28333C3.19167 18.5417 1.44167 16.7917 1.44167 12.7V12.5917C1.44167 8.89167 2.9 7.10833 6.16667 6.8C6.5 6.775 6.81667 7.025 6.85 7.36667C6.88333 7.70833 6.63333 8.01667 6.28333 8.05C3.66667 8.29167 2.69167 9.525 2.69167 12.6V12.7083C2.69167 16.1 3.89167 17.3 7.28333 17.3H12.7167C16.1083 17.3 17.3083 16.1 17.3083 12.7083V12.6C17.3083 9.50833 16.3167 8.275 13.65 8.05C13.3083 8.01667 13.05 7.71667 13.0833 7.375C13.1167 7.03333 13.4083 6.775 13.7583 6.80833C17.075 7.09167 18.5583 8.88333 18.5583 12.6083V12.7167C18.5583 16.7917 16.8083 18.5417 12.7167 18.5417Z" fill={colorSecondary} />
                <path d="M10 13.125C9.65833 13.125 9.375 12.8417 9.375 12.5V3.01666C9.375 2.675 9.65833 2.39166 10 2.39166C10.3417 2.39166 10.625 2.675 10.625 3.01666V12.5C10.625 12.8417 10.3417 13.125 10 13.125Z" fill={colorSecondary} />
                <path d="M12.7917 5.5C12.6333 5.5 12.475 5.44167 12.35 5.31667L10 2.96667L7.65 5.31667C7.40834 5.55834 7.00834 5.55834 6.76667 5.31667C6.525 5.075 6.525 4.675 6.76667 4.43334L9.55834 1.64167C9.8 1.4 10.2 1.4 10.4417 1.64167L13.2333 4.43334C13.475 4.675 13.475 5.075 13.2333 5.31667C13.1167 5.44167 12.95 5.5 12.7917 5.5Z" fill={colorSecondary} />
              </svg>
              <span className="text-button_primary text-[12px] md:text-[14px] font-medium whitespace-nowrap">{t('upload-excel')}</span>
            </button>)}

          {isInForm && (
            <>
              <ButtonLoading
                loading={loading}
                className={`button-primary tracking-[-0.5%] ${loading ? 'opacity-50' : 'hover:opacity-85'}`}
                content={nameActionSave}
                func={() => {
                  setOpenSaveConfirm(true)
                }}
              />
              {/* {id ? (
                <ButtonLoading
                  func={onDelete}
                  type={`button`}
                  loading={loadingDelete}
                  className={`button-delete tracking-[-0.5%] ${loading ? 'opacity-50' : 'hover:opacity-85'}`}
                  content={t('button_delete')}
                />
              ) : (
                <></>
              )} */}
            </>
          )}

        </div>
      </div>
    </>
  )
}

export default RightToolbar
