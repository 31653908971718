import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useTranslation } from 'react-i18next'
import Form from '../../pages/form-view/form'
import { useQuery } from '@tanstack/react-query'
import ViewServices from '../../core/services/view-service/view-service'
import { useSearchParams } from 'react-router-dom'
import useFormStore from '../../store/form'
import { FIELD_TYPE } from '../../constant/field-type'
import { isObjectEmpty } from '../../util/util'
import ModelService from '../../core/services/model-service/model-service'
import { useFormContext } from 'react-hook-form'
import ButtonLoading from '../general/ButtonLoading'

const ModalAddNew = ({ handleClose, title, resModel, context, onchangeData, tab, index }) => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const vid = parseInt(searchParams.get("vid"))
    const id = parseInt(searchParams.get("id"))
    const { formSubmitComponent } = useFormStore()
    const [formValues, setFormValues] = useState(onchangeData)
    const [isFinishedSetValues, setIsFinishedSetValues] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const methods = useFormContext()

    const { data: viewResponse } = useQuery({
        queryKey: [`view-tree-form-${resModel}`],
        queryFn: () => ViewServices.getFieldView({
            resModel: resModel,
            views: [[false, "form"]],
            context: context,
            id: false
        }),
        refetchOnWindowFocus: false,
    })

    const handleSubmitAddNew = () => {
        if (formSubmitComponent?.[resModel]?.current) {
            formSubmitComponent?.[resModel].current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
        }
    }

    const handleOnchange = () => {
        const updatedData = { ...onchangeData, ...formValues };
        const viewData = viewResponse;
        const resModelFields = viewData?.models?.[resModel];

        if (updatedData) {
            Object.keys(updatedData).forEach((key) => {
                const fieldType = resModelFields?.[key]?.type;

                if (fieldType === FIELD_TYPE.ONE2MANY || fieldType === FIELD_TYPE.MANY2MANY) {
                    const processedValue = (updatedData[key] ?? []).map((item) => {
                        if (isObjectEmpty(item)) return null;

                        if (Array.isArray(item) && item.length >= 3) {
                            return ModelService.toDataJS(item[2], viewData, resModel);
                        }
                        return item;
                    }).filter(Boolean);

                    methods.setValue(key, processedValue, { shouldDirty: true });
                } else {
                    if (Boolean(updatedData[key])) {
                        methods.setValue(key, updatedData[key], { shouldDirty: true });
                    }
                }
            });
        }
        setIsLoading(false)
        handleClose();
    };

    const handleSetValues = (onchangeData) => {
        const { id, type, url } = onchangeData;

        setFormValues((pre) => {
            const updatedTabValues = [...(pre?.[tab?.name] || [])];

            if (id) {
                if (index !== -1) {
                    updatedTabValues[index] = {
                        ...onchangeData,
                        ...(type === "url" && url && resModel === "ir.attachment" ? { datas: url } : {})
                    };
                }
            } else updatedTabValues.push(onchangeData);

            return { ...pre, [tab?.name]: updatedTabValues };
        });

        setIsFinishedSetValues(true)
    };

    useEffect(() => {
        if (isFinishedSetValues) {
            handleOnchange()
        }
    }, [isFinishedSetValues])

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleClose}>
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 overflow-auto px-6 flex justify-center items-center'>
                            <div className='relative z-[1] p-4 flex flex-col gap-2 max-w-full lg:max-w-[1000px] lg:min-w-[1000px] transform rounded-3xl bg-[#FFF] min-h-[90%] max-h-[90%] h-fit'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div className='text-[20px] font-semibold'>
                                        {title}
                                    </div>
                                    <button onClick={handleClose}>
                                        <X size={20} />
                                    </button>
                                </div>

                                <div className='flex flex-1 flex-col w-full overflow-auto relative'>
                                    <Form
                                        resModel={resModel}
                                        actionData={{}}
                                        viewData={viewResponse}
                                        id={null}
                                        isDisplayBreadcrumbs={false}
                                        vid={vid}
                                        isMainForm={true}
                                        isInModel={true}
                                        appendParentForm={handleSetValues}
                                        setLoadingParent={setIsLoading}
                                    />
                                </div>

                                <div className='flex items-center gap-2 mt-auto'>
                                    {/* <button type='button' onClick={() => handleSubmitAddNew()} className='button-primary'>{t("save")}</button> */}
                                    <button type='button' onClick={handleClose} className='button-secondary'>{t("cancel_button")}</button>
                                    <ButtonLoading
                                        loading={isLoading}
                                        className={`button-primary tracking-[-0.5%] ${isLoading ? 'opacity-50' : 'hover:opacity-85'}`}
                                        content={"save"}
                                        func={() => handleSubmitAddNew()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default ModalAddNew
