import { useSearchParams } from 'react-router-dom'
import Loading from '../../components/general/Loading'
import React, { useEffect, useState } from 'react'
import ViewServices from '../../core/services/view-service/view-service'
import { evalJSONContext, evalJSONDomain, formatSortingString, getSpecificationByFields } from '../../util/util'
import ModelService from '../../core/services/model-service/model-service'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useTable from '../../components/table/model/table'
import TableView from '../../components/table/view/table-view'
import useTableStore from '../../store/table'
import Breadcrumbs from '../../components/toolbar/view'
import useFormStore from '../../store/form'
import Pagination from '../../components/general/Pagination'
import useHeaderStore from '../../store/header'
import Excel from '../../components/excel'
import useSearchStore from '../../store/search'
import { useDebounce } from 'use-debounce'
import KanbanView from './kanbanView'
import { COMPONENT_TYPE } from '../../constant/componen-type'

const TreeView = () => {
  const [searchParams] = useSearchParams()
  const vid = searchParams.get('vid')
  const resModel = searchParams.get("model")
  const active_id = parseInt(searchParams.get("active_id"))
  const { fields, setFields, page, setPage, pageLimit, setPageLimit } = useTableStore()
  const { setViewDataStore, viewDataStore } = useFormStore()
  const [isFade, setIsFade] = useState(false);
  const { lang, rootContext } = useHeaderStore()
  const { setSelectedTags, groupByDomain, setGroupByDomain } = useSearchStore()
  const [debouncedPage] = useDebounce(page, 500);
  const [domainTable, setDomainTable] = useState([])
  const [isReady, setIsReady] = useState(false);
  const type = localStorage.getItem("viewType") || "list"
  const [viewType, setViewType] = useState(type)
  const [order, setOrder] = useState()
  const { data: actionReponse } = useQuery({
    queryKey: [`action-${resModel}-${vid}`, vid, lang],
    queryFn: () => ViewServices.getViewById({ id: vid, lang: lang }),
    enabled: !!vid && !isNaN(vid),
    refetchOnWindowFocus: false
  })
  const actionData = actionReponse?.[0] ?? JSON.parse(sessionStorage.getItem("actionData"))
  const actionContext = evalJSONContext(actionData?.context, { active_id })

  const { data: viewResponse } = useQuery({
    queryKey: [`view-${resModel}-${vid}`, lang, actionData?.id],
    queryFn: () => ViewServices.getFieldView({
      resModel: actionData?.res_model,
      views: [...actionData?.views.map(view => view[1] === "list" ? [view[0], "list"] : view),
      [Array.isArray(actionData?.search_view_id) ? actionData?.search_view_id[0] : actionData?.search_view_id, 'search']],
      context: { ...rootContext, ...actionContext },
      id: parseInt(vid)
    }),
    enabled: !!actionData,
    refetchOnWindowFocus: false
  })
  const viewData = viewResponse
  const domain = actionData?.domain ? Array.isArray(actionData?.domain) ? [...actionData?.domain] : evalJSONDomain(actionData?.domain, { active_id }) : []
  const specification = viewResponse && getSpecificationByFields(viewType == "kanban" ? viewResponse?.views?.kanban?.fields : viewResponse?.views?.list?.fields, {}, viewData, resModel)
  const default_order = viewResponse && viewResponse?.views?.list?.default_order
  const eduColor = viewResponse?.views?.kanban?.fields?.filter((item) => item.colors)
  const fetchData = async () => {
    try {
      setDomainTable(domain)
      actionReponse?.[0] && sessionStorage.setItem('actionData', JSON.stringify(actionReponse?.[0]))
      sessionStorage.setItem('viewData', JSON.stringify(viewResponse))
      setViewDataStore(viewResponse)
      setFields({ ...fields, [`${vid}_${resModel}`]: viewResponse?.views?.list?.fields })

      setPage(0)
      // setSelectedTags([])
      setPageLimit(10)
      setIsReady(true)
    } catch (err) {
      console.log(err);
    }
  }
  const width_context = {
    ...rootContext, ...actionContext, tz: "Asia/Saigon"
  }
  const { data: dataResponse, isLoading: loadingData, isFetched: isQueryFetched, isPlaceholderData, isSuccess, refetch, isRefetching } = useQuery({
    queryKey: [
      `data-${resModel}-${vid}`,
      specification,
      domainTable,
      debouncedPage,
      groupByDomain,
      pageLimit,
      order,
      vid
    ],
    queryFn: () => ModelService.getAll({
      resModel: resModel,
      specification: specification,
      domain: domainTable,
      limit: pageLimit,
      offset: debouncedPage * pageLimit,
      fields: groupByDomain?.fields,
      groupby: [groupByDomain?.contexts[0]?.group_by],
      context: { ...rootContext, ...actionContext },
      sort: order ? order : default_order ? formatSortingString(default_order) : ""
    }),
    enabled: isReady && !!specification && !!resModel && !!domainTable && !!viewDataStore && !!pageLimit,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  })
  useEffect(() => {
    setGroupByDomain(null)
    setDomainTable([])
    if (viewResponse) {
      fetchData()
    }
  }, [viewResponse])
  useEffect(() => {
    if (vid) {
      localStorage.setItem("id", vid)
    }
  }, [vid])
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setSelectedTags([])
        setIsFade(true);
      }, 100);
    }
  }, [isSuccess])
  const context = actionData?.context ? evalJSONContext(actionData?.context) : {}

  const { dataSource, columns, visibleOptionalFields, typeTable, setDataSource } = useTable({
    model: resModel,
    fields: fields?.[`${vid}_${resModel}`] || viewResponse?.views?.list?.fields,
    data: dataResponse?.records ?? dataResponse?.groups,
    dataModel: viewData?.models?.[resModel],
    context: { ...rootContext, ...context },
    typeTable: dataResponse?.groups ? "group" : "list"
  })

  return (
    <>
      {vid === "action" ?
        <Excel
        /> :
        <div className={`page-wrapper flex flex-col  ${isFade && "fade-in"}`}>
          {((!loadingData && isQueryFetched) || isPlaceholderData) ? (
            <div className="content flex-1 flex flex-col gap-2">
              <Breadcrumbs
                title={actionData?.name}
                actionData={actionData}
                viewData={viewDataStore}
                dataToolbar={type == "kanban" ? viewDataStore?.views?.kanban?.toolbar : viewDataStore?.views?.list?.toolbar}
                vid={vid}
                model={resModel}
                isInForm={false}
                refetch={refetch}
                domain={domainTable}
                setDomain={setDomainTable}
                viewType={type}
                setViewType={setViewType}
                openView={viewResponse?.views}
                dataResponseLength={dataSource?.length}
              />
              <div className='card rounded-[10px] drop-shadow-[0px_1px_8px_rgba(82,88,102,0.06)] !border-[1.5px] !border-[#DEE7DD]'>
                <div className='card-body flex flex-col gap-[16px]'>
                  <div className={`relative w-full ${type === "kanban" && ""}`}>
                    {type == "kanban" ? (
                      <KanbanView data={dataSource} name={viewDataStore?.views?.kanban?.name} vid={vid} resModel={resModel} course={columns[1]} color={eduColor[0] ? JSON.parse(eduColor[0]?.colors) : ""} field={eduColor[0]?.field} width_context={width_context} specification={specification} />
                    ) : (
                      <div className={`flex w-full items-center  ${type === "kanban" && "overflow-x-scroll"}`}>
                        <TableView
                          model={resModel}
                          dataSource={dataSource}
                          dataResponseLength={dataResponse?.length}
                          columns={columns}
                          visibleOptionalFields={visibleOptionalFields}
                          isForm={false}
                          typeTable={typeTable}
                          specification={specification}
                          showFooterTable={false}
                          typeCo={COMPONENT_TYPE.TREE}
                          setOrder={setOrder}
                          order={order}
                          setDataSource={setDataSource}
                          context={{ ...rootContext, ...context }}
                          funcRefetch={refetch}
                        />
                      </div>
                    )}

                  </div>
                </div>
              </div>
              <Pagination
                className="pagination-bar mt-auto px-3 py-4 flex justify-center"
                currentPage={page}
                totalCount={dataResponse?.length ?? 0}
                pageSize={pageLimit}
                onPageChange={page => setPage(page)}
                displayPageNumberDots={true}
              />
            </div>
          ) : (
            <Loading />
          )}
        </div>}</>

  )
}
export default TreeView
