import React, { useState } from 'react'
import { getSessionStorage } from '../../../util/util'
import BoxButton from '../../widget/BoxButton'
import useToolBar from '../model/toolbar'
import ActionCheckbox from './ActionCheckbox'
import LeftToolbar from './LeftToolbar'
import RightToolbar from './RightToolbar'
import Search from './search'
import SwitchViewMode from './SwitchViewMode'
import PaginationLimits from '../../general/PaginationLimits'
import ModalError from './ModalError'
import { useTranslation } from 'react-i18next'

const Breadcrumbs = (props) => {
  const {
    id,
    vid,
    model,
    dataToolbar,
    actionData,
    viewData,
    title,
    subTitle,
    isInForm,
    funcReset,
    refetch,
    loading,
    loadingDelete,
    nameActionSave,
    onDelete,
    domain,
    setDomain,
    formSubmitRef,
    formValues,
    formSpecification,
    viewType,
    setViewType,
    openView,
    handleOnchange,
    dataResponseLength
  } = props
  const { isShow, onClickAddNew, onClickBack } = useToolBar(vid, model, funcReset, isInForm, refetch)
  const [openModal, setOpenModal] = useState(0)
  const [openImport, setOpenImport] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const { t } = useTranslation()

  const viewDataSession = getSessionStorage('viewData')
  const buttonBox = viewData?.views?.form?.button_box?.map(item => ({ ...item, field: { ...viewData?.models?.[model]?.[item?.field ? item?.field?.name : item?.name], ...item?.field, widget: item?.field?.widget ?? item?.widget } }))
  const listTypeView = [
    {
      id: 1,
      title: t("kanban"),
      type: "kanban"
    },
    {
      id: 2,
      title: t("list"),
      type: "list"
    },
  ]

  return (
    <div className='flex flex-col mt-3'>
      {isInForm && buttonBox?.length > 0 && (
        <BoxButton listButtons={buttonBox} model={model} formValues={formValues} actionData={actionData} handleOnchange={handleOnchange} />
      )}

      <div className='w-full flex items-center justify-between !pb-4 md:!pb-1 pt-2 md:!pt-6 gap-3 sm:gap-2 flex-wrap lg:gap-1'>
        <LeftToolbar
          {...props}
          title={title}
          subTitle={subTitle}
          isShow={isShow}
          setOpenImport={setOpenImport}
          setOpenModal={setOpenModal}
          dataToolbar={dataToolbar}
          openImport={openImport}
          actions={viewDataSession?.views?.form?.toolbar?.action}
          actionsPrint={viewDataSession?.views?.form?.toolbar?.print}
          funcReset={funcReset}
          refetch={refetch}
          formValues={formValues}
          formSpecification={formSpecification}
          setErrorMessage={setErrorMessage}
        />

        {!isShow &&
          <>
            <ActionCheckbox
              model={model}
              dataToolbar={dataToolbar}
              openModal={openModal}
              setOpenImport={setOpenImport}
              setOpenModal={setOpenModal}
              refetch={refetch}
              dataResponseLength={dataResponseLength}
              viewData={viewData}
              setErrorMessage={setErrorMessage}
            />
            <Search actionData={actionData} viewData={viewData} model={model} domain={domain} setDomain={setDomain} />
          </>
        }
        {errorMessage && (
          <ModalError data={errorMessage} setErrorMessage={setErrorMessage} />
        )}

        <RightToolbar
          id={id}
          isInForm={isInForm}
          dataToolbar={dataToolbar}
          onClickAddNew={onClickAddNew}
          onClickBack={onClickBack}
          title={title}
          vid={vid}
          funcReset={funcReset}
          loading={loading}
          loadingDelete={loadingDelete}
          nameActionSave={nameActionSave}
          onDelete={onDelete}
          formSubmitRef={formSubmitRef}
        />

        {!isInForm && (
          <div className='order-4 w-full lg:w-full xl:min-w-full flex items-center justify-between gap-3'>
            {openView && Object.keys(openView).length > 3 &&
              <SwitchViewMode listTypeView={listTypeView} viewType={viewType} setViewType={setViewType} />
            }
            <PaginationLimits />
          </div>
        )}
      </div>
    </div>
  )
}

export default Breadcrumbs
