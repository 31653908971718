import React from 'react'
import useCheckbox from '../model/checkbox'
import useTableStore from '../../../store/table'
import ImageWithBasePath from '../../general/ImageCustom'

const TableHead = ({
  columns,
  dataSource,
  visibleOptionalFields,
  isForm,
  columnWidths,
  handleResize,
  ResizableHeader,
  showCheckBox,
  typeCo,
  setOrder,
  order
}) => {
  const { selectedRowKeys, setSelectedRowKeys } = useTableStore()
  const { handleSelectAllChange } = useCheckbox(setSelectedRowKeys, selectedRowKeys, dataSource)
  const isAllSelected = selectedRowKeys?.length > 0 && selectedRowKeys.length === dataSource?.length && selectedRowKeys.every(rowItem => dataSource.some(dataItem => dataItem.id === rowItem));
  const handleSort = (data) => {
    if (data != "ticket_ref") {
      if (`${data} ASC` == order) {
        setOrder(`${data} DESC`)
      } else {
        setOrder(`${data} ASC`)
      }
    }
  }
  return (
    <thead>
      <tr style={{ userSelect: 'none', WebkitUserSelect: 'none' }} className='border-b-[1.5px] border-[#DEE7DD]'>
        {showCheckBox && <th
          className={`${isForm ? 'hidden' : 'p-3'} table-checkbox-row w-[2%] text-left font-medium uppercase text-gray-500`}
        >
          <input
            type='checkbox'
            checked={isAllSelected}
            onChange={handleSelectAllChange}
            className='ml-2 align-middle custom-checkbox'
          />
        </th>}
        {columnWidths &&
          columns?.map((col, index) => {
            if (col?.key === "sequence") {
              return (
                <th className='w-[1%]'></th>
              )
            }
            return (
              <ResizableHeader
                key={'table-head-' + index}
                onResize={handleResize(index)}
                width={columnWidths[index]}
                islast={index === columns?.length - 1}
                className={`column whitespace-nowrap text-left p-3 text-sm font-medium capitalize text-[#121212]`}
              >
                <div className='cursor-pointer flex justify-between items-center gap-[4px] w-full min-w-max group' onClick={() => handleSort(col.key)}>
                  {col.title}
                  {col.key != "ticket_ref" && (
                    <ImageWithBasePath src='assets/img/arrow-down.svg' alt='arrow down' className={`h-5 w-5 min-w-fit hidden group-hover:flex transition-all ${`${col.key} ASC` == order ? 'rotate-180 !flex' : `${col.key} DESC` == order ? '!flex' : ""}`} />
                  )}
                </div>


              </ResizableHeader>
            )
          })}

        {/* {isForm && typeCo !== COMPONENT_TYPE.TREE && <td className='relative p-2 w-[35px] '></td>} */}
        {visibleOptionalFields?.length > 0 && (
          <th
            className={`column sticky right-0 whitespace-nowrap bg-white px-2 text-gray-500 w-8`}
          >
            <div className='size-8'></div>
          </th>
        )}
      </tr>
    </thead>
  )
}

export default TableHead
