import React, { useEffect } from 'react'
import useCheckbox from '../model/checkbox'
import useTableStore from '../../../store/table'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { VIEW_TYPE } from '../../../constant/view-type'
import ImageWithBasePath from '../../general/ImageCustom'
import { useTranslation } from 'react-i18next'
import TableGroup from './table-group'
import useSearchStore from '../../../store/search'
import { COMPONENT_TYPE } from '../../../constant/componen-type'
import { useFormContext } from 'react-hook-form'
import {
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable"
import Row from './Row'

export const TableBodyRow = (props) => {
  const { columns, dataSource, isForm, rootField, model, dataResponseLength, columnWidths, funcRemoveLine, funcAddLine, handleOnchange, viewData, resModel, visibleOptionalFields, typeTable, specification, type, append, level, showCheckBox, typeCo, funcChoose, context, tabIndex, setDataSource, items, prepareRow } = props
  const { t } = useTranslation()
  const { selectedRowKeys, setSelectedRowKeys, indexRowTableModal, setIndexRowTableModal, setIsUpdateTableModal } = useTableStore()
  const { handleCheckboxChange } = useCheckbox(setSelectedRowKeys, selectedRowKeys, dataSource)
  const { groupByDomain } = useSearchStore()
  const methods = useFormContext()
  const formValues = methods?.watch()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const vid = parseInt(searchParams.get("vid"))

  const handleUpdate = (record) => {
    if (!isForm && type !== "many2many") {
      searchParams.set('id', record?.id)
      const _url = `/${VIEW_TYPE.FORM}?${searchParams.toString()}`
      navigate(_url)
    }
    else if (type === "many2many") {
      append(record)
    }
  }
  return dataSource && dataResponseLength > 0
    ? dataSource?.map((rowItem, indexRow) => {
      prepareRow(rowItem);
      const record = rowItem?.original

      return (
        typeTable === "group" ?
          <TableGroup
            {...props}
            dataResponseLength={dataResponseLength}
            rowRecord={record}
            columns={columns}
            indexRow={indexRow}
            rootField={rootField}
            isForm={isForm}
            resModel={model}
            viewData={viewData}
            visibleOptionalFields={visibleOptionalFields}
            specification={specification}
            domain={record.__domain}
            typeTable={groupByDomain?.values?.length > 1 ? "group" : "list"}
            columnWidths={columnWidths}
            type={type}
            level={level + 1}
            showCheckBox={showCheckBox}
            funcChoose={funcChoose}
            funcRemoveLine={funcRemoveLine}
            funcAddLine={funcAddLine}
            typeCo={typeCo}
            context={context}
            setDataSource={setDataSource}
          />
          :
          <Row row={rowItem} record={rowItem?.original} indexRow={indexRow} handleOnchange={handleOnchange} {...props} />
      )
    })
    : !isForm && (
      <tr>
        <td className='w-full' colSpan={columns?.length + 2}>
          <div className={`flex flex-col items-center justify-center gap-3 ${typeCo === COMPONENT_TYPE.FORM ? "my-6" : "my-20"}`}>
            <ImageWithBasePath
              src='assets/img/empty_document.svg'
              alt='empty_document.svg'
              width={120}
              height={140}
              className='h-[140px] w-[120px]' />
            <div className='text-xl font-extrabold'>{t("empty_data")}</div>
          </div>
        </td>
      </tr>
    )
}

const TableBody = (props) => {
  const { typeTable, dataSource, columns, level, items } = props
  const { setFooterGroupTable, fields } = useTableStore()

  useEffect(() => {
    if (typeTable === "group" && level === 0) {
      const newFooterGroupTable = {};
      dataSource?.forEach(rowRecord => {
        columns.forEach(col => {
          if (typeof rowRecord[col.key] === "number" || col.field.type === "monetary") {
            newFooterGroupTable[col.key] = (newFooterGroupTable[col.key] || 0) + rowRecord[col.key];
          }
        });
      });
      setFooterGroupTable(newFooterGroupTable);
    }
  }, [typeTable, fields]);
  return (
    <tbody className='overflow-hidden'>
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <TableBodyRow {...props} />
      </SortableContext>
    </tbody >
  )
}

export default TableBody
