import React, { useState } from 'react'
import ImageWithBasePath from '../general/ImageCustom'
import { useClickOutside } from '../../hooks/useClickOutside'
import ViewServices from '../../core/services/view-service/view-service'
import { useQuery } from '@tanstack/react-query'
import { capitalizeFirstLetter, findLatestDate, groupNotices } from '../../util/util'
import momentTZ from 'moment-timezone'
import { useNavigate } from 'react-router-dom'
import useHeaderStore from '../../store/header'
import { useFormatDate } from '../../hooks/useFormatDate'
import { useTranslation } from 'react-i18next'

const imageNotices = {
    sms: {
        imgPath: "assets/img/header/notification/sms_failure.svg",
        description: "SMS",
        domain: ["message_has_sms_error", "=", true],
    },
    email: {
        imgPath: "assets/img/header/notification/mailfailure.jpg",
        description: "email",
        domain: ["message_has_error", "=", true]
    },
    snail: {
        imgPath: "assets/img/header/notification/snailmail_failure.png",
        description: "thư bằng đường bưu điện",
        domain: ["message_ids.snailmail_error", "=", true]
    }
}

const description_prefix = "Đã xảy ra lỗi khi gửi"

const NotificationList = ({ listNotices, handleCloseNoticeList }) => {
    const navigate = useNavigate()
    const { getRelativeTime } = useFormatDate()
    const { t } = useTranslation()

    const renderNoticeItem = ({ title, type, items, model }) => {
        const latestDate = findLatestDate(items)
        const { lang } = useHeaderStore()

        const navigateNoticePage = () => {
            const actionData = {
                domain: [imageNotices[type]?.domain],
                views: [[false, "kanban"], [false, "form"], [false, "search"], [false, "list"]],
                res_model: model,
                name: lang === "vi_VN" ? `${t("failures")} ${t(type)}` : capitalizeFirstLetter(`${t(type)} ${t("failures")}`),
                context: { create: false }
            }

            sessionStorage.setItem("actionData", JSON.stringify(actionData))
            handleCloseNoticeList()
            navigate(`/list?model=${model}`)
        }

        return (
            <div className='flex items-center gap-2 p-3 hover:bg-[#e9ecef] cursor-pointer group' onClick={() => navigateNoticePage()}>
                <div className='flex items-center justify-center rounded-full bg-primary size-9'>
                    <ImageWithBasePath className="cursor-pointer" src="assets/img/header/notification/icon-notice.svg" alt="icon-notice" width={18} height={18} />
                </div>
                <div className='flex flex-col gap-1 flex-1'>
                    <div className='flex justify-between items-center'>
                        <h2 className='font-semibold'>{title}</h2>
                        <span className='bg-[rgba(237,28,36,1)] text-xs text-white p-1 rounded-full size-5 flex justify-center items-center'>{items?.length}</span>
                    </div>
                    <div className='flex justify-between items-center gap-8'>
                        <p className='flex-1'>{`${description_prefix} ${imageNotices[type]?.description}`}</p>
                        <div className='text-sm font-medium'>{getRelativeTime(momentTZ.utc(latestDate?.date).tz("Asia/Ho_Chi_Minh").format("DD-MM-YYYY HH:mm:ss"))}</div>
                        {/* <div className='rounded-full leading-[1] px-1 py-[2px] bg-primary text-white flex items-center justify-center text-[12px] group-hover:hidden'>{items?.length}</div> */}
                        {/* <Check className='text-primary hidden group-hover:block' strokeWidth={4} size={16} /> */}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='bg-white shadow-md rounded-[10px] absolute z-[20] top-full right-0 min-h-[100px] min-w-[450px] overflow-hidden'>
            <div className='font-semibold text-base text-[rgba(18,18,18,1)]  p-3'>Thông báo mới {listNotices?.length > 0 && `(${listNotices?.length})`}</div>
            <hr className='h-[2px]' />
            {
                listNotices.map((noticeItem) => (
                    renderNoticeItem({ title: noticeItem?.resModelName, items: noticeItem?.items, type: noticeItem?.notificationType, model: noticeItem?.model })
                ))
            }
        </div>
    )
}

const Notification = () => {
    const [isShowNotice, setIsShowNotice] = useState(false)

    const handleCloseNoticeList = () => {
        setIsShowNotice(false)
    }

    const noticeRef = useClickOutside(() => {
        handleCloseNoticeList()
    })

    const { data: viewResponse, refetch } = useQuery({
        queryKey: [`load-message-failure`,],
        queryFn: () => ViewServices.loadMessages(),
        refetchOnWindowFocus: false
    })

    return (
        viewResponse?.["mail.message"] &&
        <div ref={noticeRef} className='relative'>
            <div className='relative cursor-pointer' onClick={() => {
                setIsShowNotice(!isShowNotice)
                if (!isShowNotice) {
                    refetch()
                }
            }}>
                <ImageWithBasePath className="cursor-pointer" src="assets/img/header/bell.svg" alt="bell" width={24} height={24} />
                <span className='absolute -top-2 -right-2 rounded-[50rem] w-fit max-h-max leading-[1.1] px-1 py-[2px] bg-[rgba(237,28,36,1)] text-white flex items-center justify-center text-[10px]'>{viewResponse?.["mail.message"].length ?? 0}</span>
            </div>
            {isShowNotice && <NotificationList listNotices={groupNotices(viewResponse)} handleCloseNoticeList={handleCloseNoticeList} />}
        </div>
    )
}

export default Notification
