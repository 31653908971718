import React, { useEffect, useId, useState, useRef } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { Controller } from 'react-hook-form'
import ImageWithBasePath from '../general/ImageCustom'
import { checkIsImageLink } from '../../util/util'

const BinaryField = ({ name, methods, readonly, placeholder, required, invisible, value, widget }) => {
  const inputId = useId()
  const [selectedImage, setSelectedImage] = useState(null)
  const [initialImage, setInitialImage] = useState(value || null)
  const [isInsideTable, setIsInsideTable] = useState(false)
  const { setValue } = methods
  const binaryRef = useRef(null);

  const convertUrlToBase64 = async (url) => {
    try {
      // Fetch image từ URL
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error converting URL to Base64:', error);
      throw error;
    }
  };
  const extractBase64Data = (base64Url) => {
    if (base64Url.includes('base64,')) {
      return base64Url.split('base64,')[1];
    }
    return base64Url;
  };
  const handleImageChange = async (e, onChange) => {
    const file = e.target.files[0]
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setSelectedImage(imageUrl)
      setInitialImage(null)
      onChange(file)
      const compressedBase64 = await convertUrlToBase64(imageUrl);
      const base64Data = extractBase64Data(compressedBase64)
      setValue(name, base64Data, {
        shouldDirty: true
      });
    }
  }

  const handleRemoveImage = (onChange) => {
    setSelectedImage(null)
    setInitialImage(null) // Clear initial image when removed
    onChange(null) // Update the form state to remove the file
  }

  useEffect(() => {
    return () => {
      if (selectedImage) {
        URL.revokeObjectURL(selectedImage)
      }
    }
  }, [selectedImage])

  useEffect(() => {
    if (binaryRef.current) {
      const isInsideTable = !!binaryRef.current.closest("table");
      setIsInsideTable(isInsideTable)
    }
  }, []);

  return (
    <Controller
      name={name}
      control={methods.control}
      render={({ field }) => {
        const renderImage = initialImage || selectedImage

        return (
          <div ref={binaryRef} className='relative flex w-fit items-center gap-4 rounded-lg shadow-md mb-6'>
            {renderImage ? (
              <div className='relative group'>
                <img
                  src={checkIsImageLink(renderImage) ? renderImage : "assets/img/placeholder.png"}
                  alt='Selected'
                  className='h-32 w-32 max-w-fit rounded-lg object-cover'
                />
                {
                  !isInsideTable && <button
                    type='button'
                    onClick={() => handleRemoveImage(field.onChange)}
                    className='hidden group-hover:block absolute right-0 bottom-0 mr-1 mb-1 rounded-full bg-white p-1 transition-colors duration-300 hover:bg-red-600'
                  >
                    <ImageWithBasePath src='assets/img/icons/delete1.svg' alt='close-icon' className='h-4 w-4' />
                  </button>
                }
              </div>
            ) : (
              <label
                htmlFor={inputId}
                className='flex h-32 w-32 cursor-pointer items-center justify-center rounded-lg border-4 border-dashed border-gray-300 bg-white transition-colors duration-300 hover:bg-gray-200'
              >
                <ImageWithBasePath src='assets/img/add-icon.svg' alt='add-icon' className='h-12 w-12 opacity-50' />
                <input
                  id={inputId}
                  type='file'
                  readOnly={readonly}
                  placeholder={placeholder}
                  required={!invisible && required}
                  onChange={(e) => handleImageChange(e, field.onChange)}
                  className='hidden'
                />
              </label>
            )}
          </div>
        )
      }}
    />
  )
}

export default BinaryField