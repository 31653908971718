import { create } from 'zustand'

const useSearchStore = create((set) => ({
  searchMap: new Map(),
  modelSearch: "",
  searchString: '',
  groupBy: [],
  typeFieldsSearch: "",
  filterBy: [],
  tableHead: [],
  selectedTags: [],
  hoveredIndexSearchList: null,
  firstDomain: null,
  groupByDomain: null,
  setSearchString: (newSearch) => set(() => ({ searchString: newSearch })),
  setSelectedTags: (newSelectedTags) => set(() => ({ selectedTags: newSelectedTags })),
  setFilterBy: (newFilterBy) => set(() => ({ filterBy: newFilterBy })),
  setGroupBy: (newGroupBy) => set(() => ({ groupBy: newGroupBy })),
  setHoveredIndexSearchList: (newHoveredIndexSearchList) =>
    set(() => ({ hoveredIndexSearchList: newHoveredIndexSearchList })),
  setTableHead: (newTableHead) => set(() => ({ tableHead: newTableHead })),
  setFirstDomain: (newFirstDomain) => set(() => ({ firstDomain: newFirstDomain })),
  setGroupByDomain: (newGroupDomain) => set(() => ({ groupByDomain: newGroupDomain })),
  setTypeFieldsSearch: (typeFieldsSearch) => set(() => ({ typeFieldsSearch: typeFieldsSearch })),
  setModelSearch: (modelSearch) => set(() => ({ modelSearch: modelSearch }))
}))

export default useSearchStore
