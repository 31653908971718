import React, { useEffect, useState } from 'react';
import { User } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next'; // For translation
import { Link, useNavigate } from 'react-router-dom';
import { IoMdMenu } from "react-icons/io";
import useLogoutSubmit from '../../hooks/useLogoutSubmit';
import { all_routes } from '../../Router/all_routes';
import { useMetaContext } from '../../util/context/MetaContext';
import ImageWithBasePath from '../general/ImageCustom';
import SwitchLanguage from './SwitchLanguage';
import Notification from './Notification';
import { Avatar } from '../widget/Info';
import { isObjectEmpty } from '../../util/util';
import LoadingSmall from '../general/LoadingSmall';
import { useClickOutside } from '../../hooks/useClickOutside';
import useProfileStore from '../../store/profile';
import useUserSubmit from '../../hooks/useUserSubmit';

const Header = ({ setToggleMenuMobile }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { metadata, loadingImport } = useMetaContext()
  const { handleSubmit } = useForm()
  const { onLogout } = useLogoutSubmit(navigate)
  const [toggle, SetToggle] = useState(false)
  // const [isFullscreen, setIsFullscreen] = useState(false)
  const [isShowDropDownProfile, setIsShowDropDownProfile] = useState(false)
  const { getUserInfor } = useUserSubmit()
  const { dataUser } = useProfileStore()

  const route = all_routes
  // const isElementVisible = (element) => {
  //   return element.offsetWidth > 0 || element.offsetHeight > 0
  // }

  // const dataUser = getValues()

  const dropdownProfileRef = useClickOutside(() => {
    setIsShowDropDownProfile(false)
  })

  // useEffect(() => {
  //   const handleMouseover = (e) => {
  //     e.stopPropagation()

  //     const body = document.body
  //     const toggleBtn = document.getElementById('toggle_btn')

  //     if (body.classList.contains('mini-sidebar') && isElementVisible(toggleBtn)) {
  //       e.preventDefault()
  //     }
  //   }

  //   document.addEventListener('mouseover', handleMouseover)

  //   return () => {
  //     document.removeEventListener('mouseover', handleMouseover)
  //   }
  // }, [])

  // useEffect(() => {
  //   const handleFullscreenChange = () => {
  //     setIsFullscreen(
  //       document.fullscreenElement ||
  //       document.mozFullScreenElement ||
  //       document.webkitFullscreenElement ||
  //       document.msFullscreenElement
  //     )
  //   }

  //   document.addEventListener('fullscreenchange', handleFullscreenChange)
  //   document.addEventListener('mozfullscreenchange', handleFullscreenChange)
  //   document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
  //   document.addEventListener('msfullscreenchange', handleFullscreenChange)

  //   return () => {
  //     document.removeEventListener('fullscreenchange', handleFullscreenChange)
  //     document.removeEventListener('mozfullscreenchange', handleFullscreenChange)
  //     document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
  //     document.removeEventListener('msfullscreenchange', handleFullscreenChange)
  //   }
  // }, [])
  // const handlesidebar = (e) => {
  //   e.preventDefault()
  //   document.body.classList.toggle('mini-sidebar')
  //   SetToggle((current) => !current)
  // }
  // const expandMenu = () => {
  //   document.body.classList.remove('expand-menu')
  // }
  // const expandMenuOpen = () => {
  //   document.body.classList.add('expand-menu')
  // }
  // const sidebarOverlay = () => {
  //   document?.querySelector('.main-wrapper')?.classList?.toggle('slide-nav')
  //   document?.querySelector('.sidebar-overlay')?.classList?.toggle('opened')
  //   document?.querySelector('html')?.classList?.toggle('menu-opened')
  // }

  const exclusionArray = ['/reactjs/template/dream-pos/index-three', '/reactjs/template/dream-pos/index-one']
  if (exclusionArray.indexOf(window.location.pathname) >= 0) {
    return ''
  }

  // const toggleFullscreen = (elem) => {
  //   elem = elem || document.documentElement
  //   if (
  //     !document.fullscreenElement &&
  //     !document.mozFullScreenElement &&
  //     !document.webkitFullscreenElement &&
  //     !document.msFullscreenElement
  //   ) {
  //     if (elem.requestFullscreen) {
  //       elem.requestFullscreen()
  //     } else if (elem.msRequestFullscreen) {
  //       elem.msRequestFullscreen()
  //     } else if (elem.mozRequestFullScreen) {
  //       elem.mozRequestFullScreen()
  //     } else if (elem.webkitRequestFullscreen) {
  //       elem.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
  //     }
  //   } else {
  //     if (document.exitFullscreen) {
  //       document.exitFullscreen()
  //     } else if (document.msExitFullscreen) {
  //       document.msExitFullscreen()
  //     } else if (document.mozCancelFullScreen) {
  //       document.mozCancelFullScreen()
  //     } else if (document.webkitExitFullscreen) {
  //       document.webkitExitFullscreen()
  //     }
  //   }
  // }

  useEffect(() => {
    getUserInfor()
  }, [])

  return (
    <>
      <div className='header flex p-[15px]'>
        <div className={`header-left header-left-horizontal !px-4 ${toggle ? '' : 'active'}`}>
          <div
            className='logo logo-normal'
          >
            {metadata &&
              (!loadingImport ? (
                <div className='flex items-center justify-between gap-[16px]'>
                  <div className='flex h-10 w-[100px] items-start'>
                    <img
                      src={metadata?.logo}
                      alt='logo'
                      width={100}
                      height={40}
                      className='h-auto max-h-[40px] object-contain object-top'
                    />
                  </div>
                </div>
              ) : (
                <ImageWithBasePath src='../../../public/assets/logo/vitrust-white.svg' alt='img' />
              ))}
          </div>
        </div>
        {/* Header Menu */}
        <button className="!flex lg:!hidden p-3" onClick={() => setToggleMenuMobile(true)}>
          <IoMdMenu size={25} />
        </button>
        <div className={`ml-0 lg:ml-[25px] w-full !hidden md:!block`}>
          <img
            src='/assets/img/header/banner-header.svg'
            alt='banner-header'
            height={56}
            className='h-[56px] w-full rounded-lg border border-[#021301] object-cover'
          />
        </div>
        <ul className='nav user-menu min-w-fit xl:min-w-[296px] !justify-end gap-[16px] bg-white px-[16px] flex-nowrap'>
          {/* Search */}
          {/* <li className='nav-item nav-searchinputs'>
            <div className='top-nav-search'>
              <Link to='#' className='responsive-search'>
                <Search />
              </Link>

              <SearchBar />
            </div>
          </li> */}
          {/* <li className='nav-item nav-item-box'>
            <Link
              to='#'
              id='btnFullscreen'
              onClick={() => toggleFullscreen()}
              className={isFullscreen ? 'Exit Fullscreen' : 'Go Fullscreen'}
            >
              <FeatherIcon icon='maximize' />
            </Link>
          </li> */}
          {/* <li className='nav-item nav-item-box'>
            <ImageWithBasePath src="assets/img/header/glass-search.svg" alt="glass-search" width={24} height={24} />
          </li> */}
          <li className='nav-item nav-item-box'>
            <Notification />
          </li>
          <li>
            <SwitchLanguage />
          </li>
          <li ref={dropdownProfileRef} className='nav-item dropdown has-arrow main-drop'>
            <button type='button' className='dropdown-toggle nav-link userset p-0' onClick={() => setIsShowDropDownProfile(!isShowDropDownProfile)}>
              <span className='user-info'>
                <span className=''>
                  {isObjectEmpty(dataUser) ? <LoadingSmall /> : dataUser?.picture ? (
                    <img src={dataUser?.picture} alt='img' className='img-fluid w-8 h-8 rounded-full object-cover' />
                  ) :
                    <Avatar fullName={dataUser?.name} id={dataUser?.sub} size={32} allowShowDetail={false} />
                  }

                </span>
                {/* <span className='user-detail'>
                  <span className='user-name'>{getValues('name')}</span>
                  <span className='user-role'>{getValues('email')}</span>
                </span> */}
              </span>
            </button>

            {
              isShowDropDownProfile && <div className='dropdown-menu menu-drop-user'>
                <div className='profilename'>
                  <Link className='dropdown-item' to={route.profile}>
                    <User className='me-2' />
                    {t('my_profile')}
                  </Link>
                  <hr className='m-0' />
                  <form onSubmit={handleSubmit(onLogout)}>
                    <button
                      type='submit'
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        display: 'inline',
                        padding: '0px',
                        width: '100%',
                      }}
                    >
                      <div className='dropdown-item logout pb-0'>
                        <ImageWithBasePath src='assets/img/icons/log-out.svg' alt='img' className='me-2' /> {t('logout')}
                      </div>
                    </button>
                  </form>
                </div>
              </div>
            }
          </li>
        </ul>
        {/* /Header Menu */}
        {/* Mobile Menu */}
        {/* <div className='dropdown mobile-user-menu'>
          <Link to='#' className='nav-link dropdown-toggle' data-bs-toggle='dropdown' aria-expanded='false'>
            <i className='fa fa-ellipsis-v' />
          </Link>
          <div className='dropdown-menu dropdown-menu-right'>
            <Link className='dropdown-item' to='profile'>
              {t('my_profile')}
            </Link>
            <Link className='dropdown-item' to='generalsettings'>
              {t('settings')}
            </Link>
            <form onSubmit={handleSubmit(onLogout)}>
              <button
                type='submit'
                style={{
                  backgroundColor: 'transparent',
                  border: 'none',
                  display: 'inline',
                  padding: '0px',
                  width: '100%',
                }}
              >
                <div className='dropdown-item logout pb-0'>
                  <ImageWithBasePath src='assets/img/icons/log-out.svg' alt='img' className='me-2' /> {t('logout')}
                </div>
              </button>
            </form>
          </div>
        </div> */}
        {/* /Mobile Menu */}
      </div>
    </>
  )
}

export default Header
