import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useClickOutside } from '../../hooks/useClickOutside';
import ModelService from '../../core/services/model-service/model-service';

const StatusDropdown = ({ selection, isForm, id, resModal, name, specification, contextAction, state, setOnchangeData, refetch, handleOnchange }) => {
  const colors = {
    normal: 'bg-[#e9ecef]',
    done: 'bg-primary',
    blocked: 'bg-red-500',
  };

  const [status, setStatus] = useState(colors.normal);
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useClickOutside(() => setIsOpen(false));

  const handleClick = async (status) => {
    setIsOpen(!isOpen);
    setStatus(colors[status]);
    await ModelService.saveForm({
      ids: id ? [id] : [],
      resModel: resModal,
      data: { [name]: status },
      // specification: specification,
      context: contextAction,
    }).then((res) => {
      // setOnchangeData(res)
      refetch()
    })
  };

  return (
    <div ref={buttonRef} className={isForm && "absolute top-7 right-6 z-10"}>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`transition-all rounded-full size-5 cursor-pointer shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)] focus:!shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)] ${state == "normal" ? "bg-[#e9ecef]" : state == "done" ? "bg-primary" : "bg-red-500"}`}
      />
      {isOpen && (
        <div className="absolute w-[200px] top-full bg-white transition-all right-0 flex flex-col rounded-lg z-10 shadow-md border">
          {selection.map((item, index) => {
            const isActive = item[0] == state;
            return (
              <div
                key={index}
                className={`cursor-pointer relative hover:bg-gray-200 pl-[40px] pr-[20px] py-[5px] flex items-center gap-2 ${isActive ? 'bg-gray-100' : ''}`}
                onClick={() => handleClick(item[0])}
              >
                {isActive && (
                  <FaCheck className="absolute top-1/2 transform -translate-x-1/2 -translate-y-1/2 left-5" />
                )}
                <div className={`rounded-full size-4 shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)] !border-none ${colors[item[0]]}`} />
                {item[1]}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default StatusDropdown;
