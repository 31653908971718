import React, { useState } from 'react'
import ImageWithBasePath from '../../general/ImageCustom'
import useTableStore from '../../../store/table'
import { useTranslation } from 'react-i18next'
import ActionService from '../../../core/services/actions'
import useHeaderStore from '../../../store/header'
import ModalConfirmDelete from '../../modal/ModalConfirmDelete'
import ModalConfirmArchive from '../../modal/ModalConfirmArchive'
import useActionHandler from '../../../hooks/useActionButton'
import { CustomToastMessage } from '../../../util/toast/customToastMsg'
import ModalDynamic from '../../modal/ModalDynamic'

const ActionCheckbox = (props) => {
  const { dataToolbar, openModal, setOpenImport, setOpenModal, model, refetch, dataResponseLength, setErrorMessage, viewData } = props
  const { t } = useTranslation()
  const { selectedRowKeys, setSelectedRowKeys, setPage, page, pageLimit } = useTableStore()
  const { rootContext } = useHeaderStore()
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [isArchive, setIsArchive] = useState()
  const [isConfirmArchive, setIsConfirmArchive] = useState(false)
  const { modalAction, handleButtonAction, handleCloseActions } = useActionHandler({
    context: rootContext,
    resModel: model,
    dataForm: { id: selectedRowKeys },
    refetch,
  });

  const handleClick = (id) => {
    if (id == openModal) {
      setOpenModal(0)
    } else {
      setOpenModal(id)
    }
    setOpenImport(false)
  }

  const handleDelete = async () => {
    try {
      await ActionService.removeRows({ model: model, ids: selectedRowKeys, context: rootContext })
      if (selectedRowKeys?.length >= pageLimit || dataResponseLength === selectedRowKeys?.length) {
        setPage(0)
        if (page === 0) {
          refetch()
        }
      } else {
        refetch()
      }
      CustomToastMessage.success(t('success_delete'))
    } catch (error) {
      setErrorMessage(error)
    } finally {
      setIsConfirmDelete(false)
      setOpenModal(0)
      setSelectedRowKeys([])
    }
  }

  const handleArchive = async () => {
    const actionName = isArchive ? "archive" : "unarchive"
    try {
      await ActionService.callButton({ method: `action_${actionName}`, resModel: model, ids: [...selectedRowKeys], context: rootContext })
      CustomToastMessage.success(t(`save_${actionName}_success`))
      refetch()
    } catch (error) {
      CustomToastMessage.error(t(`save_${actionName}_failure`))
    } finally {
      setIsConfirmArchive(false)
      setOpenModal(0)
      setSelectedRowKeys([])
    }
  }

  const handleActionCheckBox = async (actionName) => {
    if (actionName === "delete") {
      setIsConfirmDelete(true)
      return
    } else if (actionName === "archive" || actionName === "unarchive") {
      setIsArchive(actionName === "archive")
      setIsConfirmArchive(true)
      return
      // await ActionService.callButton({ method: `action_${actionName}`, resModel: model, ids: [...selectedRowKeys], context: rootContext })
      // CustomToastMessage(t(`save_${actionName}_success`))
    }

    try {
      if (actionName === 'duplicate') {
        await ActionService.duplicateModel({ model: model, ids: selectedRowKeys, context: rootContext })
        CustomToastMessage.success(t('successs_duplicate'))
      }

      refetch()
    } catch (error) {
      CustomToastMessage.error(actionName === "duplicate" ? t("fail_duplicate") : "")
    } finally {
      setOpenModal(0)
      setSelectedRowKeys([])
    }
  }

  const listRootActions = {
    duplicate: {
      icon: 'assets/img/icons/copy.svg',
    },
    archive: {
      icon: 'assets/img/icons/archive.svg',
    },
    unarchive: {
      icon: 'assets/img/icons/unarchive.svg',
    },
    // read: {
    //   icon: "assets/img/icons/export.svg",
    //   default: true
    // },
    delete: {
      icon: "assets/img/icons/delete3.svg",
    },
    // edit: {
    //   icon: "assets/img/icons/edit-5.svg"
    // },
    // import: {
    //   icon: "assets/img/icons/import.svg"
    // },
    // create: {
    //   icon: "assets/img/icons/create.svg"
    // }
  }
  const listDefaultActionsKey = Object.keys(listRootActions)
  const rootAction = viewData?.views?.list?.toolbar?.root

  return (
    <div className={`${selectedRowKeys?.length > 0 && dataToolbar ? "flex" : "hidden"} items-center gap-[8px] !order-3 xl:!order-2 min-w-[70%] xl:min-w-max flex-1`}>
      <div className='flex items-center gap-[8px] rounded-[8px] bg-[#d4ebfa] px-[16px] py-[8px] lowercase whitespace-nowrap min-w-max'>
        {selectedRowKeys?.length} {t("selected")}
        <button onClick={() => setSelectedRowKeys([])}>
          <ImageWithBasePath src='assets/img/close-icon.svg' alt='close icon' className='h-4' />
        </button>
      </div>
      <button
        type='button'
        onClick={() => handleClick(1)}
        className='relative flex cursor-pointer items-center gap-[8px] rounded-[8px] bg-[#dee2e6] px-[16px] py-[8px] whitespace-nowrap min-w-max'
      >
        <ImageWithBasePath src='assets/img/print.svg' alt='setting' className='h-4' />
        {t("print")}
        {openModal == '1' && (
          <div
            onClick={(e) => e.stopPropagation()}
            className='absolute left-[-190px] top-[37px] z-[5] flex h-max max-h-[400px] w-[280px] flex-col gap-[6px] overflow-hidden rounded-[8px] border-[1px] border-solid border-[#ebeaea] bg-[#fff] shadow-sm'
          >
            <div className='h-full w-full overflow-y-scroll'>
              {dataToolbar?.print.map((item) => {
                return (
                  <div key={item.id} className='px-[24px] py-[12px] hover:bg-[#f6f4f4]'>
                    {item.name}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </button>
      <button
        type='button'
        onClick={() => handleClick(2)}
        className='relative flex cursor-pointer items-center rounded-[8px] bg-[#dee2e6] px-[16px] py-[8px] whitespace-nowrap min-w-max gap-2'
      >
        <ImageWithBasePath src='assets/img/setting.svg' className='h-4 w-4' alt='setting' />
        {t("action")}
        {openModal == 2 && (
          <div
            onClick={(e) => e.stopPropagation()}
            className='absolute left-0 top-[42px] z-[5] flex h-max max-h-[400px] w-[280px] flex-col gap-[6px] overflow-hidden rounded-[8px] border-[1px] border-solid border-[#ebeaea] bg-[#fff] shadow-sm'
          >
            <div className='h-full w-full overflow-y-auto'>
              {
                listDefaultActionsKey?.length > 0 && listDefaultActionsKey?.map((actionKey, index) => (
                  rootAction[actionKey] && (
                    <button onClick={() => handleActionCheckBox(actionKey)} key={`action-key-${index}`} className='flex w-full gap-2 px-[24px] py-[10px] text-left hover:bg-[#f6f4f4] items-center ' type='button'>
                      <ImageWithBasePath src={listRootActions[actionKey]?.icon} className="opacity-65" />
                      <span className='font-normal text-sm'>{t(actionKey)}</span>
                    </button>
                  )
                ))
              }
              {props?.dataToolbar?.action?.map((item) => {
                return (
                  <div key={item.id} onClick={() => handleButtonAction(item.id)} className='px-[24px] py-[10px] text-left hover:bg-[#f6f4f4]'>
                    {item.name}
                  </div>
                )
              })}
              {modalAction?.status && <ModalDynamic
                {...modalAction}
                handleCloseActions={() => {
                  handleCloseActions()
                  setSelectedRowKeys([])
                  setOpenModal(-1)
                }}
                handleOnchange={{}}
                isInForm={false}
              />}
            </div>
          </div>
        )}
      </button>
      <ModalConfirmDelete
        title={t("confirm_delete_data")}
        content={t("delete_record_confirm_title")}
        isShowConfirmDelete={isConfirmDelete}
        handleClose={() => setIsConfirmDelete(false)}
        handleDelete={handleDelete}
      />
      <ModalConfirmArchive
        title={t(isArchive ? "confirm_save" : "confirm_cancellation")}
        content={t(isArchive ? "save_archive_records_title" : "save_unarchive_records_title")}
        isArchive={isArchive}
        handleClose={() => setIsConfirmArchive(false)}
        handleArchive={handleArchive}
        isShowConfirmArchive={isConfirmArchive}
      />
    </div>
  )
}

export default ActionCheckbox
