import React, { useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { ButtonSelectFiles } from "../../pages/chatter/SelectFiles";
import { isBase64File } from "../../util/util";
import RenderFiles from "../../pages/chatter/RenderFiles";
import { useTranslation } from "react-i18next";

const FileUpload = (props) => {
    const { name, methods, isForm, model, string } = props
    const { t } = useTranslation()
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadError, setUploadError] = useState();

    return (
        <Controller
            name={name}
            control={methods?.control}
            rules={{
                required: selectedFiles?.length === 0 ? { value: true, message: `${t('file_required')}` } : false,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                useEffect(() => {
                    let datas = selectedFiles?.[0]?.datas;
                    if (datas && isBase64File(datas)) {
                        datas = datas.split(",")[1];
                    }
                    onChange(datas || null);
                    if (model === "ir.attachment") {
                        methods.setValue("name", selectedFiles?.[0]?.name || selectedFiles?.[0]?.display_name, {
                            shouldDirty: true
                        })
                    }
                }, [selectedFiles]);

                return (
                    <div>
                        {selectedFiles?.length === 0 && (
                            <ButtonSelectFiles
                                fileInputRef={fileInputRef}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                setUploadError={setUploadError}
                                placeHolder={"Tải tệp của bạn"}
                                isInForm={isForm}
                            />
                        )}
                        {uploadError && <div className="text-[#de4747]">{uploadError}</div>}
                        {selectedFiles.length > 0 && (
                            <RenderFiles selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
                        )}
                        {error && <p className='text-[#de4747] text-sm mt-1'>{error.message}</p>}
                    </div>
                );
            }}
        />
    );
};

export default FileUpload;
