import React, { memo, useState } from 'react'
import ModelService from '../../../core/services/model-service/model-service'
import { useQuery } from '@tanstack/react-query'
import { number } from 'prop-types'
import { evalJSONDomain } from '../../../util/util'
import OptionDuration from './OptionDuration'
import useHeaderStore from '../../../store/header'

const StatusbarDuration = ({ resModel, defaultValue, domain, value, name, specifications, id, model, setOnchangeData, refetch }) => {
  const specification = {
    id: number,
    name: "",
    fold: ""
  }
  const [disabled, setDisabled] = useState(false)
  const { lang } = useHeaderStore()
  const [modelStatus, setModalStatus] = useState(false)
  const { data: dataResponse } = useQuery({
    queryKey: [
      `data-status-duration`,
      specification,
      lang
    ],
    queryFn: () => ModelService.getAll({
      resModel: resModel,
      specification: specification,
      domain: evalJSONDomain(domain, value),
      limit: 10,
      offset: 0,
      fields: "",
      groupby: [],
      context: {
        lang: lang
      },

      sort: ""
    }),
    enabled: true,
    refetchOnWindowFocus: false,
    placeholderData: defaultValue,
  })

  const handleClick = async (stage_id) => {
    setDisabled(true)
    if (stage_id) {
      await ModelService.changeStatus({ stage_id, name, specifications, id, model, lang }).then((res) => {
        if (res) {
          // setOnchangeData(res)
          setDisabled(false)
          refetch()
        }
      })
    }
  }
  return (
    <div className={`w-full items-center  rounded-[10px] !flex flex-col-reverse justify-center lg:!justify-end lg:!flex-row gap-y-[15px]`}>
      <div className='flex items-center gap-[24px]'>
        <div className='flex items-center '>
          {dataResponse && dataResponse?.records?.filter((item) => !item?.fold || item.id == defaultValue)?.map((option, index) => {
            const value = option?.id === defaultValue
            return (
              <OptionDuration
                key={option.id}
                id={option.id}
                isActive={value}
                label={option?.name}
                isFirst={index === 0}
                isLast={index === dataResponse?.records?.length - 1}
                handleClick={handleClick}
                disabled={disabled}
              />
            )
          })}

        </div>
        <div onClick={() => setModalStatus(!modelStatus)} className='w-[24px] h-[24px] cursor-pointer relative'>
          <img src="/assets/logo/moreIcon.svg" alt='more icon' width={24} height={8} />
          {modelStatus && (
            <div onClick={(e) => e.stopPropagation()} className=' w-[150px] z-20 absolute rounded-[6px] bg-[#fff] shadow-md left-[-120px] top-[30px] overflow-hidden'>
              {dataResponse && dataResponse?.records?.filter((item) => item?.fold && item.id != defaultValue)?.map((option, index) => {
                const value = option?.id === defaultValue
                return (
                  <div className={`py-[4px] px-[16px] hover:bg-[#f8f9f9] ${value ? 'bg-primary' : 'bg-[#F1F6F1]'}`} onClick={() => !disabled && handleClick(option.id)} key={option.id}>
                    {option.name}
                  </div>
                )
              })}
            </div>
          )}

        </div>
      </div>

    </div>
  )
}

export default memo(StatusbarDuration)

// const statusOptions1 = [
//   ["backlog", "Backlog"],
//   ["todo", "To Do"],
//   ["inprocess", "In Process"],
//   ["done", "Done"],
// ]