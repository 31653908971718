import React, { useRef, useEffect } from "react";
import { Controller } from "react-hook-form";

const HtmlField = ({ name, control, readonly, isForm, defaultValue = "", handleOnchange }) => {
    const divRef = useRef(null);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field: { onChange, value } }) => {
                useEffect(() => {
                    if (divRef.current && divRef.current.innerHTML !== value) {
                        divRef.current.innerHTML = value || "";
                    }
                }, [value]);

                const handleInput = (e) => {
                    onChange(e.currentTarget.innerHTML);
                    handleOnchange(e.currentTarget.innerHTML)
                };

                return (
                    <div
                        ref={divRef}
                        contentEditable={!readonly}
                        onInput={handleInput}
                        className={`${!isForm || readonly ? "!bg-transparent focus-within:!border-none" : "field"}`}
                    />
                );
            }}
        />
    );
};

export default HtmlField;
