import { useTranslation } from "react-i18next"
import ModalLayer from "../../modal/ModalLayer"

const ModalError = ({ data, setErrorMessage }) => {
    const { t } = useTranslation()
    const handleClose = () => {
        setErrorMessage(null)
    }
    return (
        <ModalLayer isOpen={Boolean(data)} onClose={handleClose}>
            <div className="rounded-[4px] bg-[#fff] flex flex-col gap-[16px] max-w-[1140px] relative">
                <div className="font-bold text-[20px]">{t("confirm_failure")}</div>
                <div className="flex flex-col gap-[32px]">{data?.message}</div>
                <button onClick={handleClose} type="button" className="button-primary w-fit">Đóng</button>
            </div>
        </ModalLayer>
    )
}
export default ModalError