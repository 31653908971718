import { useState } from "react";
import ActionService from "../core/services/actions";
import { getSubdomain } from "../util/util";
import { CustomToastMessage } from "../util/toast/customToastMsg";

const useActionHandler = ({ context, resModel, dataForm, refetch, }) => {
  const hostname = window.location.hostname;
  const [loading, setLoading] = useState(false);
  const [modalAction, setModalAction] = useState({ status: false, id: null, res_model: null, views: null, display_name: "", res_id: null });

  const handleOpenModal = async ({ actionId, ...params }) => {
    setModalAction(prev => ({ ...prev, ...params, status: true, idModal: actionId }));
  };

  const handleCloseActions = () => {
    setModalAction(prev => ({ ...prev, status: false, id: null, res_model: null, views: null, display_name: "", res_id: null }));
    refetch && refetch();
  };

  const handleOpenNewPage = async ({ actionResult }) => {
    const { url, target } = actionResult || {}
    if (!url) return CustomToastMessage.error('No such URL');
    if (target === "self") {
      window.location.href = url
    }
    window.open(`${process.env.REACT_APP_DOMAIN}${url}&db=${getSubdomain(hostname)}`, '_blank')
  };

  const resultLoadAction = {
    "ir.actions.act_window": (params) => handleOpenModal(params),
    "ir.actions.server": (params) => handleRunAction(params),
    "ir.actions.act_url": ({ actionResult }) => handleOpenNewPage({ actionResult }),
  };

  const resultRunAction = {
    "ir.actions.act_url": ({ actionResult }) => handleOpenNewPage({ actionResult }),
    "ir.actions.act_window": (params) => handleOpenModal({ ...params, actionId: null }),
  };

  const handleRunAction = async ({ actionId }) => {
    if (!actionId) return CustomToastMessage.error('No such report');
    setLoading(true);
    try {
      const actionResponse = await ActionService.runAction({ idAction: actionId, idDetail: dataForm?.id, model: resModel });
      resultRunAction[actionResponse?.result?.type]({ actionId, actionResult: actionResponse?.result, formValues: context });
    } catch (err) {
      CustomToastMessage.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleButtonAction = async (actionId) => {
    if (!actionId) return CustomToastMessage.error('No such report');
    setLoading(true);
    try {
      const actionResponse = await ActionService.loadAction({ idAction: actionId, idDetail: dataForm?.id, model: resModel, context: context });
      resultLoadAction[actionResponse?.result?.type]({ actionId, actionResult: actionResponse?.result, formValues: context });
    } catch (err) {
      CustomToastMessage.error(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    modalAction,
    setModalAction,
    resultRunAction,
    resultLoadAction,
    handleButtonAction,
    handleCloseActions
  };
};

export default useActionHandler;