export const COLORS = [
    {
        name: "no_color",
        color: "RGBA(230.1375,221.3625,221.3625,1)",
        id: 0
    },
    {
        name: "red",
        color: "rgba(255,155.5,155.5,1)",
        id: 1
    },
    {
        name: "orange",
        color: "RGBA(247.0375,198.06116071,152.4625,1)",
        id: 2
    },
    {
        name: "yellow",
        color: "RGBA(252.88960843, 226.89175248, 135.61039157,1)",
        id: 3
    },
    {
        name: "cyan",
        color: "RGBA(187.45210396, 215.03675558, 248.04789604,1)",
        id: 4
    },
    {
        name: "purple",
        color: "RGBA(216.79194664, 167.70805336, 203.91748283,1)",
        id: 5
    },
    {
        name: "almond",
        color: "RGBA(247.84539474, 213.9484835, 199.65460526,1)",
        id: 6
    },
    {
        name: "teal",
        color: "RGBA(136.6125, 224.8875, 218.94591346,1)",
        id: 7
    },
    {
        name: "blue",
        color: "RGBA(150.60535714, 165.68382711, 248.89464286,1)",
        id: 8
    },
    {
        name: "raspberry",
        color: "RGBA(254.94583333, 157.55416667, 203.95543194,1)",
        id: 9
    },
    {
        name: "green",
        color: "RGBA(182.62075688, 236.87924312, 189.81831118,1)",
        id: 10
    },
    {
        name: "violet",
        color: "RGBA(230.11575613, 219.41069277, 252.08930723,1)",
        id: 11
    },
];