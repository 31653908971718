import { useLocation, useNavigate } from 'react-router-dom'
import useToolBarStore from '../../../store/toolbar'

const useToolBar = (vid, model, funcReset, isInForm) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { pathname } = location
    const baseUrl = pathname.replace(/^\/?(new-|update-)?/, '')
    const { breadCrumbs, setBreadCrumbs } = useToolBarStore()

    const onClickAddNew = () => {
        // funcReset()
        navigate(`/form?vid=${vid}&model=${model}`)
    }

    const onClickBack = () => {
        const newVid = localStorage.getItem("id")
        const idForm = localStorage.getItem("idForm")
        if (!vid && breadCrumbs?.length > 0) {

            const actionData = breadCrumbs?.[breadCrumbs?.length - 1]

            const { view, vid, model, id } = actionData

            const url = `/${view}?${vid ? `vid=${vid}` : ''}`

            // console.log("actionData", actionData);
        }
        if (!vid && newVid == "426") {
            navigate(`/form?vid=${newVid}&model=helpdesk.team&id=${idForm}`);

        } else {
            navigate(`/list?vid=${vid}&model=${model}`);
        }
    }


    const isShow = !(pathname === "/list")

    return {
        baseUrl,
        pathname,
        isShow,
        onClickAddNew,
        onClickBack
    }
}

export default useToolBar
