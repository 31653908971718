import { getMessage, sentMessage, updateContentPath, uploadPath, webImagePath } from "../../../constant/endpoint"
import requests from "../AxiosClient"

const ChatterAction = {
    getComment: async ({ thread_model = 'helpdesk.ticket', thread_id = 3, limit = 100, lang }) => {
        try {
            const jsonData = {
                "thread_id": thread_id,
                "thread_model": thread_model,
                "limit": limit,
                with_context: {
                    lang: lang
                }
            }
            return requests.post(getMessage, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } catch (error) {
            console.error('Error when sent message:', error)
            throw error
        }
    },
    sentComment: async ({ thread_model, thread_id, attachment_ids = [], message, subtype }) => {
        try {
            const jsonData = {
                "context": {
                    "tz": "Asia/Saigon",
                    "uid": 2,
                    "allowed_company_ids": [1],
                    "mail_post_autofollow": false,
                    "temporary_id": 142183.01
                },
                "post_data": {
                    "body": message,
                    "message_type": "comment",
                    attachment_ids: attachment_ids,
                    attachment_tokens: [],
                    "subtype_xmlid": subtype
                },
                "thread_id": Number(thread_id),
                "thread_model": thread_model
            }
            return requests.post(sentMessage, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } catch (error) {
            console.error('Error when sent message:', error)
            throw error
        }
    },
    upload: async (data) => {
        try {
            return requests.post(uploadPath, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
        } catch (error) {
            console.error('Error when sent message:', error)
            throw error
        }
    },
    getImage: async ({ filename, checksum }) => {
        try {
            return requests.get(`${webImagePath}?filename=${filename}&unique=${checksum}&width=1920&height=300`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } catch (error) {
            console.error('Error when sent message:', error)
            throw error
        }
    },
    deleteComment: async ({ message_id }) => {
        try {
            const jsonData = {
                "attachment_ids": [],
                "attachment_tokens": [],
                "body": "",
                "message_id": message_id
            }
            return requests.post(updateContentPath, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        } catch (error) {
            console.error('Error when sent message:', error)
            throw error
        }
    }

}
export default ChatterAction