import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { formatFloatNumber } from '../../util/util';
import { useTranslation } from 'react-i18next';

const IntegerField = ({
  name,
  value,
  readonly,
  placeholder,
  required,
  invisible,
  methods,
  isForm,
  defaultValue,
  handleOnchange,
  string
}) => {
  const { t } = useTranslation()
  if (!isForm) {
    return <span>{value ?? defaultValue}</span>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={{
        required: (required && !invisible) ? { value: true, message: `${string} ${t("must_required")}` } : false,
        validate: (val) => Number.isInteger(parseInt(val)) || 'Invalid number',
      }}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const { setError, clearErrors } = methods;
        useEffect(() => {
          if (value) {
            clearErrors(name);
          }
        }, [value])
        return (
          <>
            <input
              value={value !== undefined && value !== null ? value : ''}
              onBlur={(e) => {
                if (e.target.value) {
                  const rawValue = e.target.value.trim();
                  const parsedValue = parseInt(rawValue, 10);
                  if (!readonly && !isNaN(parsedValue)) {
                    onBlur();
                    handleOnchange(name, parsedValue);
                    clearErrors(name)
                  }
                } else {
                  setError(name, {
                    type: "required",
                    message: `${string} ${t("must_required")}`,
                  });
                }
              }}
              onChange={(e) => {
                if (e.target.value) {
                  const rawValue = e.target.value.trim();
                  const parsedValue = parseInt(rawValue, 10);
                  if (!readonly && !isNaN(parsedValue)) {
                    onChange(parsedValue);
                    clearErrors(name)
                  } else {
                    setError(name, {
                      type: "required",
                      message: `${string} ${t("must_required")}`,
                    });
                  }
                }
              }}
              type="text"
              readOnly={readonly}
              disabled={readonly}
              placeholder={placeholder}
              required={!invisible && required}
              style={invisible ? { display: 'none' } : {}}
              className={`${!isForm ? '!bg-transparent focus-within:!border-none' : 'field'
                }`}
            />
            {error && <p className="text-[#de4747] text-sm mt-1">{error.message}</p>}
          </>
        )
      }}
    />
  );
};

export default IntegerField;
