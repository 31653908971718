export const themes = {
  // ATOM
  atom: {
    name: 'ATOM',
    defaultBank: '',
    primary: '#ED1C24',
    sidebar: '#AD1112',
    sidebarHover: '#93090B',
    selectHover: '#ED1C241A',
    textPrimary: '#262626',
    textSecondary: '#B9BAC4',
    error: '#F5222D',
    favIcon: '/images/atom/favIcon.png',
    loginBg: '/images/atom/loginBg.png',
    loginLogo: '/images/atom/loginLogo.png',
    sidebarLogo: '/images/atom/sidebarLogo.png',
    banner: '/images/atom/banner-header.svg',
    bannerOCB: '/images/isb/banner-ocb.png',
    discountWeb: '',
    overviewURL: {
      vi: 'https://metabase-oce-demo-stag.atomsolution.vn/public/dashboard/bc0bd8f7-f71b-46ba-81d3-0ddec7046ca7',
      en: 'https://metabase-oce-demo-stag.atomsolution.vn/public/dashboard/18f092a5-605e-4a13-8db7-b5eb38da73be',
    },
  },

  // OCB
  ocb: {
    name: 'OCB',
    defaultBank: 'ocb',
    primary: '#008F3C',
    sidebar: 'linear-gradient(178deg, #016A0D -0.89%, #04B342 99.46%)',
    sidebarHover: '#005E28',
    selectHover: '#08813C10',
    textPrimary: '#262626',
    textSecondary: '#BFBFBF',
    error: '#F5222D',
    favIcon: '/images/ocb/favIcon.png',
    loginBg: '/images/ocb/loginBg.png',
    loginLogo: '/images/ocb/loginLogo.png',
    sidebarLogo: '/images/ocb/sidebarLogo.png',
    banner: '/images/isb/banner-big.png',
    bannerOCB: '/images/isb/banner-ocb.png',
    discountWeb: 'https://isb.edu.vn/tuyen-sinh/',
    overviewURL: {
      vi: 'https://metabase-oce-demo-stag.atomsolution.vn/public/dashboard/06bd50e7-4d8e-4fa8-86d2-f0d5c54eae52',
      en: 'https://metabase-oce-demo-stag.atomsolution.vn/public/dashboard/65614431-89b7-4fe1-821f-4070e1b1402b',
    },
  },
}

export const theme =
  themes[
  Object.keys(themes).find((item) =>
    window.location.host.split('.')[0].includes(item)
  ) || process.env.REACT_APP_DEFAULT_THEME
  ]
