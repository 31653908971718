import React from "react";
import styled from "styled-components";
import Row from "../Row";

const StyledStaticData = styled.td`
  background: white;
  &:first-of-type {
    min-width: 20ch;
  }
`;

const StyledStaticTableRow = styled.tbody`
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 30%) 0px 10px 10px -5px;
`;

export const StaticTableRow = ({ row, ...props }) => {

    return (
        <StyledStaticTableRow key={row?.id}>
            <Row row={row} record={row?.original} indexRow={row?.index} {...props} />
        </StyledStaticTableRow>
    );
};
