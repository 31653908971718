export const COMPONENT_TYPE = {
  GROUP: 'group',
  FIELD: 'field',
  TREE: 'list',
  FORM: 'form',
  LIST: "list",
  DIV: "div",
  SETTING: "setting",
  SPAN: "span"
}

export const OBJECT_POSITION = 2
export const LIMIT_LOOP = 2

export const TABLE_TYPE = {
  GROUP: 'group',
  LIST: 'list',
}
