import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { SidebarContext } from '../util/context/SidebarContext'
import UserService from '../core/services/auth'
import { LocalStorageService } from '../util/lib/LocalStorage'
import useSidebar from '../store/sidebar'
import { CustomToastMessage } from '../util/toast/customToastMsg'

const useLoginSubmit = ({ navigate, isRemember }) => {
  const { setIsLoading } = useContext(SidebarContext)
  const { setIsNearLogin } = useSidebar()
  const { t } = useTranslation()
  const onSubmit = (data) => {
    setIsLoading(true)
    LocalStorageService.setStayLoginIn(isRemember)
    UserService.login(data)
      .then((res) => {
        LocalStorageService.setToken(res)
        LocalStorageService.setRefreshToken(res)
        CustomToastMessage.success(t('success_sign_in'))
        setIsLoading(false)
        setIsNearLogin(true)
        navigate('/list')
      })
      .catch((err) => {
        setIsLoading(false)
        navigate('/signin')
        CustomToastMessage.error(err?.detail || t('error_sign_in'))
      })
  }
  return { onSubmit }
}

export default useLoginSubmit
