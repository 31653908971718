import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { formatFloatNumber } from '../../util/util';
import { useTranslation } from 'react-i18next';

const FloatField = ({
  name,
  value: propValue,
  readonly,
  placeholder,
  required,
  invisible,
  methods,
  isForm,
  handleOnchange,
  defaultValue,
  relation,
  string,
}) => {
  const { t } = useTranslation();

  if (!isForm) {
    return <span>{formatFloatNumber(propValue ?? defaultValue)}</span>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={{
        required: required && !invisible ? { value: true, message: `${string} ${t('must_required')}` } : false,
        validate: (val) =>
          val === undefined || val === null || val === '' || !isNaN(parseFloat(String(val).replace(',', '.'))) || t("invalid_number"),
      }}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const { setError, clearErrors } = methods;

        // Local state để quản lý giá trị hiển thị trong input
        const [inputValue, setInputValue] = useState(
          value !== undefined && value !== null ? formatFloatNumber(value) : ''
        );

        useEffect(() => {
          // Đồng bộ inputValue với value từ form state khi value thay đổi từ bên ngoài
          if (value !== undefined && value !== null && value !== parseFloat(inputValue?.replace(',', '.'))) {
            setInputValue(formatFloatNumber(value)); // Chỉ format nếu value thực sự thay đổi
            clearErrors(name);
          } else if (value === null || value === undefined) {
            setInputValue('');
          }
        }, [value, name, clearErrors]);

        const handleInputChange = (e) => {
          const newValue = e.target.value;
          // Giới hạn tối đa 100 số sau dấu phẩy
          const decimalPart = newValue.split('.')[1] || newValue.split(',')[1];
          if (decimalPart && decimalPart.length > 100) return; // Ngăn nhập nếu vượt quá 100 số

          setInputValue(newValue); // Cập nhật giá trị hiển thị ngay lập tức (giữ nguyên input của người dùng)

          const rawValue = newValue.replace(',', '.'); // Chuẩn hóa để parse
          if (rawValue === '' || rawValue === '.' || rawValue === ',') {
            onChange(null); // Cho phép xóa hoặc trạng thái trung gian
            clearErrors(name);
          } else {
            const parsedValue = parseFloat(rawValue);
            if (!isNaN(parsedValue)) {
              onChange(parsedValue); // Lưu giá trị số thực vào form state
              clearErrors(name);
            } else {
              setError(name, { type: 'validate', message: t("invalid_number") });
            }
          }
        };

        const handleInputBlur = () => {
          const rawValue = inputValue.replace(',', '.'); // Chuẩn hóa trước khi parse
          if (rawValue === '' || rawValue === '.' || rawValue === ',') {
            if (required) {
              setError(name, { type: 'required', message: `${string} ${t('must_required')}` });
              onChange(null);
              setInputValue('');
            } else {
              onChange(null);
              setInputValue('');
              clearErrors(name);
            }
          } else {
            const parsedValue = parseFloat(rawValue);
            if (!isNaN(parsedValue)) {
              const fixedValue = parsedValue.toFixed(2); // Lấy 2 số sau dấu phẩy
              const formattedValue = formatFloatNumber(parsedValue); // Format với dấu phẩy
              onChange(parseFloat(fixedValue)); // Cập nhật giá trị số thực vào form state
              setInputValue(formattedValue); // Hiển thị dạng "X,XX"
              handleOnchange(name, parseFloat(fixedValue)); // Gọi callback với giá trị số thực
              clearErrors(name);
            } else {
              setError(name, { type: 'validate', message: t("invalid_number") });
              setInputValue('');
            }
          }
          onBlur(); // Hoàn tất blur
        };

        return (
          <>
            <input
              value={inputValue}
              onChange={!readonly ? handleInputChange : undefined}
              onBlur={!readonly ? handleInputBlur : undefined}
              type="text"
              readOnly={readonly}
              disabled={readonly}
              placeholder={placeholder}
              required={!invisible && required}
              style={invisible ? { display: 'none' } : {}}
              className={`${!isForm ? '!bg-transparent focus-within:!border-none' : 'field'}`}
            />
            {error && <p className="text-[#de4747] text-sm mt-1">{error.message}</p>}
          </>
        );
      }}
    />
  );
};

export default FloatField;