import MessageContent from "./MessageContent"
import ListMessage from "./ListMessage"
import { useSearchParams } from "react-router-dom"

const Chatter = ({ messages, refetch }) => {
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const handleAfterSubmit = () => {
        refetch()
    }

    return (
        <div className="flex flex-col gap-[24px] mt-3">
            <div className="flex flex-col gap-[16px]">
                <MessageContent handleAfterSubmit={handleAfterSubmit} />
            </div>
            {id && <ListMessage messages={messages} handleAfterSubmit={handleAfterSubmit} />}
        </div>
    )
}
export default Chatter