import React, { useEffect, useMemo, useState } from 'react';
import Calendar from '../../widget/Calendar';
import useHandleField from '../model/main';
import useTabModel from '../model/tab';
import { decimalToTime, getArrayValues } from '../../../util/util';
import { FaRegCalendarAlt, FaTable } from 'react-icons/fa';
import useSelectStore from '../../../store/select';

const types = [
  {
    name: 'list',
    icon: <FaTable />,
  },
  {
    name: 'calendar',
    icon: <FaRegCalendarAlt />
  },
]

const TabsView = ({ id, viewData, onchangeData, resModel, context, handleOnchange, setOnchangeData }) => {
  const isDefault = viewData?.views?.form?.notebook_view_mode?.calendar?.is_default
  if (isDefault) {
    const calendarIndex = types.findIndex(item => item.name === 'calendar')
    const calendar = types.splice(calendarIndex, 1)
    types.unshift(calendar[0])
  }
  const [tabFocus, setTabFocus] = useState()
  const [typeView, setTypeView] = useState(types[0].name)
  const { listSubject } = useSelectStore()

  const tabsData = useMemo(() => {
    return useTabModel(viewData, onchangeData, resModel)
  }, [viewData, onchangeData, resModel]);

  useEffect(() => {
    if (!tabFocus && tabsData && tabsData.length > 0) {
      setTabFocus(tabsData?.findIndex(tab => tab?.name && tab?.string))
    }
  }, [tabsData])

  const getViewInTab = (tab) => {
    const { fieldList } = useHandleField({ fields: tab?.fields, viewData: viewData, resModel: resModel, onchangeData: onchangeData, handleOnchange: handleOnchange, setOnchangeData: setOnchangeData, tabIndex: tab?.index })
    return fieldList;
  }

  const renderTableData = () => {
    
    return (
      tabsData?.length > 0 && <div>
        <div style={{ scrollbarWidth: "none" }} className='w-full flex gap-4 border-b mb-3 overflow-x-auto'>
          {tabsData?.map((item, index) => {
            if (item?.name || item?.string) {
              return <button key={index} onClick={() => setTabFocus(index)} type='button'
                className={`py-3 border-b-2 whitespace-nowrap ${tabFocus === index ? '!border-primary font-medium text-[#121212]' : 'border-transparent text-[#525252] font-normal'}`}>{item?.string}</button>
            }
          })}
        </div>
        {tabsData?.map((tab, index) => {
          if (tab?.name || tab?.string) {
            return (
              <div className={`${tabFocus === index ? 'block' : 'hidden'} relative`} key={`tab-${index}`}>
                {getViewInTab({ ...tab, index, modelTab: viewData?.models?.[resModel]?.[tab?.name] })}
              </div>
            )
          }
        })}
      </div>
    )
  }

  const renderCalendarData = () => {
    const padTime = (time) => {
      if (time < 10) {
        return `0${time}`;
      }
      return time;
    };

    const formatEvents = getArrayValues(onchangeData).map(event => {
      const title = Array.from(listSubject)?.find(subject => subject?.value === event?.subject)?.label ?? 'Nhấn để xem chi tiết'
      return ({
        key: event?.key,
        title: title,
        itemId: event?.id,
        classroom_id: event?.classroom_id,
        start: new Date(`${event?.date}T${padTime(decimalToTime(event?.time_from))}:00`),
        end: new Date(`${event?.date}T${padTime(decimalToTime(event?.time_till))}:00`),
      })
    });

    return (
      <Calendar events={formatEvents} context={context} tabsData={tabsData} id={id} onchangeData={onchangeData} handleOnchange={handleOnchange} />
    )
  }

  const hasCalendar = viewData?.views?.form?.notebook_view_mode?.calendar?.invisible === false

  return (
    <div>
      {hasCalendar && <div className="flex gap-2 items-enter mb-4 justify-end">
        {types.map((item, index) => (
          <button
            key={index}
            onClick={() => setTypeView(item.name)}
            type='button'
            className={`p-2 duration-100 rounded-md border-2 !border-primary whitespace-nowrap ${typeView === item.name ? '!bg-primary font-medium text-[#fff]' : ' text-[#525252] hover:bg-primary/10 font-normal'}`}
          >
            {item.icon}
          </ button>
        ))}
      </div>}

      {typeView === 'calendar' ? renderCalendarData() : renderTableData()}
    </div>
  )
}

export default TabsView
