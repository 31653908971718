import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useUserSubmit from '../../hooks/useUserSubmit'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { SidebarContext } from '../../util/context/SidebarContext'
import Loading from '../../components/general/Loading'
import ImageWithBasePath from '../../components/general/ImageCustom'
import ButtonLoading from '../../components/general/ButtonLoading'
import useProfileStore from '../../store/profile'

const ALLOWED_TYPES = [
  'image/jpeg', 'image/png', 'image/svg+xml', 'image/jpg',
];

const Profile = () => {
  const { t } = useTranslation()
  const { isLoading } = useContext(SidebarContext)
  const { dataUser } = useProfileStore()
  const {
    onUpdateProfile,
    handleSubmit,
    register,
    getValues,
    imageSrc,
    setImageSrc,
    onImageUpload,
    imageUpload,
    setImageUpload,
    colorPalette,
    reset,
    isUpdatingProfile
  } = useUserSubmit()
  const [uploadError, setUploadError] = useState(null)

  const handleCancel = async (e) => {
    e.preventDefault()
    setImageSrc(getValues('picture'))
    setImageUpload(false)
    reset(dataUser)
  }

  const handleUploadImage = (e) => {
    setUploadError(null)
    const file = e.target.files[0]
    const maxSize = 10 * 1024 * 1024 // 10MB
    if (file) {
      if (!ALLOWED_TYPES.includes(file.type)) {
        setUploadError(t("avatar_profile_type"))
        return;
      }

      if (file.size > maxSize) {
        setUploadError(t("avatar_profile_maxsize"))
        return
      }
      const reader = new FileReader()
      reader.onloadend = async () => {
        setImageSrc(reader.result)
      }
      reader.readAsDataURL(file)
    }
    setImageUpload(file)
  }

  const handleUpdateProfile = async (data) => {
    try {
      if (imageUpload) {
        const dataImageUpload = await onImageUpload(imageUpload)
        if (dataImageUpload) {
          await onUpdateProfile(data)
          return
        }
      }

      await onUpdateProfile(data)
    } catch (error) {
      console.log(error);
    }
  }

  if (isLoading) return <Loading />

  return <div className='page-wrapper'>
    <div className='page-header'>
      <div className='page-title mt-4'>
        <h4>{t('profile_title')}</h4>
        <h6>{t('profile_subtitle')}</h6>
      </div>
    </div>
    <div className='card'>
      <div className='card-body p-4'>
        <div className='profile-set'>
          <div
            style={{
              background: `linear-gradient(90deg,${colorPalette} 0%, #FFFFFF 100%)`,
            }}
            className='profile-head'
          ></div>
          <div className='profile-top'>
            <div className='profile-content'>
              <div className='profile-contentimg'>
                <img
                  className='object-cover'
                  src={imageSrc || 'assets/img/customer/customer5.jpg'}
                  style={{ aspectRatio: '1' }}
                  alt='img'
                  id='blah'
                />
                <div className='profileupload'>
                  <input type='file' id='imgInp' onChange={(e) => handleUploadImage(e)} />
                  <Link to='#'>
                    <ImageWithBasePath src='assets/img/icons/edit-set.svg' alt='img' />
                  </Link>
                </div>
              </div>
              <div className='profile-contentname'>
                <h2>{getValues('name') || 'Username'}</h2>
                <h4>{t('user_profile_note')}</h4>
                {uploadError && (
                  <div className='text-[#de4747]'>{uploadError}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-sm-12'>
            <div className='input-blocks'>
              <label className='form-label'>{t('name')}</label>
              <input type='text' placeholder={t('placeholder_username')} {...register('name')} />
            </div>
          </div>
          <div className='col-lg-6 col-sm-12'>
            <div className='input-blocks'>
              <label>Email</label>
              <input type='text' placeholder={t('placeholder_email')} {...register('email')} />
            </div>
          </div>
          <form onSubmit={handleSubmit(handleUpdateProfile)}>
            <div className='col-12'>
              <ButtonLoading loading={isUpdatingProfile} className={'button-primary me-2 '} content={'submit'} />
              {
                !isUpdatingProfile &&
                <button type='button' onClick={(e) => handleCancel(e)} className='button-secondary'>
                  {t('cancel')}
                </button>
              }
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}

export default Profile
