import React, { useEffect, useMemo, useState } from 'react'
import { Triangle } from 'react-feather'
import FieldView from '../../field/field-view'
import ModelService from '../../../core/services/model-service/model-service'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useTable from '../model/table'
import useHeaderStore from '../../../store/header'
import useTableStore from '../../../store/table'
import { TableBodyRow } from './table-body'
import useSearchStore from '../../../store/search'
import Pagination from '../../general/Pagination'

const TableGroup = (props) => {
    const { rowRecord, columns, indexRow, rootField, isForm, resModel, viewData, visibleOptionalFields, specification, domain, columnWidths, type, level, dataResponseLength, showCheckBox, funcChoose, funcRemoveLine, funcAddLine, typeCo, context, setDataSource } = props
    const [pageGroup, setPageGroup] = useState(0)
    const [isShowGroup, setIsShowGroup] = useState(false)
    const { groupByDomain, selectedTags } = useSearchStore()
    const vid = sessionStorage.getItem("vid")
    const { rootContext } = useHeaderStore()
    const { fields, footerGroupTable } = useTableStore()
    const [colEmptyGroup, setColEmptyGroup] = useState({ fromStart: 1, fromEnd: 1 });

    const handleExpandChildGroup = () => {
        setIsShowGroup(!isShowGroup)
    }

    const processedData = useMemo(() => {
        const calculateColSpanEmpty = () => {
            const startIndex = columns.findIndex((col) => (col.field.type === "monetary" && typeof rowRecord[col.key] === "number") || col.field.aggregator === "sum");
            const endIndex = columns.findLastIndex((col) => (col.field.type === "monetary" && typeof rowRecord[col.key] === "number") || col.field.aggregator !== "sum");

            const fromStart = startIndex === -1 ? columns.length : startIndex;
            const fromEnd = endIndex === -1 ? columns.length : columns.length - 1 - endIndex;
            setColEmptyGroup({ fromStart: fromStart + 1, fromEnd: fromEnd + 1 });
            return { fromStart: fromStart + 1, fromEnd: fromEnd + 1 };
        };
        return calculateColSpanEmpty();
    }, [columns, rowRecord]);

    const shouldFetchData = useMemo(() => {
        return !!isShowGroup;
    }, [isShowGroup]);

    const { data: dataResponse, isFetched: isQueryFetched, isPlaceholderData } = useQuery({
        queryKey: [`data-${resModel}-${vid}-${level}-row${indexRow}`, specification, domain, pageGroup],
        queryFn: () => ModelService.getAll({ resModel: resModel, specification: specification, domain: domain, context: context, offset: pageGroup * 10, fields: groupByDomain?.fields, groupby: [groupByDomain?.contexts[level]?.group_by] }),
        enabled: shouldFetchData && !!processedData,
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
    })

    const { dataSource, typeTable: typeTableGroup } = useTable({
        model: resModel,
        fields: fields?.[`${vid}_${resModel}`],
        data: dataResponse?.records ?? dataResponse?.groups,
        dataModel: viewData?.models?.[resModel],
        context: rootContext,
        typeTable: dataResponse?.groups ? "group" : "list"
    })

    const leftPadding = level > 1 ? (level * 8) + "px" : "0px"

    useEffect(() => {
        if (isShowGroup && selectedTags?.length > 0) {
            setIsShowGroup(false);
        }
    }, [selectedTags])

    return (
        <>
            <tr draggable className='border-b border-[#e8e8e8] cursor-pointer hover:bg-gray-50 relative z-10' onClick={handleExpandChildGroup}>
                <td colSpan={colEmptyGroup.fromStart ?? 1} style={{ display: "table-cell" }} className='relative z-20 w-max whitespace-nowrap border-b border-gray-200 p-3 text-sm font-normal text-gray-900 h-[53px]'>
                    <div style={{ paddingLeft: leftPadding }} className={`flex items-center justify-between gap-2 font-medium ml-2 z-21`}>
                        <div className='flex items-center gap-2'>
                            <Triangle className={`w-2 h-2  text-button_primary ${isShowGroup && isQueryFetched ? "rotate-180" : "rotate-90"}`} />
                            {
                                Object.keys(rowRecord)
                                    .find(key => key.endsWith('_count'))
                                    ?.split("_count")
                                    .map(item => {
                                        if (item) {
                                            const nameGroup = (Array.isArray(rowRecord[item]) ? rowRecord[item][1] : rowRecord[item]) + " (" + (rowRecord[`${item}_count`]) + ")"
                                            return <span key={nameGroup} className='capitalize'>{nameGroup.replace("false", "nonce")}</span>
                                        }
                                    })
                            }
                        </div>
                        <div onClick={(e) => {
                            e.stopPropagation();
                        }}>
                            {isShowGroup && dataResponse?.length >= 10 && !dataResponse?.groups && <Pagination
                                className="pagination-bar !bg-transparent absolute z-[100] right-0 top-1/2 -translate-y-1/2"
                                currentPage={pageGroup}
                                totalCount={dataResponse?.length ?? 0}
                                pageSize={10}
                                onPageChange={page => setPageGroup(page)}
                                displayPageNumberDots={false}
                            />}
                        </div>
                    </div>
                </td>
                {columns?.map(col => {
                    // if ((Object.keys(rowRecord).includes(col.key) && Object.keys(rowRecord).includes(`${col.key}_count`))) {
                    //     return <td
                    //         key={col.key}
                    //         className='w-max whitespace-nowrap border-b border-gray-200 p-3 text-sm font-normal text-gray-900'></td>
                    // }

                    if ((col.field.type === "monetary" && typeof rowRecord[col.key] === "number") || col.field.aggregator === "sum") {
                        return (
                            <td
                                key={col.key}
                                className='w-max whitespace-nowrap border-b border-gray-200 p-3 text-sm font-normal text-gray-900'
                            >
                                <FieldView
                                    {...col.field}
                                    rootField={rootField}
                                    index={indexRow}
                                    defaultValue={rowRecord[col.key]}
                                    onchangeData={ModelService.toDataJS(rowRecord, viewData, resModel)}
                                    string=''
                                    isForm={isForm}
                                />
                            </td>
                        )
                    }

                })}
                {visibleOptionalFields && visibleOptionalFields.length > 0 && <td colSpan={colEmptyGroup.fromEnd ?? 1} className='relative p-2 w-[35px]'></td>}
            </tr>
            {(isShowGroup && (isQueryFetched || isPlaceholderData)) &&
                <TableBodyRow
                    {...props}
                    viewData={viewData}
                    resModel={resModel}
                    model={resModel}
                    columns={columns}
                    isForm={isForm}
                    dataSource={dataSource}
                    rootField={rootField}
                    dataResponseLength={dataResponse?.length}
                    visibleOptionalFields={visibleOptionalFields}
                    typeTable={typeTableGroup}
                    specification={specification}
                    columnWidths={columnWidths}
                    type={type}
                    level={level}
                    showCheckBox={showCheckBox}
                    funcChoose={funcChoose}
                    funcRemoveLine={funcRemoveLine}
                    funcAddLine={funcAddLine}
                    typeCo={typeCo}
                    context={context}
                    setDataSource={setDataSource}
                />
            }

            {/* {level === 1 && dataResponseLength - 1 === indexRow &&
                <tr>
                    <td></td>
                    {columns?.map((col, index) => {
                        if (footerGroupTable[col.key] || footerGroupTable[col.key] >= 0) {

                            return (
                                <td
                                    key={col.key}
                                    className='w-max whitespace-nowrap border-b border-gray-200 p-3 text-sm font-normal text-gray-900'
                                    style={{ width: columnWidths[index] }}
                                >
                                    <FieldView
                                        {...col.field}
                                        rootField={rootField}
                                        index={indexRow}
                                        string=''
                                        isForm={false}
                                        onchangeData={ModelService.toDataJS(rowRecord, viewData, resModel)}
                                        defaultValue={footerGroupTable[col.key]}
                                    /> 
                                    {formatCurrency(parseFloat(footerGroupTable[col.key]), "VND")}
                                </td>
                            )
                        }
                        return <td></td>
                    })}
                </tr>
            } */}
        </>
    )
}

export default TableGroup
