import ImageWithBasePath from '../../general/ImageCustom';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ActionService from '../../../core/services/actions';
import { useTranslation } from 'react-i18next';
import LayerLoading from '../../general/LayerLoading';
import { downloadPDF, getSubdomain } from '../../../util/util';
import ModalDynamic from '../../modal/ModalDynamic';
import { useClickOutside } from '../../../hooks/useClickOutside';
import useActionHandler from '../../../hooks/useActionButton';
import useHeaderStore from '../../../store/header';
import ModalConfirmArchive from '../../modal/ModalConfirmArchive';
import ModalConfirmDelete from '../../modal/ModalConfirmDelete';
import { CustomToastMessage } from '../../../util/toast/customToastMsg';

const listRootActions = {
  duplicate: {
    icon: 'assets/img/icons/copy.svg',
  },
  archive: {
    icon: 'assets/img/icons/archive.svg',
  },
  unarchive: {
    icon: 'assets/img/icons/unarchive.svg',
  },
  // read: {
  //   icon: "assets/img/icons/export.svg",
  //   default: true
  // },
  delete: {
    icon: "assets/img/icons/delete3.svg",
  },
  // edit: {
  //   icon: "assets/img/icons/edit-5.svg"
  // },
  // import: {
  //   icon: "assets/img/icons/import.svg"
  // },
  // create: {
  //   icon: "assets/img/icons/create.svg"
  // }
}

const ButtonIcon = ({ onClick, iconSrc, children, className }) => {
  return (
    <button type='button' onClick={onClick} className={`size-8 p-2 border rounded-lg bg-white border-[#DEE7DD] cursor-pointer select-none ${className}`}>
      {iconSrc && <ImageWithBasePath src={iconSrc} />}
      {children}
    </button>
  );
};

const ButtonAction = ({ onClick, iconSrc, children, className, isLoading }) => {
  return (
    <button
      type='button'
      onClick={onClick}
      disabled={isLoading}
      className={`flex w-full rounded-lg px-4 py-2 hover:bg-[#eeecec] font-normal text-sm text-[#121212] tracking-[2%] text-start ${isLoading ? 'opacity-60 cursor-wait' : ''} ${className}`}
    >
      {iconSrc && <ImageWithBasePath src={iconSrc} />}
      {children}
    </button>
  );
};

const LeftToolbar = (props) => {
  const { isShow, actionsPrint, title, subTitle, actions, funcReset, model, handleOnchange, refetch, formValues, formSpecification, viewData, setErrorMessage } = props;
  const hostname = window.location.hostname;
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams();
  const vid = parseInt(searchParams.get("vid"))
  const detailId = parseInt(searchParams.get('id'))
  const { rootContext } = useHeaderStore()
  const [isOnActions, setOnActions] = useState(false);
  const [isOpenSaveArchive, setIsOpenArchive] = useState(false)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)
  const [loading, setLoading] = useState(false);
  const actionsRef = useClickOutside(() => setOnActions(false))

  const url_icon = JSON.parse(sessionStorage.getItem("url_icon")) ?? {};
  const parent_icon = JSON.parse(sessionStorage.getItem("parent_icon")) ?? {};
  const existIcon = url_icon?.url_icon ? url_icon : parent_icon;

  const { modalAction, handleButtonAction, handleCloseActions } = useActionHandler({
    context: formValues,
    resModel: model,
    dataForm: { id: detailId },
    refetch,
  });

  const updateIdInSearchParams = (newId) => {
    searchParams.set('id', newId);
    setSearchParams(searchParams);
  };

  const renderSubmenu = () => {
    const handleClick = async ({ id, name = 'DefaultName' }) => {
      if (!id) return CustomToastMessage.error('No such report')
      setLoading(true)
      try {
        const res = await ActionService.getPrintReportName({ ids: id })
        const db = getSubdomain(hostname)
        const res1 = await ActionService.print({ ids: detailId, report: res[0]?.report_name, db: db })
        downloadPDF(res1, name)
      } catch (err) {
        console.log('error: ', err)
        CustomToastMessage.error(err?.message || 'Lỗi hệ thống')
      } finally {
        setLoading(false)
      }
    }

    return (
      <div className={`left-[calc(100%+10px)] hidden group-hover:block top-0 absolute shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-xl p-2 border border-[#E5E5E5] z-[100]`}>
        <div className='absolute top-0 -left-5 w-10 h-full bg-transparent'></div>
        {actionsPrint && actionsPrint.length > 0 ? actionsPrint.map((action, index) => {
          return (
            <ButtonAction key={'button-action-submenu-' + index} onClick={() => handleClick({ id: action?.id, name: action?.name })}>
              {action?.name}
            </ButtonAction>
          )
        }) : <div className='text-center text-sm text-[#121212]'>Không có dữ liệu</div>}
      </div>
    )
  }

  const renderMoreAction = () => {
    return (
      <div >
        {actions && actions.length > 0 && <div className='border-t pt-2'>
          {actions.map((action, index) => {
            return (
              <ButtonAction key={'button-action-more-' + index} onClick={() => handleButtonAction(action?.id)} >
                {action.name}
              </ButtonAction>
            )
          })}
        </div>
        }
        {modalAction?.status && <ModalDynamic {...modalAction} handleCloseActions={handleCloseActions} handleOnchange={handleOnchange} formSpecification={formSpecification} isInForm={true} />}
      </div>
    )
  }

  const renderActions = () => {
    const isArchive = formValues?.active
    const listDefaultActionsKey = Object.keys(listRootActions)
    const rootAction = viewData?.views?.form?.toolbar?.root

    const handleDuplicate = async () => {
      setLoading(true)
      try {
        const res = await ActionService.duplicateModel({ model: model, ids: [detailId], context: rootContext })
        if (res) {
          CustomToastMessage.success(t('successs_duplicate'))
          updateIdInSearchParams(res[0]?.[0])
        }
      } catch (err) {
        console.log('error: ', err)
        CustomToastMessage.error(err?.message || 'Lỗi hệ thống')
      } finally {
        setLoading(false)
      }
    }

    const handleArchive = async () => {
      setLoading(true)
      try {
        const res = await ActionService.callButton({ method: isArchive ? "action_archive" : "action_unarchive", resModel: model, ids: [detailId], context: rootContext })
        if (res) {
          CustomToastMessage.success(t(isArchive ? 'save_archive_success' : "save_unarchive_success"))
          refetch()
        }
      } catch (err) {
        CustomToastMessage.error(t(isArchive ? 'save_archive_failure' : "save_unarchive_failure"))
      } finally {
        setLoading(false)
        setIsOpenArchive(false)
      }
    }

    const handleDelete = async () => {
      try {
        await ActionService.removeRows({ model: model, ids: [detailId], context: rootContext })

        CustomToastMessage.success(t('success_delete'))
        if(vid){
          navigate(`/list?vid=${vid}&model=${model}`)
        } else {
          navigate(-1)
        }
      } catch (error) {
        setErrorMessage(error)
      } finally {
        setIsConfirmDelete(false)
      }
    }

    const handleActions = (actionName) => {
      if (actionName === "delete") {
        setIsConfirmDelete(true)
      } else if (actionName === "duplicate") {
        handleDuplicate()
      } else if (actionName === "archive" || actionName === "unarchive") {
        setIsOpenArchive(true)
      }
    }

    return (
      <>
        <ModalConfirmArchive
          title={t(isArchive ? "confirm_save" : "confirm_cancellation")}
          content={t(isArchive ? "save_archive_record_title" : "save_unarchive_record_title")}
          isArchive={isArchive}
          handleClose={() => setIsOpenArchive(false)}
          handleArchive={handleArchive}
          isShowConfirmArchive={isOpenSaveArchive}
        />

        <ModalConfirmDelete
          title={t("confirm_delete_data")}
          content={t("delete_record_confirm_title")}
          isShowConfirmDelete={isConfirmDelete}
          handleClose={() => setIsConfirmDelete(false)}
          handleDelete={handleDelete}
        />
        <div
          className='group-actions flex gap-2 h-full items-end'>
          <div ref={actionsRef} onClick={() => setOnActions(!isOnActions)} className='size-8 border rounded-lg bg-white border-[#DEE7DD] cursor-pointer select-none relative flex justify-center items-center'>
            <ImageWithBasePath src="assets/img/setting.svg" width={16} height={16} className="w-4 h-4" />

            <div onClick={e => e.stopPropagation()} className={`${!isOnActions ? "hidden" : 'z-[3] absolute top-[calc(100%+10px)] start-0'} shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-xl p-2 border border-[#E5E5E5] `}>
              {/* <ButtonAction onClick={() => setIsOpenArchive(true)}>
                <div className='flex w-full gap-3'>
                  <ImageWithBasePath src={isArchive ? 'assets/img/icons/archive.svg' : 'assets/img/icons/unarchive.svg'} />
                  <span className='font-normal text-sm text-[#121212] tracking-[2%]'>{t(formValues?.active ? "archive" : "unarchive")}</span>
                </div>
              </ButtonAction>

              <ButtonAction onClick={handleDuplicate}>
                <div className='flex w-full gap-3'>
                  <ImageWithBasePath src='assets/img/icons/copy.svg' />
                  <span className='font-normal text-sm text-[#121212] tracking-[2%]'>{t("duplicate")}</span>
                </div>
              </ButtonAction> */}
              {
                listDefaultActionsKey?.length > 0 && listDefaultActionsKey?.map((actionKey, index) => {

                  if (rootAction[actionKey] && isArchive && actionKey === "unarchive") return
                  if (rootAction[actionKey] && !isArchive && actionKey === "archive") return

                  return rootAction[actionKey] && (
                    <ButtonAction onClick={() => handleActions(actionKey)} key={`action-key-${index}`} className='flex w-full gap-2 px-[24px] py-[10px] text-left hover:bg-[#f6f4f4] items-center' type='button'>
                      <ImageWithBasePath src={listRootActions[actionKey]?.icon} />
                      <span className='font-normal text-sm'>{t(actionKey)}</span>
                    </ButtonAction>
                  )
                })
              }
              {actionsPrint && actionsPrint?.length > 0 && <ButtonAction className={'group'}>
                <div className='flex w-full gap-3'>
                  <ImageWithBasePath src='assets/img/icons/printer-2.svg' />
                  <span className='font-normal text-sm text-[#121212] tracking-[2%]'>{t("print")}</span>
                </div>
                <ImageWithBasePath src='assets/img/icons/arrow-right.svg' />
                {renderSubmenu()}
              </ButtonAction>}
              {renderMoreAction()}
            </div>
          </div>

          <ButtonIcon onClick={funcReset} iconSrc="assets/img/icons/reset.svg" />
        </div >
      </>
    )
  };

  const renderFormAction = () => {

    return (
      <div className='relative'>
        {/* <button
          onClick={() => setOnActions(!isOnActions)}
          className='relative w-6 h-6 flex items-center justify-center'
        >
          <ImageWithBasePath src='assets/img/setting.svg' className='h-4 w-4' alt='setting' width={16} height={16} />
        </button> */}

        {/* <div className={`${!isOnActions ? "hidden" : 'z-[100] absolute top-[calc(100%+10px)] start-0 '} shadow-[0_12px_16px_-1px_rgba(0,0,0,0.04),0_1px_5px_0_rgba(0,0,0,0.04)] min-w-fit w-[320px] bg-white rounded-2xl p-2 border border-[#E5E5E5] `}>
          <ButtonAction onClick={handleClick} className={'group'}>
            <div className='flex w-full gap-3'>
              <ImageWithBasePath src='assets/img/icons/printer-2.svg' />
              <span className='font-normal text-sm text-[#121212] tracking-[2%]'>Import dữ liệu</span>
            </div>
          </ButtonAction>
        </div> */}
      </div>
    )
  }

  return (
    <>
      <div className='add-item flex items-center gap-[8px] h-full order-1 flex-1'>
        <div className='page-title flex items-center h-full'>
          <div className="icon me-2 md:me-4 p-2 border border-[#E2E4E9] rounded-full flex justify-center items-center bg-primary w-12 h-12">
            <img
              src={existIcon?.url_icon && existIcon?.url_icon?.startsWith("http") ? existIcon.url_icon : process.env.REACT_APP_DOMAIN + existIcon.url_icon}
              width={20}
              height={20}
              alt="img"
            />
          </div>
          <div className={`flex ${isShow ? "gap-6 items-end" : "gap-2 items-center"}`}>
            <div className="group-title">
              <div className='title text-base md:text-xl tracking-wide text-dark font-semibold whitespace-nowrap'>{title?.toUpperCase()}</div>
              <div className='sub-title text-xl tracking-wide font-normal text-primary whitespace-nowrap'>{subTitle}</div>
            </div>
            {isShow ? renderActions() : renderFormAction()}
          </div>
        </div>
      </div>

      <LayerLoading loading={loading} />
    </>
  );
};

export default LeftToolbar;