import { matchDomains } from "../../../core/domain/domain";
import FieldView from "../../field/field-view";
import GroupView from "../view/group-view";
import SettingView from "../view/setting-view";
import TreeViewForm from "../view/tree-view-form";

const useHandleField = ({ fields, viewData, resModel, onchangeData = {}, handleOnchange, setOnchangeData, tabIndex = -1, help, specification, contextAction, id, state, refetch }) => {
    const fieldList = (fields ??= [])?.map((field, index) => {
        if (field?.type_co === "field" || field?.type_co === "b") {
            const fieldDataRoot = viewData?.models?.[resModel]?.[field?.name] || {};
            const mergedField = { ...fieldDataRoot, onchangeData: onchangeData, setOnchangeData: setOnchangeData, model: resModel, ...field, help: help };
            return <FieldView handleOnchange={handleOnchange} key={index} {...mergedField} isForm={true} tabIndex={tabIndex} specification={specification} contextAction={contextAction} id={id} state={state} refetch={refetch} />
        }
        else if (field?.type_co === "group" || field?.type_co === "div" || field?.type_co === "span") {
            const invisible = matchDomains(onchangeData, field?.invisible)
            if (invisible) return
            const mergeProps = { viewData: viewData, resModel: resModel, onchangeData: onchangeData, setOnchangeData: setOnchangeData, ...field }
            return (<GroupView handleOnchange={handleOnchange} key={index} {...mergeProps} />)
        }
        else if (field?.type_co === "setting") {
            const mergeProps = { viewData: viewData, resModel: resModel, onchangeData: onchangeData, setOnchangeData: setOnchangeData, ...field }
            return (<SettingView handleOnchange={handleOnchange} key={index} {...mergeProps} />)
        }
        else if (field?.type_co === "list" || field?.type_co === "tree") {
            const invisible = matchDomains(onchangeData, field?.invisible)
            if (invisible) return
            const fieldDataRoot = viewData?.models?.[resModel]?.[field?.name] || {};
            const mergedField = { ...fieldDataRoot, onchangeData: onchangeData, ...field };

            return <div className="col-span-2">
                <div className="w-full overflow-x-auto overflow-y-hidden mb-4">
                    {tabIndex < 0 ? <div className='text-sm font-medium text-dark mb-2'>{mergedField?.string}</div> : null}
                    <TreeViewForm handleOnchange={handleOnchange} onchangeData={onchangeData} tab={{ ...mergedField, index: tabIndex, modelTab: viewData?.models?.[resModel]?.[mergedField?.name] }} viewData={viewData} resModel={resModel} key={index} setOnchangeData={setOnchangeData} />
                </div>
            </div>
        }
        else if (field?.type_co === "h2") {
            return <div className="col-span-2 mb-3 mt-4 border-t pt-4 border-t-[#E1E4EA]">
                <h2 className="text-2xl font-semibold">{field?.heading}</h2>
            </div>
        } else if (field?.type_co === "p") {
            return <div className="col-span-2">
                <h2 className="text-base font-normal">{field?.help}</h2>
            </div>
        } else {
            return <></>
        }
    });

    return {
        fieldList,
    }
};

export default useHandleField