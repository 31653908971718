import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const CharField = ({
  name,
  value: propValue,
  readonly,
  placeholder,
  required,
  invisible,
  methods,
  isForm,
  defaultValue,
  handleOnchange,
  string,
  icon_cmp
}) => {
  const { t } = useTranslation();

  if (!isForm) {
    return <span>{propValue || defaultValue}</span>;
  }

  return (
    <Controller
      name={name}
      control={methods.control}
      rules={{
        required:
          required && !invisible
            ? { value: true, message: `${string} ${t("must_required")}` }
            : false,
      }}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const { setError, clearErrors } = methods;

        useEffect(() => {
          if (value) {
            clearErrors(name);
          }
        }, [value, clearErrors, name]);

        return (
          <>
            <textarea
              value={value} // Sử dụng value của react-hook-form thay vì propValue
              onBlur={(e) => {
                if (readonly) return;
                const newValue = e.target.value || ""; // Đảm bảo giá trị luôn là chuỗi
                onChange(newValue); // Cập nhật giá trị vào react-hook-form
                handleOnchange(name, newValue);

                if (e.target.value) {
                  clearErrors(name);
                } else if (required && !invisible) {
                  setError(name, {
                    type: "required",
                    message: `${string} ${t("must_required")}`,
                  });
                }
              }}
              onChange={(e) => {
                if (!readonly) {
                  onChange(e.target.value);

                  if (e.target.value) {
                    clearErrors(name);
                  } else if (required && !invisible) {
                    setError(name, {
                      type: "required",
                      message: `${string} ${t("must_required")}`,
                    });
                  }
                }
              }}
              type="text"
              readOnly={readonly}
              disabled={readonly}
              placeholder={placeholder}
              required={!invisible && required}
              style={invisible ? { display: "none" } : {}}
              className={`${!isForm || icon_cmp ? "textarea-field h-fit w-full cursor-pointer rounded-lg !bg-transparent focus-within:!border-none" : "field"}`}
            />
            {error && <p className="text-[#de4747] text-sm mt-1">{error.message}</p>}
          </>
        );
      }}
    />
  );
};

export default CharField;
