import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { copyTextToClipboard } from "../../util/util";
import { FaRegCopy, FaCheck } from 'react-icons/fa';

const CopyLinkButtonField = ({
    name,
    value: propValue,
    readonly,
    placeholder,
    required,
    invisible,
    methods,
    isForm,
    defaultValue,
    handleOnchange,
    string,
    icon_cmp
}) => {
    const { t } = useTranslation();

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyToClipboard = async (value) => {
        await copyTextToClipboard(value);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Revert icon after 2 seconds
    };

    if (!isForm) {
        return <span>{propValue || defaultValue}</span>;
    }

    return (
        <Controller
            name={name}
            control={methods.control}
            rules={{
                required:
                    required && !invisible
                        ? { value: true, message: `${string} ${t("must_required")}` }
                        : false,
            }}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                const { setError, clearErrors } = methods;
                useEffect(() => {
                    if (value) {
                        clearErrors(name);
                    }
                }, [value])
                return (
                    <div className="relative">
                        <div className="flex relative field">
                            <input
                                value={propValue || ""}
                                onBlur={(e) => {
                                    if (readonly) return;
                                    onBlur();
                                    handleOnchange(name, e.target.value);

                                    if (e.target.value) {
                                        clearErrors(name);
                                    } else if (required && !invisible) {
                                        setError(name, {
                                            type: "required",
                                            message: `${string} ${t("must_required")}`,
                                        });
                                    }
                                }}
                                onChange={(e) => {
                                    if (!readonly) {
                                        onChange(e.target.value);

                                        if (e.target.value) {
                                            clearErrors(name);
                                        } else if (required && !invisible) {
                                            setError(name, {
                                                type: "required",
                                                message: `${string} ${t("must_required")}`,
                                            });
                                        }
                                    }
                                }}
                                type="text"
                                readOnly={readonly}
                                disabled={readonly}
                                placeholder={placeholder}
                                required={!invisible && required}
                                style={invisible ? { display: "none" } : {}}
                                className={`${!isForm || (icon_cmp) ? "textarea-field truncate h-fit w-full cursor-pointer rounded-lg !bg-transparent focus-within:!border-none" : " flex-1 truncate"}`}
                            />
                            <button className="" type="button" onClick={() => handleCopyToClipboard(propValue)}>
                                {isCopied ? <FaCheck /> : <FaRegCopy />}
                            </button>
                        </div>
                        {error && <p className="text-[#de4747] text-sm mt-1">{error.message}</p>}
                    </div>
                )
            }}
        />
    );
};

export default CopyLinkButtonField;
