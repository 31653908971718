import React from 'react'
import BinaryField from './BinaryField'
import DateField from './DateField'
import FileUploadDownload from './FileUploadDownload'
import Many2ManyCheckbox from './Many2ManyCheckbox'
import Many2ManyTag from './Many2ManyTag'
import Many2OneButton from './Many2OneButton'
import Many2OneField from './Many2OneField'
import TextAreaField from './TextAreaField'
import SelectDropdown from './SelectDropdown'
import CharField from '../instance-fields/CharField'
import FloatField from '../instance-fields/FloatField'
import IntegerField from '../instance-fields/IntegerField'
import ButtonBadge from './ButtonBadge'
import RadioButtonGroup from './RadioButtonGroup'
import CheckboxGroup from './CheckboxGroup'
import FloatTime from './FloatTime'
import HtmlField from './HtmlField'
import MontaryField from '../instance-fields/Montary'
import ImageField from './ImageField'
import One2ManyList from './One2ManyList'
import CopyClipboardButton from './CopyClipboardButton'
import Fee from './Fee'
import Priority from './Priority'
import Website from './Website'
import StatusDropdown from './StatusDropdown'
import BinaryFieldDownload from './BinaryFieldDownload'
import FileUpload from './FileUpload'
import One2ManyTable from './One2ManyTable'
import ColorPicker from './PickColor'
import Status from './Status'
import RemainingDays from './RemainingDays'
import CopyLinkButtonField from './CopyLinkButtonField'
import InfoWidget from './Info'

const RenderWidget = (props) => {
  const {
    name,
    setValue,
    relation,
    modelsData,
    type,
    invisible,
    widget,
    value,
    string,
    methods,
    placeholder,
    required,
    readonly,
    defaultValue,
    onchangeData,
    specification,
    resModal,
    contextAction,
    id,
    isForm,
    state,
    fetchDetailData,
    refetch
  } = props

  switch (type) {

    case 'boolean':
      if (widget === "website_redirect_button") {
        return <Website {...props} />
      }
      return (
        <CheckboxGroup {...props} />
      )
    case 'float':
      if (widget === 'float_time') {
        return <FloatTime {...props} />
      }
      return (
        <FloatField {...props} />
      )
    case 'char':
      if (widget === "CopyClipboardButton") {
        return <CopyClipboardButton {...props} />
      }
      if(widget === "CopyClipboardChar"){
        return <CopyLinkButtonField {...props}/>
      }
      if (widget === "image") {
        return (<ImageField value={value} type="base64" />)
      }
      if (widget === "image_url") {
        return (<ImageField value={value} type="url" />)
      }
      if (widget === "html") {
        return (
          <HtmlField {...props} type="text" />
        )
      }
      return (
        <CharField {...props} />
      )
    case 'integer':
      if (widget === "handle") {
        return <></>
      }

      if (widget === "color_picker") {
        return <ColorPicker {...props} />
      }
      return (
        <IntegerField {...props} />
      )
    case 'monetary':
      return (
        <MontaryField {...props} />
      )
    case 'html':
      return (
        <HtmlField {...props} type="text" />
      )
    case 'date':
      return (
        <DateField
          {...props}
        />
      )
    case 'datetime':
      if (widget === "remaining_days") {
        return <RemainingDays {...props} />
      }
      return (
        <DateField
          {...props}
        />
      )
    case 'many2one':
      if(widget === "many2one_avatar_user") {
        return <InfoWidget {...props} />
      }
      return (
        <Many2OneField
          {...props}
          defaultValue={defaultValue}
        />
      )
    case 'one2many':
      if (widget === "one2many_list") {
        return (
          <One2ManyList {...props} />
        )
      }
      if (widget === "section_one2many") {
        return (
          <One2ManyTable {...props} />
        )
      }
      if (widget === "many2many_tags") {
        return (
          <Many2ManyTag {...props} />
        )
      }
      if (widget === "helpdesk_sla_many2many_tags") {
        return (
          <Status {...props} />
        )
      }
      return <div />
    case 'many2one_checkbox':
      return (
        <Many2ManyCheckbox
          relation={relation}
          name={name}
          methods={methods}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
        />
      )
    case 'many2one_button':
      return (
        <Many2OneButton
          {...props}
        />
      )
    case 'many2many':
      if (widget === 'many2many_tags' || widget === "many2many_tags_email" || widget === "many2many_avatar_user") {
        return (
          <Many2ManyTag
            {...props}
          />
        )
      }
      return <div />
    case 'file_upload_download':
      return (
        <FileUploadDownload
          {...props}
        />
      )
    case 'selection':
      if (widget === "state_selection") {
        return (
          <StatusDropdown
            {...props}
          />
        )
      }
      if (widget === 'radio') {
        return (
          <RadioButtonGroup
            {...props}
          />
        )
      }
      if (widget === "badge") {
        return <ButtonBadge {...props} />
      }

      if (widget === "priority") {
        return <Priority {...props} methods={methods} />
      }

      return (
        <SelectDropdown
          {...props}
          name={name}
          methods={methods}
          modelsData={modelsData}
          onchangeData={onchangeData}
          readonly={readonly}
          placeholder={placeholder}
          required={required}
          invisible={invisible}
          defaultValue={defaultValue}
        />
      )
    case 'binary':
      if (widget === "image" || widget === "image_url") {
        return <BinaryField
          {...props}
        />
      }
      if (widget === 'payment') {
        return <></>
      }
      if (widget === "account-tax-totals-field") {
        return <Fee {...props} />
      }
      if (widget === "binary") {
        return <BinaryFieldDownload {...props} />
      }
      return (
        <FileUpload
          {...props}
        />
      )
    case 'text':
      return (
        <TextAreaField
          {...props}
        />
      )
    case 'barcode':
      return (
        <Barcode value={'example-value'} background='transparent' />
      )
    default:
      return null
  }
}

export default RenderWidget
