export const WIDGETCOLOR = {
    "many2many_tags": "many2many_tags",
    "helpdesk_sla_many2many_tags": "helpdesk_sla_many2many_tags"
}

export const WIDGETAVATAR = {
    "many2one_avatar_user": "many2one_avatar_user",
    "many2many_avatar_user": "many2many_avatar_user",
}


export const WIDGETSTATUS = {
    "sla_status_ids": "sla_status_ids",
}

export const WIDGETNOSTRING = {
    "sla_status_ids": "sla_status_ids",
}