import React from 'react'
import { useFormatDate } from '../../hooks/useFormatDate'
import moment from 'moment'
import { Tooltip } from 'react-tooltip'
import momentTZ from 'moment-timezone'

const RemainingDays = (props) => {
    const { value, icon_cmp, string, isForm } = props
    const { getRelativeTime } = useFormatDate()
    const adjustedValue = moment(value).add(7, 'hours');
    const isFuture = adjustedValue.isAfter(moment());
    if (!value) return
    return (
        <div className={isForm && 'mb-4'}>
            <Tooltip id={`remainingDays_${value}`} place='top' content={string} />
            <div data-tooltip-id={`remainingDays_${value}`} className='flex items-center w-fit cursor-context-menu'>
                {icon_cmp && <i className={`fa ${icon_cmp}`}></i>}
                <div className={`text-sm font-semibold ${isFuture ? "text-[rgba(73,80,87,0.76)]" : "text-red-700"}`}>
                    {getRelativeTime(momentTZ.utc(value).tz("Asia/Ho_Chi_Minh").format("DD-MM-YYYY HH:mm:ss"))}
                </div>
            </div>
        </div>
    )
}

export default RemainingDays
