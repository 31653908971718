import React from 'react';
import { stringToColor } from '../../util/util';
import ModalDetail from '../modal/ModalDetail';
import { Tooltip } from 'react-tooltip';
import Many2ManyTag from './Many2ManyTag';
import Many2OneField from './Many2OneField';

export const Avatar = ({ fullName, id, size = 26, textColor = "#fff", isForm, relation, vid, allowShowDetail = true, avatarSrc }) => {
  const content = fullName ? fullName.charAt(0).toUpperCase() : '';

  const bgColor = stringToColor(fullName, id);

  const avatarStyle = {
    width: `${size}px`,
    height: `${size}px`,
    backgroundColor: bgColor,
    color: textColor,
    fontSize: `${size / 2.5}px`,
  };

  return <>
    {isForm && allowShowDetail && <ModalDetail idToolTip={"avatar-widget-id-" + id} title={"Chi tiết"} idForm={id} resModel={relation} vid={vid} place={"top-start"} />}
    {
      allowShowDetail &&
      <Tooltip
        id={"avatar-widget-id-" + id}
        place={isForm ? "bottom" : "top"}
        content={fullName}
      />
    }
    {
      avatarSrc ? <img data-tooltip-id={"avatar-widget-id-" + id} src={avatarSrc} className='uppercase font-medium rounded-md flex items-center justify-center cursor-pointer size-[26px]' /> :
      <div data-tooltip-id={"avatar-widget-id-" + id} style={avatarStyle} className='uppercase font-medium rounded-md flex items-center justify-center cursor-pointer'>
        {content}
      </div>
    }
  </>
};


const InfoWidget = (props) => {
  const { value, isForm } = props

  if(isForm) {
    return <Many2OneField {...props} />
  }
  
  if (!value || (Array.isArray(value) && value?.length === 0)) {
    return <></>
  }
  
  const inforValues = Array.isArray(value) ? value : [value]

  return (
    <div className='group relative flex items-center gap-1 rounded-lg transition-shadow duration-300'>
      {
        inforValues?.length > 1 ? inforValues?.map((item) => (
          <Avatar key={item.id} id={item.id} fullName={item?.display_name ?? item?.label} isForm={isForm} />
        )) : <div className='flex gap-2 items-center'>
          <Avatar avatarSrc={inforValues[0].image_256} id={inforValues[0]?.id} fullName={inforValues[0]?.display_name ?? inforValues[0]?.label} isForm={isForm} />
          <span className="capitalize">{inforValues[0]?.display_name}</span>
        </div>
      }
    </div>
  )
}

export default InfoWidget
