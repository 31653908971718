import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useLoginSubmit from '../../hooks/useLoginSubmit'
import { SidebarContext } from '../../util/context/SidebarContext'
import ImageWithBasePath from '../../components/general/ImageCustom'
import ButtonLoading from '../../components/general/ButtonLoading'
import { hasAccessToken } from '../../util/util'
import { useTranslation } from 'react-i18next'

const Signin = () => {
  const { t } = useTranslation()
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [isRemember, setIsRemember] = useState(true)
  let navigate = useNavigate()
  const { register, handleSubmit, formState: { errors } } = useForm()
  const { onSubmit } = useLoginSubmit({
    navigate: navigate,
    isRemember: isRemember,
  })
  const { isLoading, setIsLoading } = useContext(SidebarContext)

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState)
  }
  const handleRemember = () => {
    !isRemember ? setIsRemember(true) : setIsRemember(false)
  }

  const accessToken = hasAccessToken()

  useEffect(() => {
    setIsLoading(false)
    // if (accessToken) {
    //   setIsNearLogin(true)
    //   navigate('/list')
    // }
  }, [accessToken])

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center"
        >
          <ImageWithBasePath
            src={'assets/img/authentication/sigin-bg-small.png'}
            className="object-cover w-[548px] h-[576px] hidden md:block"
          />
          <div className="flex flex-col gap-[40px] bg-white p-10 shadow-lg rounded-md w-[479px] h-[576px]">
            <div className='w-full flex justify-center'>
              <ImageWithBasePath
                src={'assets/img/authentication/logo-hoasen.png'}
                className="object-contain  w-[271px] h-auto"
              />
            </div>
            <div className='flex flex-col gap-[24px]'>
              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  {t("username")}<span className='text-red-500'>*</span>
                </label>
                <div>
                  <input type="text"   {...register('email', { required: t("required_username") })} className='border-[1px] border-solid w-full border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder={t("placeholder_username")} />
                  {errors.email && (
                    <p className="text-red-500 text-[12px] mt-[2px]">{errors.email.message}</p>
                  )}
                </div>
              </div>
              <div className='flex flex-col gap-3'>
                <label className="text-[rgba(38,38,38,1)] font-semibold text-[14px]">
                  {t("password")}<span className='text-red-500'>*</span>
                </label>
                <div className='relative'>
                  <input type={isPasswordVisible ? 'text' : 'password'}
                    {...register('password', { required: t("required_password") })}
                    className='border-[1px] w-full border-solid border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded' placeholder={t("placeholder_password")} />
                  <div onClick={togglePasswordVisibility} className='absolute right-[10px] top-1/2 transform -translate-y-1/2'>
                    {!isPasswordVisible ?
                      <ImageWithBasePath
                        src={'assets/img/authentication/hide-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                      :
                      <ImageWithBasePath
                        src={'assets/img/authentication/show-password.svg'}
                        className="object-contain w-[20px] h-[20px]"
                      />
                    }
                  </div>
                  {errors.password && (
                    <p className="text-red-500 text-[12px] mt-[2px]">{errors.password.message}</p>
                  )}
                </div>
              </div>
              <div className='flex justify-between items-center'>
                <div className='flex gap-3 items-center'>
                  <label
                    className={`group cursor-pointer rounded-[4px] ${!isRemember && 'h-[20px] w-[20px] border-[2px] border-[#D9D9D9]'
                      }`}
                  >
                    <div className='flex size-5 items-center justify-center rounded-[4px] transition-all group-has-[:checked]:border-[rgba(15,48,112,1)] group-has-[:checked]:bg-[rgba(15,48,112,1)] group-has-[:checked]:opacity-100'>
                      <ImageWithBasePath
                        src={'assets/img/authentication/tick.svg'}
                        className="object-contain w-[13px] h-[13px]"
                      />
                    </div>
                    <input
                      type='checkbox'
                      onChange={() => handleRemember()}
                      checked={isRemember}
                      width={20}
                      className='hidden cursor-pointer'
                      height={20}
                    />
                  </label>
                  <div className='text-[rgba(15,48,112,1)] font-medium text-[14px]'>{t("remember_me")}</div>
                </div>
                <button type="button" onClick={() => navigate('/forgot-password')} className='underline text-[rgba(15,48,112,1)] font-medium text-[14px]'>{t("forgotten_password")}?</button>
              </div>
            </div>
            <ButtonLoading loading={isLoading} content={t("sign_in")} className='text-[16px] rounded font-semibold py-3 text-[rgba(255,255,255,1)] !bg-[#0B701D]' />
          </div>
        </form>
      </div>
    </div>
  )
}

export default Signin
