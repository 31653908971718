import React, { Fragment, useEffect, useState } from 'react'
import { X } from 'react-feather'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useTable from '../../table/model/table'
import { formatSortingString, getSpecificationByFields } from '../../../util/util'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useTableStore from '../../../store/table'
import useFormStore from '../../../store/form'
import useHeaderStore from '../../../store/header'
import ModelService from '../../../core/services/model-service/model-service'
import ViewServices from '../../../core/services/view-service/view-service'
import TableView from '../../table/view/table-view'
import Search from '../../toolbar/view/search'
import Pagination from '../../general/Pagination'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { COMPONENT_TYPE } from '../../../constant/componen-type'
import useSearchStore from '../../../store/search'
import Loading from '../../general/Loading'
import { useDebounce } from 'use-debounce'
import { useTranslation } from 'react-i18next'

const Many2ManyView = ({ id, isShow, title, relation, domain, setDomain, context, tab, append, funcChoose, funcClose, funcCreateModal, funcCreatePage, showCheckBox = true, onchangeData }) => {
    const [searchParams] = useSearchParams()
    const { t } = useTranslation()
    const vid = searchParams.get('vid')
    const model = searchParams.get('model')
    const { lang, rootContext } = useHeaderStore()
    const actionData = JSON.parse(sessionStorage.getItem("actionData")) ?? ""
    const { fields, setFields, page, setPage, selectedRowKeys } = useTableStore()
    const { setViewDataStore } = useFormStore()
    const { groupByDomain, setGroupByDomain } = useSearchStore()
    const [debouncedPage] = useDebounce(page, 500);
    const [order, setOrder] = useState()
    const navigate = useNavigate()

    const { data: viewResponse, isFetched: isViewReponseFetched } = useQuery({
        queryKey: [`view-${relation}-${vid}`, lang],
        queryFn: () => ViewServices.getFieldView({
            resModel: relation, views: [
                [false, 'list']
            ], lang: lang, context
        }),
        refetchOnWindowFocus: false
    })

    const viewData = viewResponse

    const specification = viewResponse && getSpecificationByFields([...viewResponse?.views?.list?.fields, ...(tab?.fields ? tab.fields : [])], {}, viewData, relation)
    const default_order = viewResponse && viewResponse?.views?.list?.default_order

    const fetchData = async () => {
        try {
            setDomain(domain)
            setViewDataStore(viewResponse)
            const modalData = viewResponse?.views?.list?.fields.map((field) => ({
                ...viewResponse?.models?.[model]?.[field?.name],
                ...field
            }))

            if (!fields?.[`${vid}_${relation}_popupmany2many`] && modalData) {
                setFields({ ...fields, [`${vid}_${relation}_popupmany2many`]: modalData })
            }
            setPage(0)

        } catch (err) {
            console.log(err);
        }
    }
    const { data: dataResponse, isFetched: isDataResponseFetched, isFetching, isPlaceholderData } = useQuery({
        queryKey: [`view-${relation}-${vid}`, specification, domain, page, debouncedPage, groupByDomain, order],
        queryFn: () => ModelService.getAll({ resModel: relation, specification: specification, domain: domain, offset: debouncedPage * 10, context: context, fields: groupByDomain?.fields, groupby: [groupByDomain?.contexts[0]?.group_by], sort: order ? order : default_order ? formatSortingString(default_order) : "" }),
        enabled: !!specification && !!relation && !!domain && !!viewResponse,
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
        keepPreviousData: true
    })

    useEffect(() => {
        if (viewResponse) {
            fetchData()
        }
        return () => {
            setGroupByDomain(null)
            setFields(prevFields => ({
                ...prevFields,
                [`${vid}_${relation}_popupmany2many`]: null
            }));
            setPage(0);
            setDomain([]);
        };
    }, [viewResponse])

    const { dataSource, columns, visibleOptionalFields, typeTable, setDataSource } = useTable({
        model: relation,
        fields: fields?.[`${vid}_${relation}_popupmany2many`] || viewResponse?.views?.list?.fields,
        data: dataResponse?.records ?? dataResponse?.groups,
        dataModel: viewData?.models?.[relation],
        context: { ...rootContext, ...context },
        type: "many2many",
        typeTable: dataResponse?.groups ? "group" : "list"
    });

    const handleCreateNewOnPage = async () => {
        try {
            const actionData = await ViewServices.getFormView({ id: null, model: relation, context: context })
            sessionStorage.setItem("actionData", JSON.stringify(actionData))
            navigate(`/form?model=${relation}`)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Transition
                show={isShow}
                enter='transition duration-100 ease-out'
                enterFrom='transform scale-95 opacity-0'
                enterTo='transform scale-100 opacity-100'
                leave='transition duration-75 ease-out'
                leaveFrom='transform scale-100 opacity-100'
                leaveTo='transform scale-95 opacity-0'
                as={Fragment}
            >
                <Dialog onClose={funcClose}>
                    <DialogPanel>
                        <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                            <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                            <div className='absolute inset-0 overflow-auto px-6 flex justify-center items-center'>
                                <div className='relative z-[1] p-4 flex flex-col gap-2 max-w-full lg:max-w-[1000px] lg:min-w-[1000px] transform rounded-3xl bg-[#FFF] min-h-[90%] max-h-[90%] h-fit'>
                                    <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                        <div className='text-[20px] font-semibold'>
                                            {title}
                                        </div>
                                        <button onClick={funcClose}>
                                            <X size={20} />
                                        </button>
                                    </div>

                                    <div className='grid grid-cols-3 gap-2 items-center py-2'>
                                        <div className='col-span-3 sm:col-span-2 flex-1'>
                                            {isViewReponseFetched && <Search typeFields="many2many" searchViewId={"false"} actionData={{
                                                ...actionData, views: [
                                                    [false, 'list']
                                                ]
                                            }} viewData={viewResponse} model={relation} domain={domain} setDomain={setDomain} />}
                                        </div>
                                        <Pagination
                                            className="pagination-bar col-span-3 sm:col-span-1 justify-end flex-1"
                                            currentPage={page}
                                            totalCount={dataResponse?.length ?? 0}
                                            pageSize={10}
                                            onPageChange={page => setPage(page)}
                                            displayPageNumberDots={false}
                                        />
                                    </div>
                                    {(isViewReponseFetched && isDataResponseFetched) || isPlaceholderData ?
                                        <div className='relative overflow-y-auto'>
                                            <TableView
                                                model={relation}
                                                dataSource={dataSource}
                                                dataResponseLength={dataResponse?.length}
                                                columns={columns}
                                                visibleOptionalFields={visibleOptionalFields}
                                                type="many2many"
                                                tabIndex={tab?.index}
                                                append={append}
                                                showCheckBox={showCheckBox}
                                                funcChoose={funcChoose}
                                                typeCo={COMPONENT_TYPE.TREE}
                                                typeTable={typeTable}
                                                specification={specification}
                                                context={context}
                                                setDataSource={setDataSource}
                                                order={order}
                                                setOrder={setOrder}
                                            />
                                        </div>
                                        :
                                        <Loading />
                                    }
                                    <div className='flex items-center gap-2 mt-auto'>
                                        {showCheckBox && <button disabled={selectedRowKeys?.length === 0} type='button' onClick={() => funcChoose(selectedRowKeys)} className='button-primary'>{t("choose")}</button>}
                                        <button type='button' onClick={() => {
                                            if (typeof funcCreateModal === "function") {
                                                funcCreateModal()
                                                funcClose()
                                            }
                                            else {
                                                handleCreateNewOnPage()
                                            }
                                        }} className='button-primary'>{t("new")}</button>
                                        <button type='button' onClick={funcClose} className='button-secondary'>{t("cancel_button")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </Transition>
            {/* {isShowModalNew &&
                <ModalAddNew
                    resModel={relation}
                    context={context}
                    handleClose={() => {
                        setIsShowModalNew(false)
                        funcClose()
                    }}
                    title={t("title_create_new")}
                    onchangeData={onchangeData}
                    index={dataResponse?.length}
                    tab={tab}
                />
            } */}

        </>
    )
}

export default Many2ManyView