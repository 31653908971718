import { useSearchParams } from 'react-router-dom'
import { checkDomain } from '../../../core/domain/domain'
import useTableStore from '../../../store/table'
import { useEffect, useState } from 'react'

const useTable = ({ fields, data, dataModel, model, context, tabIndex, type, typeTable }) => {

  const [searchParams] = useSearchParams()
  const vid = searchParams.get("vid")
  const { setFields, fields: fieldsTable } = useTableStore()
  const [dataSource, setDataSource] = useState(data || [])
  const mergeFields = fields?.map((field) => {
    return { ...dataModel?.[field?.name], ...field }
  })

  useEffect(() => {
    if (mergeFields?.length > 0) {
      const vidPrefix = vid ? `${vid}_` : "";

      if (type === "many2many") {
        setFields({
          ...fields,
          ...fieldsTable,
          [`${vidPrefix}${model}_popup${type}`]: mergeFields,
        });
      } else if (tabIndex === undefined) {
        setFields({
          ...fields,
          ...fieldsTable,
          [`${vidPrefix}${model}`]: mergeFields,
        });
      } else {
        setFields({
          ...fields,
          ...fieldsTable,
          [`${vidPrefix}${model}_tab${tabIndex}`]: mergeFields,
        });
      }
    }
  }, []);


  const visibleOptionalFields = mergeFields?.filter(
    (item) => item?.optional && !(item?.column_invisible ? checkDomain(context, item?.column_invisible) : false)
  )

  const transformData = (dataList) => {
    return dataList.map((item) => {
      const transformedItem = { ...item }

      Object.keys(item).forEach((field) => {
        if (item[field] && typeof item[field] === 'object' && item[field].display_name) {
          transformedItem[field] = item[field]
        } else if (Array.isArray(item[field]) && item[field].length > 0) {
          transformedItem[field] = item[field]
        }
      })

      return item.display_name ? { ...transformedItem, item: item.display_name } : transformedItem
    })
  }

  useEffect(() => {
    setDataSource(transformData(data || [])) // Update dataSource when `data` changes
  }, [data])

  const handleGetColumns = () => {
    let cols = []
    try {
      cols = mergeFields
        ?.filter(
          (item) =>
            (item?.optional === 'show' || !item?.optional) &&
            !(item?.column_invisible ? checkDomain(context, item?.column_invisible) : false)
        )
        ?.map((field) => {
          return {
            key: field?.name,
            title: field?.string,
            field: { ...field },
          }
        })
    } catch (error) {
      console.log('fields:', fields)
      console.error('Error in useTable:', error)
    }

    return cols
  }

  const columns = handleGetColumns()

  return {
    dataSource,
    columns,
    visibleOptionalFields,
    typeTable,
    data,
    setDataSource
  }
}

export default useTable
