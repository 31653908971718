import { useNavigate } from "react-router-dom"
import FieldView from "../../components/field/field-view"
import ReactStars from 'react-stars'
import momentTZ from 'moment-timezone'
import { useFormatDate } from "../../hooks/useFormatDate"
import { useTranslation } from "react-i18next"
import { COLORS } from "../../constant/color-type"
import { formatTimeFromNumber } from "../../util/util"
import { useEffect, useState } from "react"
import KanbanServices from "../../core/services/kanban/view-service"
import useProfileStore from "../../store/profile"
import KanbanItem from "./kanbanItem"
import ImageWithBasePath from "../../components/general/ImageCustom"


const KanbanView = ({ data, name, vid, resModel, course, color, field, width_context, specification }) => {
    const { dataUser } = useProfileStore()


    const { t } = useTranslation()
    const navigate = useNavigate()
    const { getRelativeTime } = useFormatDate()
    const [groups, setGroups] = useState()
    const [title, setTitle] = useState()
    const handleClick = (item) => {
        navigate(`/form?vid=${vid}&model=${resModel}&id=${item.id}`)
    }
    const fetchGroup = async (resModel, width_context) => {
        await KanbanServices.getGroup({ resModel, width_context }).then((res) => {
            setGroups(res?.groups)

        }).catch((error) => {
        })
    }
    const fetchTitle = async (color, resModel, field, width_context) => {
        await KanbanServices.getProgressBar({ color, resModel, field, width_context }).then((res) => {
        }).catch((error) => {
        })
    }
    useEffect(() => {
        if (color, resModel, field, width_context) {
            fetchTitle(color, resModel, field, width_context)
            fetchGroup(resModel, width_context)
        }
    }, [])
    const sum = (num) => {
        return Number(num) + 1
    }
    if (name == "student_template") {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} className="cursor-pointer min-w-[350px] min-h-[133px] p-[8px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[80px] h-full flex pl-[4px]">
                                {item.image_1920 ? <img src={item.image_1920} width={64} height={64} alt="avatar" /> : <img src="/assets/img/avatar/avatar_grey.png" width={64} height={64} alt="avatar" />}
                            </div>
                            <div className="flex flex-col gap-[4px]">
                                <div>{item.name}</div>
                                <div>{item.curr_year}</div>
                                <div>{item.mobile}</div>
                                <div>{item.email}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "faculty_template") {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} className="min-w-[350px] cursor-pointer min-h-[97px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[95px] h-full flex pl-[4px] rounded-full">
                                {item.image_1920 ? <img src={item.image_1920} width={95} height={95} alt="avatar" /> : <img src="/assets/img/avatar/avatar_grey.png" width={95} height={95} alt="avatar" />}
                            </div>
                            <div className="flex flex-col gap-[4px] p-[8px]">
                                <div className="font-bold text-[16px]">{item.name}</div>
                                <div>{item.email}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "slide_channel_template") {
        return (
            <div className={`flex sm:flex-wrap items-center pt-[16px] px-[8px] ${data?.length > 2 ? "justify-center" : "justify-start"} `}>
                {data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="cursor-pointer w-full max-w-[533px] min-h-[133px] px-[16px] py-[8px] flex flex-col gap-[16px] bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-full h-full flex justify-between">
                                <div>{item.name}</div>
                                <div>more</div>
                            </div>
                            <div className="flex flex-col gap-[8px]">
                                <div className="flex items-center">
                                    <div className="w-1/2">
                                        <button className="bg-[#ED1C24] px-[10px] py-[5px] rounded-[4px] text-[#fff] font-semibold">
                                            Xem khóa học
                                        </button>
                                    </div>
                                    <div className="w-1/2 flex justify-between">
                                        <div className="flex flex-col gap-[4px] w-full">
                                            <div className="flex items-center justify-between w-full">
                                                <div>Lượt xem</div>
                                                <div>{item.total_views}</div>
                                            </div>
                                            <div className="flex items-center justify-between w-full">
                                                <div>Nội dung</div>
                                                <div>{item.total_time}</div>

                                            </div>
                                            <div className="flex items-center justify-between w-full">
                                                <div>Thời hạn</div>
                                                <div>{item.total_slides}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full flex items-center pb-[8px]">
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_invited_count}</div>
                                        <div>Đã mời</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_engaged_count}</div>
                                        <div>Đang học</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 border-r-[1px] items-center border-solid border-r-[#dee2e6]">
                                        <div>{item.members_completed_count}</div>
                                        <div>Hoàn tất</div>
                                    </div>
                                    <div className="h-[42px] flex flex-col gap-[4px] w-1/4 items-center">
                                        <div>{item.members_all_count}</div>
                                        <div>Tổng</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "slide_slide_view_kanban") {
        return (
            <div className="flex flex-wrap justify-start items-start px-[8px]">
                {data && data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="w-full cursor-pointer max-w-[350px] min-h-[143px] p-[8px] gap-[8px] flex bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className="w-[95px] h-full flex rounded-full">
                                <img src="/assets/img/categories/contentDefault.jpg" width={95} alt="avatar" />
                            </div>
                            <div className="flex flex-col justify-between gap-[24px]">
                                <div className="flex flex-col gap-[6px]">
                                    <div className="font-bold">{item.name}</div>
                                    <FieldView
                                        {...course.field}
                                        idForm={false}
                                        string=''
                                        defaultValue={item[course.key]}
                                    />
                                    {item.tag_ids?.length > 0 && <div className="p-[4px] rounded-full bg-[#fae9e9] w-[70px] text-center text-[12px]">{item.tag_ids[0].display_name}</div>}
                                </div>
                                <div className="flex items-center gap-[12px] ">
                                    {item.slide_category && (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/document.svg" alt="document" />
                                    </div> {item.slide_category == "article" ? "Tài liệu" : "Bài viết"}</div>)}

                                    {item.completion_time && item.completion_time == "0" ? (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/clock.svg" alt="clock" />
                                    </div>  00:00</div>) : (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/clock.svg" alt="clock" />
                                    </div>  0{item.completion_time}:00</div>)}
                                    {item.completion_time && item.completion_time == "0" ? (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/question.svg" alt="clock" />
                                    </div> 0</div>) : (<div className="flex items-center gap-[4px]"> <div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/question.svg" alt="clock" />
                                    </div>{item.completion_time}</div>)}
                                    {item.total_views && item.total_views == "0" ? (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/eye.svg" alt="clock" />
                                    </div> 0</div>) : (<div className="flex items-center gap-[4px]"><div className="w-[16px] h-[16px]">
                                        <img src="/assets/img/avatar/eye.svg" alt="clock" />
                                    </div> {item.total_views}</div>)}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "sla") {
        return (
            <div className="flex flex-wrap justify-start items-start">
                {data && data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="flex flex-col gap-[6px] w-full cursor-pointer max-w-[350px] p-[8px] bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className=" gap-[8px] flex font-bold text-[14px]">
                                <div className="flex flex-col gap-[4px]">
                                    <div>{item.name}</div>
                                </div>
                            </div>
                            <div>{item?.team_id?.display_name}</div>
                            <div>
                                {item.priority && (
                                    <ReactStars
                                        count={3}
                                        defaultValue={item.priority}
                                        value={item.priority}
                                        size={16}
                                        color2={'#ffd700'}
                                        color1={'#ddd'} />
                                )}

                            </div>
                            <div>{formatTimeFromNumber(item?.time)}</div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "helpdesk_ticket_kanban") {
        return (
            <div className="flex flex-wrap justify-center items-start">
                {data && data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="flex flex-col gap-[6px] w-full cursor-pointer max-w-[350px] md:w-[23.8%] md:min-h-[197px] min-h-[97px] p-[8px] bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className=" gap-[8px] flex">
                                <div className="w-[64px] h-full flex rounded-full">
                                    {item.books_image ? <img src={item.books_image} width={64} height={64} alt="avatar" /> : <img src="/assets/img/avatar/default_book.png" width={64} height={64} alt="avatar" />}
                                </div>
                                <div className="flex flex-col gap-[4px]">
                                    <div className="font-bold text-[16px]">{item.books_code}</div>
                                    <div>{item.name}</div>
                                </div>
                            </div>
                            {item?.commercial_partner_id?.display_name && (
                                <div className="gap-[8px] flex items-center">
                                    <div>{t("partner_customer")}:</div>
                                    <div>{item?.commercial_partner_id?.display_name}</div>

                                </div>
                            )}

                            <div className="gap-[8px] flex items-center">
                                <div>{t("priority")}:</div>
                                <div>
                                    {item.priority && (
                                        <ReactStars
                                            count={3}
                                            defaultValue={item.priority}
                                            value={item.priority}
                                            size={16}
                                            color2={'#ffd700'}
                                            color1={'#ddd'} />
                                    )}

                                </div>
                            </div>
                            {item?.tag_ids && item?.tag_ids[0]?.display_name && (
                                <div className="gap-[8px] flex items-center">
                                    <div>{t("type")}:</div>
                                    <div>
                                        <div>{item.tag_ids[0]?.display_name}</div>
                                    </div>
                                </div>
                            )}

                            <div className="gap-[8px] flex items-center">
                                <div>{t("assign")}:</div>
                                <div>{item?.user_id?.display_name}</div>
                            </div>
                            <div className="gap-[8px] flex items-center">
                                <div>{t("time")}:</div>
                                <div>{getRelativeTime(momentTZ.utc(item?.sla_deadline).tz("Asia/Ho_Chi_Minh").format("DD-MM-YYYY HH:mm:ss"))}</div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "helpdesk_team") {
        return (
            <div className="flex flex-wrap justify-start items-start">
                {data && data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="flex flex-col gap-[6px] w-full cursor-pointer max-w-[350px] md:w-[23.8%] p-[8px] bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className=" gap-[8px] flex flex-col">
                                <div className="flex flex-col gap-[4px] font-bold text-[15px]">
                                    <div>{item.display_name}</div>
                                </div>
                                <div className="flex items-start gap-[16px] w-full">
                                    <div className="bg-[#0b701d] py-[5px] px-[10px] rounded-[8px] text-[#fff]">{t("tickets")}</div>
                                    <div className="flex-col gap-[16px] w-full">

                                        <div className="flex items-center justify-between gap-[24px] w-full">
                                            <div>{t("tickets_closed")}</div>
                                            <div>{item?.ticket_closed}</div>
                                        </div>

                                    </div>
                                </div>
                                <div className="mt-[32px] flex flex-col justify-center text-[#66598f]">
                                    <div className="flex items-center">
                                        <div className="px-[16px] border-r-[1px] border-solid border-r-[#dee2e6] min-w-[104px] flex flex-col items-center">{item?.open_ticket_count} <div className="text-[#495057c2]">{t("kanban_open")}</div></div>
                                        <div className="px-[16px] border-r-[1px] border-solid border-r-[#dee2e6] min-w-[104px] flex flex-col items-center">{item?.unassigned_tickets} <div className="text-center w-[150px] text-[#495057c2]">{t("kanban_unassigned")}</div></div>
                                        <div className="px-[16px] min-w-[104px] flex flex-col items-center">{item?.urgent_ticket} <div className="text-[#495057c2]">{t("kanban_urgent")}</div></div>
                                    </div>
                                    <div className="px-[16px] min-w-[104px] flex flex-col items-center">{item?.ticket_closed} <div className="text-[#495057c2]">{t("kanban_failed")}</div></div>

                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "helpdesk_stages") {
        return (
            <div className="flex flex-wrap justify-start items-start">
                {data && data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="flex flex-col gap-[6px] w-full cursor-pointer max-w-[350px] md:w-[23.8%] min-h-[110px] p-[8px] bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className=" gap-[8px] flex">
                                <div className="flex flex-col gap-[4px]">
                                    <div className="font-bold text-[16px]">{item.name}</div>
                                </div>
                            </div>
                            <div className="gap-[8px] flex items-center flex-wrap">
                                {item?.team_ids && item?.team_ids.length > 0 && (
                                    item?.team_ids?.map((item, index) => {
                                        return (

                                            <div key={item.id} className={`py-[3px] px-[9.7px] rounded-full`} style={{ backgroundColor: `${COLORS[item?.color].color}` }}>

                                                <div>{item.display_name}</div>

                                            </div>
                                        )
                                    })
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    } else if (name == "help_desk_ticket") {
        return (
            <div className="pt-[16px] w-full overflow-hidden flex-1 flex h-full">
                <div className="flex justify-start items-start overflow-x-auto w-full flex-1 h-full">
                    {
                        groups?.length > 0 ?
                            <div className="flex justify-start items-start">
                                {groups && resModel && specification && width_context && groups.map((item) => {
                                    return (
                                        <KanbanItem key={item?.stage_id[0]} data={item} width_context={width_context} specification={specification} resModel={resModel} handleClick={handleClick} />
                                    )
                                })
                                }
                            </div> :
                            <div className='w-full mx-auto'>
                                <div className={`flex flex-col items-center justify-center gap-3 my-20`}>
                                    <ImageWithBasePath
                                        src='assets/img/empty_document.svg'
                                        alt='empty_document.svg'
                                        width={120}
                                        height={140}
                                        className='h-[140px] w-[120px]' />
                                    <div className='text-xl font-extrabold'>{t("empty_data")}</div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className="flex flex-wrap justify-start items-start">
                {data && data.map((item) => {
                    return (
                        <div onClick={() => handleClick(item)} key={item.id} className="flex flex-col gap-[6px] w-full cursor-pointer max-w-[350px] md:w-[23.8%] md:min-h-[197px] min-h-[97px] p-[8px] bg-white border-solid border-[1px] border-[#dee2e6] mx-2 my-1">
                            <div className=" gap-[8px] flex">
                                <div className="w-[64px] h-full flex rounded-full">
                                    {item.books_image ? <img src={item.books_image} width={64} height={64} alt="avatar" /> : <img src="/assets/img/avatar/default_book.png" width={64} height={64} alt="avatar" />}
                                </div>
                                <div className="flex flex-col gap-[4px]">
                                    <div className="font-bold text-[16px]">{item.books_code}</div>
                                    <div>{item.name}</div>
                                </div>
                            </div>

                            <div className="gap-[8px] flex items-center">
                                <div>{t("partner_customer")}:</div>
                                {item?.commercial_partner_id?.display_name && (
                                    <div>{item?.commercial_partner_id?.display_name}</div>
                                )}
                            </div>


                            <div className="gap-[8px] flex items-center">
                                <div>{t("priority")}:</div>
                                {item.priority && (
                                    <div>

                                        <ReactStars
                                            count={3}
                                            defaultValue={item.priority}
                                            value={item.priority}
                                            size={16}
                                            color2={'#ffd700'}
                                            color1={'#ddd'} />
                                    </div>
                                )}
                            </div>



                            <div className="gap-[8px] flex items-center">
                                <div>{t("type")}:</div>
                                {item?.ticket_type_id && item?.ticket_type_id?.display_name && (
                                    <div>

                                        <div>{item.ticket_type_id?.display_name}</div>

                                    </div>
                                )}
                            </div>


                            <div className="gap-[8px] flex items-center">
                                <div>{t("assign")}:</div>
                                {item?.user_id?.display_name && (
                                    <div>{item?.user_id?.display_name}</div>
                                )}
                            </div>



                            <div className="gap-[8px] flex items-center">
                                <div>{t("time")}:</div>
                                {item?.sla_deadline && (
                                    <div>{getRelativeTime(momentTZ.utc(item?.sla_deadline).tz("Asia/Ho_Chi_Minh").format("DD-MM-YYYY HH:mm:ss"))}</div>
                                )}
                            </div>


                        </div>
                    )
                })}
            </div>
        )
    }

}
export default KanbanView