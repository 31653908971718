import React from 'react';
import { COLORS } from '../../constant/color-type';
import { X, Check } from 'react-feather';

const Status = (props) => {
  const { value, isForm } = props

  return (
    <div className={`flex gap-1 flex-wrap ${isForm ? "mb-4" : ""}`}>
      {Array.isArray(value) && value?.map((item) => {
        const { display_name, color, status, id } = item
        const bgColor = color !== null ? COLORS[color]?.color : COLORS[id]?.color

        return <div className='w-fit px-2 py-[2px] rounded-full text-[12px] text-black flex gap-1 items-center' style={{ backgroundColor: bgColor || "RGBA(230.1375, 221.3625, 221.3625, 1)" }}>
          {
            status === "failed" ? <span className='p-[0.5px] rounded-full bg-black' style={{ color: bgColor || "RGBA(230.1375, 221.3625, 221.3625, 1)" }}>
              <X className="font-bold" strokeWidth={5} size={8} />
            </span> :
              status === "reached" ? <span className='p-[0.5px] rounded-full bg-black' style={{ color: bgColor || "RGBA(230.1375, 221.3625, 221.3625, 1)" }}>
                <Check className="font-bold" strokeWidth={5} size={8} />
              </span> : ""
          }
          {display_name}
        </div>
      })

      }
    </div>
  )
}

export default Status
