import React from 'react'
import ModalLayer from './ModalLayer'
import { useTranslation } from 'react-i18next'

const ModalConfirmArchive = ({ isShowConfirmArchive, isArchive, handleArchive, handleClose, title, content }) => {
    const { t } = useTranslation()
    return (
        <ModalLayer isOpen={isShowConfirmArchive} onClose={handleClose}>
            <div className='mx-auto flex flex-col items-center justify-center gap-4'>
                <div className='flex flex-col gap-[4px] items-center'>
                    <div className='text-[18px] font-bold'>{title}</div>
                    <p className='text-[16px]'>{content}</p>
                </div>
                <div className='flex justify-center items-center gap-2 w-full'>
                    <button type='button' onClick={handleClose} className='button-secondary flex-1'>{t("cancel")}</button>
                    <button type='button' onClick={handleArchive} className='button-primary flex-1'>{t(isArchive ? "save_archive" : "save_unarchive")}</button>
                </div>
            </div>
        </ModalLayer>
    )
}

export default ModalConfirmArchive
