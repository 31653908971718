import moment from "moment"
import { useFormatDate } from "../../hooks/useFormatDate"
import { formatCurrency } from "../../util/util"
import { FaRegTrashAlt } from "react-icons/fa"
import { useMutation } from "@tanstack/react-query"
import ChatterAction from "../../core/services/chatter"
import { useTranslation } from "react-i18next"
import { Avatar } from "../../components/widget/Info"
import RenderFiles from "./RenderFiles"
import useProfileStore from "../../store/profile"
import { CustomToastMessage } from "../../util/toast/customToastMsg"
import ModalConfirmDelete from "../../components/modal/ModalConfirmDelete"
import { useState } from "react"

const MessageItem = ({ id, author, date, oldValue, newValue, status, body, isNote, listFiles, handleAfterSubmit, data, comment }) => {

    const { getRelativeTime } = useFormatDate()
    const { t } = useTranslation()
    const { dataUser } = useProfileStore()
    const [modalDelete, setModalDelete] = useState(false)

    const formatValue = (value) => {
        if (typeof value === "number") {
            return formatCurrency(parseFloat(value), "VND");
        }

        if (typeof value === "string" && moment(value, "DD/MM/YYYY", true).isValid()) {
            return moment(value).format("DD/MM/YYYY");
        }

        if (typeof value === "boolean") {
            return value ? "Có" : "Không";
        }

        return value ? value : "Không";
    };

    const { mutateAsync: handleDelete, isPending: loadingCallButton } = useMutation({
        mutationFn: () => ChatterAction.deleteComment({
            message_id: id
        }),
        onSuccess: (data) => {
            handleAfterSubmit && handleAfterSubmit()
            CustomToastMessage.success(t('delete_note_success'));
        },
        onError: () => {
            CustomToastMessage.error(t('end_note_failure'));
        }
    });

    return (
        <div className="flex items-start gap-[8px] group">
            <div className=" rounded-full overflow-hidden">
                {
                    dataUser?.picture && author?.userId === dataUser?.sub ?
                        <img src={dataUser?.picture} alt='img' className='img-fluid w-8 h-8 rounded-full object-cover' /> :
                        (author?.avatar_128 ? <img src={author?.avatar_128} alt='img' className='img-fluid w-8 h-8 rounded-full object-cover' /> :
                            <Avatar allowShowDetail={false} fullName={author?.name} id={author?.userId} size={30} />)
                }
            </div>
            <div className="flex flex-col gap-[4px] flex-1">
                <div className=" flex items-center gap-[4px] text-[14px] leading-[14px]">
                    <div className="font-bold text-[#333]">{author?.name}</div>
                    <div className="text-sm text-[rgba(73,80,87,0.76)] opacity-75"> - {getRelativeTime(moment(date).format("DD-MM-YYYY HH:mm:ss"))}</div>
                    {!status && comment == "comment" && (
                        <FaRegTrashAlt
                            size={15}
                            color='black'
                            onClick={() => setModalDelete(!modalDelete)}
                            className={`ml-4 cursor-pointer transition-all group-hover:block hidden ${loadingCallButton ? 'cursor-not-allowed' : ''}`}
                        />
                    )}
                </div>

                {body && <div className={`text-[#495057c2] font-medium w-fit ${!isNote ? 'rounded-lg p-[10px] bg-[#DEEFE4]' : ''}`} dangerouslySetInnerHTML={{ __html: body }}></div>}
                {data && data.map((item) => {
                    return (
                        <div key={item.id} className=" gap-[4px] text-[14px] flex items-center flex-wrap">
                            <span className="w-[24px] h-[21px] flex justify-center items-center">
                                <div className="w-[4px] bg-[#333] h-[4px] rounded-full"></div>
                            </span>

                            <span className="text-[#495057c2] font-medium">{formatValue(item.oldValue.value)}</span>

                            <img src="/assets/img/chatter/right-arrow.svg" alt="attachment" className="object-cover w-[14px] h-[14px]" />

                            <span className="text-[#0180A5] font-medium">{formatValue(item.newValue.value)}</span>

                            <span className="font-normal italic text-[#495057c2]">({item.changedField})</span>
                        </div>
                    )
                })}


                {listFiles?.length > 0 && (
                    <div className="flex items-center gap-2">
                        <RenderFiles selectedFiles={listFiles} />
                    </div>
                )}
                <ModalConfirmDelete
                    title={t("confirm_delete_data")}
                    content={t("delete_record_confirm_title")}
                    isShowConfirmDelete={modalDelete}
                    handleClose={() => setModalDelete(false)}
                    handleDelete={handleDelete}
                />
            </div>
        </div>
    )
}
export default MessageItem