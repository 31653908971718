import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { SidebarContext } from '../../util/context/SidebarContext'
import ImageWithBasePath from '../../components/general/ImageCustom'
import UserService from '../../core/services/auth'
import ButtonLoading from '../../components/general/ButtonLoading'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CustomToastMessage } from '../../util/toast/customToastMsg'
import Cookies from 'js-cookie'

const ForgotPassword = () => {
  const { isLoading } = useContext(SidebarContext);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors }, // Lấy errors từ formState để hiển thị thông báo lỗi
  } = useForm({
    mode: 'onChange', // Kiểm tra validation khi submit
  });
  const navigate = useNavigate();
  const [isLoadingSendRequest, setIsLoadingSendRequest] = useState(false);

  const onSubmit = (data) => {
    const cookieLange = Cookies.get("i18next")
    setIsLoadingSendRequest(true)
    UserService.forgotPassword({
      ...data,
      lang: cookieLange === "vi" ? "vi_VN" : "en_US",
      url: `${window.location.protocol}//${window.location.host}/reset-password`
    })
      .then((res) => {
        CustomToastMessage.success(t("send_link_forgot_password_success"))
        setIsLoadingSendRequest(false)
      })
      .catch((err) => {
        CustomToastMessage.error(err?.error)
        setIsLoadingSendRequest(false)
      })
  }

  return (
    <div className="w-full h-screen">
      <div className="relative w-full h-full">
        <ImageWithBasePath
          src={'assets/img/authentication/signin-bg.png'}
          className="object-cover w-full h-full"
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="absolute inset-0 flex justify-center items-center"
        >
          <div className="flex flex-col gap-[64px] bg-white p-10 shadow-lg rounded-md w-[479px]">
            <div>
              <div className="flex flex-col items-center gap-3">
                <ImageWithBasePath
                  src={'assets/img/forgot-password/lock.svg'}
                  className="object-cover w-[32px] h-[40px]"
                />
                <div className="text-[20px] text-center font-bold text-[rgb(38,38,38)]">
                  {t("forgotten_password")}
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="text-sm text-center text-[rgb(38,38,38)]">
                  {t("placeholder_username_note")}
                </div>
                <input
                  type="text"
                  {...register('email', {
                    required: t("required_username"),
                  })}
                  className="border-[1px] border-solid w-full border-[rgba(217,217,217,1)] py-[14px] pl-4 text-[14px] font-normal rounded"
                  placeholder={t("placeholder_username")}
                />
                {errors.email && (
                  <p className="text-red-500 text-[12px] mt-[2px]">{errors.email.message}</p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <ButtonLoading
                loading={isLoadingSendRequest}
                content={t("send_link")}
                className="text-[16px] rounded font-semibold py-3 px-4 bg-primary"
              />
              <ButtonLoading
                type="button"
                func={() => navigate('/signin')}
                content={t("back_return")}
                className="text-[16px] rounded font-semibold py-3 text-[#0B701D] px-4 bg-transparent border-[1px] border-solid border-[#0B701D]"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;