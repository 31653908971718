import { useQuery } from '@tanstack/react-query'
import React, { Fragment } from 'react'
import { X } from 'react-feather'
import ViewServices from '../../core/services/view-service/view-service'
import { useSearchParams } from 'react-router-dom'
import useHeaderStore from '../../store/header'
import Form from '../../pages/form-view/form'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import { evalJSONContext, isObjectEmpty } from '../../util/util'
import useTableStore from '../../store/table'

const ModalDynamic = ({ idModal, actionResult, handleCloseActions, formValues, isInForm = true }) => {
    const { res_model, views, display_name, res_id, context } = actionResult
    const { selectedRowKeys } = useTableStore()


    const [searchParams] = useSearchParams()
    const vid = searchParams.get('vid')
    const model = searchParams.get("model")
    const id = parseInt(searchParams.get('id'))
    const { rootContext } = useHeaderStore()

    const contextAction = typeof context === "object" && !isObjectEmpty(context) ? context :
        (isObjectEmpty(JSON.parse(context)) ? {
            active_id: id || selectedRowKeys?.[0],
            active_ids: id ? [id] : selectedRowKeys,
            active_model: model,
            ...rootContext,
        } : evalJSONContext(context, {
            active_id: id || selectedRowKeys?.[0],
            active_ids: id ? [id] : selectedRowKeys,
            active_model: model,
            ...rootContext,
        }) ?? {})


    const { data: viewResponse, isError, isLoading } = useQuery({
        queryKey: [`view-${vid}`, idModal, res_model],
        queryFn: () => ViewServices.getFieldView({
            id: res_id > 0 ? false ? idModal : idModal : false,
            resModel: res_model,
            views: views ?? [[false, actionResult?.view_type]],
            context: rootContext
        }),
        enabled: true,
        refetchOnWindowFocus: false
    })

    if (isLoading) return

    return (
        <Transition
            show={true}
            enter='transition duration-100 ease-out'
            enterFrom='transform scale-95 opacity-0'
            enterTo='transform scale-100 opacity-100'
            leave='transition duration-75 ease-out'
            leaveFrom='transform scale-100 opacity-100'
            leaveTo='transform scale-95 opacity-0'
            as={Fragment}
        >
            <Dialog onClose={handleCloseActions}>
                <DialogPanel>
                    <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                        <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                        <div className='absolute inset-0 flex flex-col justify-center items-center px-5'>
                            <div className='relative z-[1] max-w-full my-[88px] p-4 flex flex-col gap-2 w-[1000px] transform rounded-3xl bg-[#FFF] max-h-[90%] h-fit'>
                                <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                    <div id="modal-title" className='text-[20px] font-semibold'>
                                        {display_name ?? viewResponse?.views?.form?.name ?? actionResult?.name}
                                    </div>
                                    <button onClick={handleCloseActions} aria-label="Close">
                                        <X size={20} />
                                    </button>
                                </div>
                                {
                                    isError ? (
                                        <div className='flex justify-center py-6 text-red-500'>
                                            {isError}
                                        </div>
                                    ) : (
                                        <div className='flex-1'>
                                            <Form
                                                resModel={!idModal && res_id === 0 ? model : res_model}
                                                actionData={{
                                                    ...actionResult,
                                                    context: JSON.stringify({
                                                        ...contextAction,
                                                        ...rootContext
                                                    })
                                                }}
                                                id={!idModal ? res_id === 0 ? id : res_id : null}
                                                viewData={viewResponse}
                                                isDisplayBreadcrumbs={false}
                                                vid={vid}
                                                isMainForm={false}
                                                isButtonInside={true}
                                                handleCloseActions={handleCloseActions}
                                                isInForm={isInForm}
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                </DialogPanel>
            </Dialog>
        </Transition>
    )
}

export default ModalDynamic