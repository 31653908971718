import { Dialog, DialogPanel, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { X } from 'react-feather';

const ModalLayer = ({ isOpen, onClose, title, children, }) => {
  return (
    <Transition
      show={isOpen}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <Dialog onClose={onClose} aria-labelledby="modal-title">
        <DialogPanel>
          <div className="fixed bottom-0 left-0 right-0 top-0 z-[500]">
            <div className="absolute inset-0 bg-[rgba(27,27,27,0.48)]"></div>
            <div className="flex items-center justify-center mx-4 absolute inset-0 overflow-auto">
              <div className=" relative z-[1] mx-auto my-[88px] p-4 flex flex-col gap-2 max-w-[1000px] transform rounded-xl bg-[#FFF]">
                <div className={`flex justify-between items-center border-[rgba(0,0,0,0.1)] pb-2`}>
                  {title && (
                    <div id="modal-title" className="text-[20px] font-semibold">
                      {title}
                    </div>
                  )}
                  <button onClick={onClose} aria-label="Close" className='ml-auto absolute top-[16px] right-[16px]'>
                    <X size={20} />
                  </button>
                </div>
                {children}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </Transition>
  );
};

export default ModalLayer;