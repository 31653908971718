import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import ChatterAction from '../../core/services/chatter';
import { LuLoader } from "react-icons/lu";
import { CustomToastMessage } from '../../util/toast/customToastMsg';

export const ButtonSelectFiles = ({ fileInputRef, selectedFiles, setSelectedFiles, setUploadError, placeHolder = null, isInForm = null }) => {
  const [searchParams] = useSearchParams()
  const resModel = searchParams.get('model')
  const id = searchParams.get('id')
  const { t } = useTranslation();

  const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'application/pdf', 'video/mp4', 'application/zip', 'application/x-zip-compressed', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
  const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
  const MAX_TOTAL_SIZE = 50 * 1024 * 1024; // 50MB in bytes

  const { mutateAsync, isPending } = useMutation({
    mutationFn: (data) => ChatterAction.upload(data),
    onSuccess: (data) => {
      const formatData = data?.data?.['ir.attachment']?.[0]
      const files = [...selectedFiles, formatData]
      setSelectedFiles(files);
      CustomToastMessage.success(t("upload_success"))
    },
    onError: () => {
      CustomToastMessage.error(`${t('upload_failure')}`);
    }
  });

  const validateFile = (file) => {
    // Kiểm tra định dạng file
    if (!ALLOWED_TYPES.includes(file.type)) {
      setUploadError(t('file_accept_single'))
      return false;
    }

    // Kiểm tra kích thước file đơn lẻ
    if (file.size > MAX_FILE_SIZE) {
      setUploadError(t('file_accept_single'))
      return false;
    }

    // Tính tổng dung lượng hiện tại
    const currentTotalSize = selectedFiles.reduce((total, file) => total + (file.file_size || file?.size), 0);

    // Kiểm tra tổng dung lượng sau khi thêm file mới
    if (currentTotalSize + file.size > MAX_TOTAL_SIZE) {
      setUploadError(t('file_accept_total'))
      return false;
    }

    return true;
  };

  const handleOnChange = async (event) => {
    const files = Array.from(event.target.files);
    setUploadError()
    event.target.value = '';

    if (files.length > 0) {
      const file = files[0];

      if (!validateFile(file)) {
        return;
      }

      setUploadError(false)

      if (!isInForm) {
        const formData = new FormData();
        formData.append('ufile', file);
        formData.append('thread_model', resModel);
        formData.append('thread_id', Number(id));
        formData.append('is_pending', true);
        await mutateAsync(formData);
      } else {
        const convertToBase64 = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        };

        try {
          const base64File = await convertToBase64(file);
          const files = [...selectedFiles, { name: file?.name, datas: base64File, mimetype: file?.type, size: file?.size }]
          setSelectedFiles(files);
          CustomToastMessage.success(t("upload_success"))
        } catch {
          CustomToastMessage.error(`${t('upload_failure')}`);
        }
      }
    }
  };

  return (
    <>
      {isPending ? (
        <>
          <LuLoader className='animate-spin' />
          <span className="text-sm text-gray-500">{t('Đang tải lên...')}</span>
        </>
      ) : (
        <button
          type='button'
          disabled={isPending}
          className={`w-fit h-[24px] flex gap-2 items-center text-black rounded-full overflow-hidden transition-all ${isPending ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={() => fileInputRef.current.click()}
        >
          <img src="/assets/img/chatter/attachment.svg" alt="attachment" className="object-cover w-[22px] h-full" />
          {placeHolder && <span>{placeHolder}</span>}
        </button>
      )}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleOnChange}
        multiple
        accept=".jpeg,.jpg,.png,.pdf,.mp4,.zip,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
    </>
  );
};



// export const RenderSelectFiles = ({ selectedFiles, setSelectedFiles }) => {
//   const handleRemoveFile = (index) => {
//     if (setSelectedFiles) {
//       // setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
//       const files = selectedFiles.filter((_, i) => i !== index)
//       setSelectedFiles(files);
//     }
//   };

//   const handleFileDownload = async (e, url, name) => {
//     e.stopPropagation()
//     await downloadFile(url, name)
//   }

//   const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'application/pdf', 'video/mp4', 'application/zip', 'application/x-zip-compressed', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

//   const types = {
//     // "image/jpeg": ImageItem,
//     // "image/png": ImageItem,
//     "video/mp4": VideoPlayer,
//     "video/quicktime": VideoPlayer,
//     "video/webm": VideoPlayer,
//     "application/pdf": FileItem,
//     default: FileItem,
//   }

//   return (
//     <div className="mt-3 flex flex-wrap w-full gap-2">
//       {selectedFiles.map((file, index) => {
//         return <div className='relative group/file'>
//           <FileItem key={index} {...file} src={file?.datas} filename={file?.display_name ?? file?.name} index={index} handleRemoveFile={setSelectedFiles && handleRemoveFile} handleFileDownload={handleFileDownload} />
//         </div>
//         // if (file?.mimetype?.includes("image") || file?.mimetype?.includes("img") || checkIsImageLink(file?.datas)) {
//         //   return <div className='relative group/file'>
//         //     <ImageItem index={index} filename={file?.display_name ?? file?.name} src={file.datas} checksum={file?.checksum} handleRemoveFile={setSelectedFiles && handleRemoveFile} handleFileDownload={handleFileDownload} />
//         //   </div>
//         // }
//         // else if (file?.mimetype?.includes("video/mp4")) {
//         //   return <VideoPlayer index={index} src={file?.datas} name={file?.display_name ?? file?.name} handleRemoveFile={setSelectedFiles && handleRemoveFile} handleFileDownload={handleFileDownload} />
//         // }

//         // const Component = types[file?.mimetype] || types.default;

//       })}
//     </div>
//   )
// }


