import React, { forwardRef } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ImageWithBasePath from '../general/ImageCustom';

const DateField = ({
  name,
  value,
  readonly,
  placeholder = "DD/MM/YYYY",
  required,
  invisible,
  methods = null,
  isForm,
  handleOnchange,
}) => {
  const range = (start, end, step = 1) => {
    let arr = [];
    for (let i = start; i < end; i += step) {
      arr.push(i);
    }
    return arr;
  };

  const years = range(1990, new Date().getFullYear() + 1, 1);
  const months = [
    "Tháng 1",
    "Tháng 2",
    "Tháng 3",
    "Tháng 4",
    "Tháng 5",
    "Tháng 6",
    "Tháng 7",
    "Tháng 8",
    "Tháng 9",
    "Tháng 10",
    "Tháng 11",
    "Tháng 12",
  ];

  const InputDateCustom = forwardRef(
    ({ value, onClick, className, onChange, isForm }, ref) => (
      <label className={`flex ${className}`}>
        <input
          className="bg-transparent w-full"
          required={required}
          readOnly={readonly}
          type=""
          onClick={onClick}
          onChange={onChange}
          ref={ref}
          placeholder={placeholder}
          value={
            value && moment(value).isValid() ? moment(value).format("DD/MM/YYYY") : ""
          }
        />
        {isForm && <ImageWithBasePath src={'assets/img/icons/calendar.svg'} />}
      </label>
    ),
  );

  if (!isForm) {
    return (
      <span>
        {value && moment(value).isValid() ? moment(value).format("DD/MM/YYYY") : ""}
      </span>
    );
  } else {
    return (
      <Controller
        name={name}
        control={methods?.control}
        defaultValue={moment().subtract(1, 'days').format('YYYY-MM-DD')}
        render={({ field }) => {
          return (
            <DatePicker
              selected={
                !methods
                  ? value && moment(value).isValid()
                    ? new Date(value)
                    : null
                  : field?.value && moment(field.value).isValid()
                  ? new Date(field.value)
                  : null
              }
              onChange={(date) => {
                if (date) {
                  const formattedDate = moment(date).format('YYYY-MM-DD');
                  field.onChange(formattedDate);
                  handleOnchange(name, formattedDate);
                } else {
                  // Allow clearing the date
                  field.onChange(null);
                  handleOnchange(name, null);
                }
              }}
              readOnly={isForm ? readonly : true}
              placeholderText={placeholder}
              required={required}
              wrapperClassName={`w-full`}
              customInput={
                <InputDateCustom
                  isForm={isForm}
                  className={`${invisible ? 'hidden' : ''} ${
                    !isForm ? "!bg-transparent focus-within:!border-none" : "field"
                  }`}
                />
              }
              portalId="root-portal"
              // Enable clearing with a clear button
              // isClearable={!readonly && isForm} // Show clear button if not readonly
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    {"<"}
                  </button>
                  <select
                    value={date.getFullYear()}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[date.getMonth()]}
                    onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    {">"}
                  </button>
                </div>
              )}
            />
          );
        }}
      />
    );
  }
};

export default DateField;