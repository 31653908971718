import React, { Fragment, useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { X, Eye } from 'react-feather'
import { Dialog, DialogPanel, Transition } from '@headlessui/react'
import useFormStore from '../../store/form'
import DetailForm from '../form/view/detail-form'
import { useTranslation } from 'react-i18next'

const ModalDetail = ({ idToolTip, title, resModel, idForm, vid, place, context }) => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const newFormId = searchParams.get('id')
    const navigate = useNavigate()
    const [showModalDetail, setShowModalDetail] = useState(false)
    const [actionID, setActionID] = useState()
    const [actionData, setActionData] = useState()

    const { isShowingModalDetail, setIsShowingModalDetail } = useFormStore()
    const newId = localStorage.getItem("vid")


    const handleToggleModal = (e) => {
        e.stopPropagation()
        setShowModalDetail(!showModalDetail)
        setIsShowingModalDetail(!isShowingModalDetail)
    }

    const handleNavigateDetail = () => {
        sessionStorage.setItem("actionData", JSON.stringify(actionData))
        localStorage.setItem("idForm", newFormId)
        setIsShowingModalDetail(!isShowingModalDetail)
        navigate(`/form?model=${resModel}&id=${idForm}`)
    }
    useEffect(() => {
        if (newId) {
            setActionID(newId)
        }
    }, [newId])
    return (
        <>
            {!isShowingModalDetail && <Tooltip opacity={1} className=' p-2' place={place} id={idToolTip} clickable>
                <button className='flex gap-2 items-center justify-center rounded-lg' type="button" onClick={handleToggleModal}>
                    <Eye size={12} />
                    {title}
                </button>
            </Tooltip>}
            {showModalDetail &&
                <Transition
                    show={true}
                    enter='transition duration-100 ease-out'
                    enterFrom='transform scale-95 opacity-0'
                    enterTo='transform scale-100 opacity-100'
                    leave='transition duration-75 ease-out'
                    leaveFrom='transform scale-100 opacity-100'
                    leaveTo='transform scale-95 opacity-0'
                    as={Fragment}
                >
                    <Dialog onClose={handleToggleModal} aria-labelledby="modal-detail">
                        <DialogPanel>
                            <div className='fixed bottom-0 left-0 right-0 top-0 z-[100]'>
                                <div className='absolute inset-0 bg-[rgba(27,27,27,0.48)]'></div>
                                <div className='absolute inset-0 overflow-auto flex flex-col justify-center items-center px-5'>
                                    <div className='relative z-[1] max-w-full p-4 flex flex-col gap-4 w-[1000px] transform rounded-3xl bg-[#FFF] h-[90%]'>
                                        <div className='flex justify-between items-center border-b border-[rgba(0,0,0,0.1)] pb-2'>
                                            <div id="modal-detail" className='text-[20px] font-semibold flex items-stretch gap-2'>
                                                {t("detail_button")}
                                                <button onClick={handleNavigateDetail} className='text-sm italic text-primary underline'>Xem chi tiết</button>
                                            </div>
                                            <button onClick={handleToggleModal} aria-label="Close">
                                                <X size={20} />
                                            </button>
                                        </div>
                                        <div className='flex-1 overflow-auto'>
                                            <DetailForm id={idForm} vid={vid} resModel={resModel} context={context} setActionID={setActionID} actionID={actionID} setActionData={setActionData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </Dialog>
                </Transition>
            }
        </>
    );
};


export default ModalDetail
