import { callPath } from '../../../constant/endpoint'
import requests from '../AxiosClient'

const KanbanServices = {
  getProgressBar: async ({ field, color, resModel, width_context }) => {
    const jsonDataView = {
      model: resModel,
      method: "read_progress_bar",
      kwargs: {
        domain: [],
        group_by: "stage_id",
        progress_bar: {
          colors: color,
          field: field
        }
      },
      width_context: width_context

    }
    return requests.post(callPath, jsonDataView, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getGroup: async ({ resModel, width_context }) => {
    const jsonDataView = {

      model: resModel,

      method: "web_read_group",

      kwargs: {

        domain: [
          [

            "stage_id.fold",

            "=",

            false

          ]

        ],

        fields: ["color:sum"],

        groupby: [

          "stage_id"

        ]

      },
      width_context: width_context

    }
    return requests.post(callPath, jsonDataView, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  getListData: async ({ resModel, specification, domain, width_context }) => {
    const jsonDataView = {

      model: resModel,
      method: "web_search_read",
      ids: [],
      kwargs: {
        domain: domain,
        specification: specification
      },
      width_context: width_context,
      offset: 0,
      order: "",
      limit: 80,
      count_limit: 10001
    }
    return requests.post(callPath, jsonDataView, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

export default KanbanServices
