import React from 'react'
import { useFormContext } from 'react-hook-form'
import RenderWidget from '../widget/RenderWidget'
import PropTypes from 'prop-types'
import useFormStore from '../../store/form'
import useField from '../../hooks/useField'
import useHeaderStore from '../../store/header'
import { WIDGETNOSTRING } from '../../constant/widget-type'
import { useSearchParams } from 'react-router-dom'

const FieldView = (props) => {
  const [searchParams] = useSearchParams()
  const idForm = searchParams.get('id')
  const { setIsShowModalTranslate, setFieldTranslate, isShowingModalDetail } = useFormStore()
  const methods = useFormContext()
  const { lang } = useHeaderStore()
  const { name, string, widget, onchangeData, isForm, defaultValue, tabIndex, help, type, icon_cmp, specification, contextAction, id, state, setOnchangeData, refetch, translate } = props
  const { invisible, required, readonly, nameField } = useField(props)
  const amountResidual = props?.name === 'amount_residual'
  const amountTaxTotal = widget === "account-tax-totals-field"
  const payment = widget === "payment"
  const stateSelection = widget === "state_selection"
  const remainingDays = widget === "remaining_days"
  const sequence = widget === "handle"

  const inputElement = RenderWidget({
    ...props,
    lang: lang,
    required: required,
    readonly: isShowingModalDetail || readonly,
    invisible: invisible,
    name: nameField || name,
    methods: methods,
    value: onchangeData?.[name],
    isForm: isForm,
    defaultValue: defaultValue,
    isForm,
    specification: specification,
    resModal: props.model,
    contextAction: contextAction,
    id: id,
    state: state,
    setOnchangeData: setOnchangeData,
    refetch: refetch
  })

  if ((isForm && (invisible || inputElement === null)) || invisible) return null
  if (payment || amountResidual || sequence) return null
  if (stateSelection || remainingDays) return inputElement

  return (
    (type === "boolean" || type === "many2many") && help && isForm ? (
      <div className={`${isForm && string ? 'min-h-[80px]' : 'min-h-max'} col-span-2 flex flex-col gap-2`}>
        <div className='flex gap-2 items-start'>
          <div className='min-w-5'>{inputElement}</div>
          <div className='flex flex-col ps-2 border-l'>
            {!amountTaxTotal && isForm && string && tabIndex < 0 && (
              <div className='flex h-5 gap-[1px]'>
                {icon_cmp ? "" : <span className='text-sm font-medium text-dark'>{string}</span>}
                {required && (
                  <span style={{ verticalAlign: 'super' }} className='text-sm text-[#DD441A]'>
                    *
                  </span>
                )}
              </div>
            )}
            {help && <p className={`text-[rgba(73,80,87,0.76)]`}>{help}</p>}
          </div>
        </div>

      </div>
    ) : (
      <div className={`${isForm && string && !WIDGETNOSTRING[name || nameField] ? 'min-h-[100px]' : 'min-h-max'} ${WIDGETNOSTRING[name || nameField] ? "" : "col-span-2 w-full"} flex ${icon_cmp ? "field-warning" : "flex-col"} gap-2`}>
        {icon_cmp && <i className={`fa ${icon_cmp}`}></i>}
        {!amountTaxTotal && isForm && string && tabIndex < 0 && (
          !icon_cmp && !WIDGETNOSTRING[name || nameField] && <div className='flex h-5 gap-[1px]'>
            <span className='text-sm font-medium text-dark'>{string}</span>
            {required && (
              <span style={{ verticalAlign: 'super' }} className='text-sm text-[#DD441A]'>
                *
              </span>
            )}
          </div>
        )}
        {help && isForm && <p className={`text-[rgba(73,80,87,0.76)]`}>{help}</p>}
        <div className='w-full relative group/field'>
          {inputElement}
          {
            idForm && translate && isForm && methods?.getValues(name) &&
            <button
              onClick={(e) => {
                e.stopPropagation()
                setIsShowModalTranslate(true)
                setFieldTranslate({
                  name: nameField || name,
                  string: string
                })
              }}
              type='button'
              className='hidden group-hover/field:block absolute top-1/2 -translate-y-1/2 right-2 font-medium text-sm uppercase'>
              {lang?.split("_")[0]}
            </button>
          }
        </div>
      </div>
    )
  )
}

FieldView.propTypes = {
  name: PropTypes.string,
  invisible: PropTypes.arrayOf(PropTypes.string),
  string: PropTypes.string,
  value: PropTypes.any,
  widget: PropTypes.string,
  onchangeData: PropTypes.object,
  relation: PropTypes.string,
  index: PropTypes.number,
  rootField: PropTypes.object,
  isForm: PropTypes.bool,
}

export default FieldView
