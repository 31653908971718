import React, { useState } from 'react';
import { copyTextToClipboard } from '../../util/util';
import { FaRegCopy, FaCheck } from 'react-icons/fa';

const CopyClipboardButton = ({ value, string, }) => {
    const [isCopied, setIsCopied] = useState(false);

    const handleCopyToClipboard = async () => {
        await copyTextToClipboard(value);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Revert icon after 2 seconds
    };

    return (
        <button type='button' onClick={handleCopyToClipboard} className='button-primary flex items-center gap-2 py-2 px-4 w-fit' >
            {isCopied ? <FaCheck /> : <FaRegCopy />}
            {string}
        </button>
    );
};

export default CopyClipboardButton;