import React from 'react';
import TreeViewForm from '../form/view/tree-view-form';
import { useQuery } from '@tanstack/react-query';
import useHeaderStore from '../../store/header';
import { useSearchParams } from 'react-router-dom';
import ViewServices from '../../core/services/view-service/view-service';

const One2ManyTable = (props) => {
    const { relation, onchangeData, setOnchangeData, handleOnchange } = props
    const [searchParams] = useSearchParams()
    const vid = parseInt(searchParams.get('vid'))
    const { lang, rootContext } = useHeaderStore()

    const { data: viewResponse } = useQuery({
        queryKey: [`view-${relation}-${vid}`, lang],
        queryFn: () => ViewServices.getFieldView({
            resModel: relation,
            views: [[false, "list"]],
            context: rootContext,
            id: false
        }),
        refetchOnWindowFocus: false,
    })

    const tab = { ...props, fields: viewResponse?.views?.list?.fields }

    return (
        <div className='mb-4'>
            <TreeViewForm
                onchangeData={onchangeData}
                tab={tab}
                viewData={viewResponse}
                resModel={relation}
                setOnchangeData={setOnchangeData}
                isForm={true}
                handleOnchange={handleOnchange}
            />
        </div>
    )
}

export default One2ManyTable
