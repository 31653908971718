import { useEffect, useState } from "react";
import { matchDomains } from "../../../core/domain/domain";
import useHeaderStore from "../../../store/header";
import { evalJSONContext, filterFieldDirty, removeUndefinedFields } from "../../../util/util";
import ModalDynamic from "../../modal/ModalDynamic";
import useActionHandler from "../../../hooks/useActionButton";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import ActionService from "../../../core/services/actions";
import ModelService from "../../../core/services/model-service/model-service";
import ModalLayer from "../../modal/ModalLayer";
import { CustomToastMessage } from "../../../util/toast/customToastMsg";
import { useNavigate, useSearchParams } from "react-router-dom";
import useFormStore from "../../../store/form";
import { useFormContext } from "react-hook-form";

const ButtonView = ({ viewData, action, context, resModel, dataForm, refetch, handleOnChange, formSpecification, isButtonInside = false, handleCloseActions, isInForm }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams()
  const vid = searchParams.get('vid')
  const model = searchParams.get("model")
  const id = parseInt(searchParams.get("id"))
  const { rootContext } = useHeaderStore();
  const { isShowingModalDetail } = useFormStore()
  const contextAction = action?.context && evalJSONContext(action?.context, context);
  const [isHidden, setHidden] = useState(false);
  const [isOpenSaveConfirm, setOpenSaveConfirm] = useState(false);
  const navigate = useNavigate()
  const methods = useFormContext()
  const { dirtyFields } = methods.formState
  const formValues = methods.watch()

  const { loading, resultLoadAction, setModalAction, handleButtonAction, modalAction } = useActionHandler({
    context,
    resModel,
    dataForm,
    refetch,
  });

  const { mutateAsync: callButtonMutate, isPending: loadingCallButton } = useMutation({
    mutationFn: ({ method, resModel, ids, context }) => ActionService.callButton({
      method: method,
      resModel: resModel,
      ids: ids,
      context: context
    }),
    onSuccess: (data) => {
      if (isButtonInside) {
        handleResultButtonInside(data);
      } else {
        handleResultButtonOutside(data);
      }
    },
    onError: () => {
      CustomToastMessage.error(`${action?.string} ${t('failure')}`);
    }
  });

  const { mutateAsync: webSaveMutate, isPending: loadingWebSave } = useMutation({
    mutationFn: ({ resModel, ids, data, context }) => {
      const dataForm = ModelService.parseORMOdoo(
        filterFieldDirty(ids, viewData, data, dirtyFields, resModel, formValues)
      )

      return ModelService.saveForm({
        ids: ids ? [ids] : [],
        data: dataForm,
        resModel: resModel,
        context: context
      })
    },
    onSuccess: async (data) => {
      await callButtonMutate({
        method: action?.name,
        resModel: resModel,
        ids: data?.[0]?.id ? [data?.[0]?.id] : data?.id ? [data?.id] : [],
        context: {
          ...removeUndefinedFields(context),
        }
      });
      if (!id) {
        searchParams.set("id", data?.[0]?.id || data?.id)
        setSearchParams(searchParams)
        // updateQueryParams("id", data?.[0]?.id || data?.id)
      }
    },
    onError: () => {
      CustomToastMessage.error(`${action?.string} ${t('failure')}`);
    }
  });

  const handleConfirm = async () => {
    try {
      if (action?.["confirm-label"] === "Switch" && !id) {
        // await webSaveMutate({
        //   method: action?.name,
        //   resModel: resModel,
        //   data: dataForm,
        //   ids: id,
        //   context: {
        //     ...removeUndefinedFields(context),
        //   }
        // });
        methods.handleSubmit(async () => {
          await webSaveMutate({
            method: action?.name,
            resModel: resModel,
            data: dataForm,
            ids: id,
            context: {
              ...removeUndefinedFields(context),
            }
          });
          refetch && refetch();
          CustomToastMessage.success(`${action?.string} ${t('success')}`);
        })()
        return
      } else {
        await callButtonMutate({
          method: action?.name,
          resModel: resModel,
          ids: dataForm?.id ? dataForm?.id : [],
          context: {
            ...removeUndefinedFields(context),
          }
        });
        refetch && refetch();
      }
      CustomToastMessage.success(`${action?.string} ${t('success')}`);
    } catch (error) {
      console.log(error);
      CustomToastMessage.error(`${action?.string} ${t('failure')}`);
    }
  }

  const handleNavigateList = () => {
    if (vid) {
      navigate(`/list?vid=${vid}&model=${model}`)
    } else {
      navigate(-1)
    }
  }

  const handleResultButtonOutside = (data) => {
    if (action?.name === 'button_cancel') {
      return handleConfirm();
    } else if (Object.prototype.hasOwnProperty.call(data, 'data') && !data?.data) {
      return
      // return setOpenSaveConfirm(true)
    }
    refetch && refetch();
    if ((action?.name === 'action_unlink' || action?.name === "action_archive") && isInForm) {
      handleNavigateList()
    }
    resultLoadAction[data?.type]({ actionId: dataForm?.id, actionResult: data, formValues: context, url: data?.url });
  }

  const handleResultButtonInside = (data) => {
    handleCloseActions()
    CustomToastMessage.success(`${action?.string} ${t('success')}`);
    if ((action?.name === 'action_unlink' || action?.name === "action_archive") && isInForm) {
      handleNavigateList()
    }
  }

  const handleButtonObject = async () => {
    // if (action?.confirm) {
    //   setModalAction(prev => ({ ...prev, status: true }));
    //   return;
    // }

    if (isButtonInside) {
      try {
        if (isInForm && action?.name !== "action_unlink" && !dataForm?.id) {
          await webSaveMutate({
            method: action?.name,
            resModel: resModel,
            data: dataForm,
            ids: id,
            context: {
              ...removeUndefinedFields(context),
            }
          });
        } else {
          await callButtonMutate({
            method: action?.name,
            resModel: resModel,
            ids: dataForm?.id ? [dataForm?.id] : [],
            context: {
              ...removeUndefinedFields(context),
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await callButtonMutate({
          method: action?.name,
          resModel: resModel,
          ids: dataForm?.id ? [dataForm?.id] : [],
          context: {
            ...context,
            active_id: dataForm?.id,
            active_ids: [dataForm?.id],
            active_model: resModel
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleButton = async (action) => {
    if (action?.confirm) {
      setOpenSaveConfirm(true)
    } else if (action?.special === "cancel") {
      handleCloseActions()
    } else if (action?.type === 'object') {
      handleButtonObject();
    } else if (action?.type === 'action') {
      handleButtonAction(action?.id);
    }
  };

  useEffect(() => {
    if (action?.invisible) {
      setHidden(matchDomains({ ...context, ...rootContext, ...contextAction }, action.invisible));
    }
  }, [context]);

  const disabled = loading || loadingWebSave || loadingCallButton || isShowingModalDetail;

  return (
    <>
      <button
        type="button"
        disabled={disabled}
        onClick={() => handleButton(action)}
        className={`transition-opacity  m-2 ${action?.class?.includes('oe_highlight') || action?.class?.includes('btn-primary') ? 'button-primary' : 'button-secondary'} ${isHidden && 'hidden'} ${disabled && 'cursor-not-allowed opacity-30'}`}
      >
        {action?.string}
      </button>

      {modalAction?.status && <ModalDynamic {...modalAction} handleCloseActions={handleCloseActions} handleOnchange={handleOnChange} formSpecification={formSpecification} />}

      <ModalLayer isOpen={isOpenSaveConfirm} onClose={() => setOpenSaveConfirm(false)}>
        <div className='mx-auto flex flex-col items-center justify-center gap-4'>
          <p className='text-[16px]'>{action?.confirm || `${t("Bạn có muốn")} ${action?.string}?`}</p>
          <div className='flex justify-center items-center gap-2 w-full'>
            <button type='button' onClick={() => setOpenSaveConfirm(false)} className='button-secondary w-[25%]'>{t("cancel")}</button>
            <button
              type='button'
              onClick={() => {
                handleConfirm()
                setOpenSaveConfirm(false)
              }}
              className='button-primary  w-[25%]'
            >
              {t("confirm_button")}
            </button>
          </div>
        </div>
      </ModalLayer>
    </>
  );
};

export default ButtonView;