import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import Select from 'react-select'
import ViewServices from '../../core/services/view-service/view-service'
import { evalJSONContext, evalJSONDomain } from '../../util/util'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import InfoWidget, { Avatar } from './Info'
import useHeaderStore from '../../store/header'
import useSidebar from '../../store/sidebar'
import { X } from 'react-feather'
import { WIDGETAVATAR, WIDGETCOLOR } from '../../constant/widget-type'
import { COLORS } from '../../constant/color-type'

const CustomMultiValue = (props) => {
  const { selectProps, data } = props
  const { relation } = selectProps
  const { menuList } = useSidebar()

  const actionId = menuList?.flatMap(item =>
    item?.child_id.filter(childItem =>
      childItem?.is_display && childItem?.action?.res_model === relation
    )
  )?.[0]?.action?.id;

  return (
    <div className='flex items-center gap-2 relative mr-2 group'>
      <Avatar id={data?.id} fullName={data?.label ?? data?.display_name} relation={relation} isForm={true} vid={actionId?.id} avatarSrc={data?.image_256} />
      <span className="capitalize">{data?.label ?? data?.display_name}</span>
      <span
        className='absolute -top-1 -right-1 font-medium bg-white rounded-full !shadow-lg hidden group-hover:block'
        onClick={(e) => {
          e.stopPropagation();
          props.removeProps.onClick();
        }}
      >
        <X size={12} />
      </span>
    </div>
  );
};

const Many2ManyTag = ({
  relation,
  value,
  name,
  methods,
  readonly,
  placeholder,
  required,
  invisible,
  defaultValue,
  isForm,
  handleOnchange,
  domain,
  lang,
  string,
  options: optionsFields,
  widget,
}) => {
  const { t } = useTranslation()
  const { rootContext } = useHeaderStore()
  const addtionalFields = optionsFields ? evalJSONContext(optionsFields) : null

  const domainObject = evalJSONDomain(domain, methods?.getValues() || {})
  const { data: dataOfSelection } = useQuery({
    queryKey: [`data_${relation}`, domainObject, lang],
    queryFn: () => ViewServices.getSelectionItem({
      model: relation,
      domain: domainObject,
      context: rootContext,
      specification: {
        id: {},
        name: {},
        display_name: {},
        ...(WIDGETAVATAR[widget] ? { image_256: {} } : {}),
        ...(WIDGETCOLOR[widget] && addtionalFields?.color_field ? { color: {} } : {}),
      }
    }),
    refetchOnWindowFocus: false
  }
  )

  const customNoOptionsMessage = () => t("no_option")
  const tranfer = (data) => {
    return data?.map((val) => ({
      id: val.value,
      display_name: val.label,
    })) || []
  }

  const options =
    dataOfSelection?.records?.map((val) => ({
      value: val.id,
      label: val.name ?? val.display_name,
      ...val
    })) || []

  if (!isForm) {
    const optionValue = value && value?.map(item => ({ ...item, value: item.id, label: item.name ?? item.display_name }))
    return <InfoWidget value={optionValue || null} isForm={isForm} />
  } else {
    const isUser = relation === "res.users" || relation === "res.partner"
    return (
      <Controller
        name={name}
        control={methods.control}
        rules={{
          required: (required && !invisible) ? { value: true, message: `${string} ${t("must_required")}` } : false,
        }}
        render={({ field, fieldState: { error } }) => {
          const { clearErrors } = methods;
          useEffect(() => {
            if (field.value) {
              clearErrors(name);
            }
          }, [field.value])

          return (
            <>
              <Select
                relation={relation}
                key={field?.value}
                options={options}
                noOptionsMessage={customNoOptionsMessage}
                isMulti
                value={options ? options?.filter((option) => (field.value ??= [])?.some(item => (item?.id) === option.value)) : null}
                onChange={(selectedOptions) => {
                  field.onChange(tranfer(selectedOptions))
                  handleOnchange(name, tranfer(selectedOptions))
                  clearErrors(name)
                }}
                classNames={{
                  valueContainer: () => "overflow-unset !px-0",
                  control: () => isForm
                    ? `field h-auto !py-1 ${isUser ? "" : "custom-select"}`
                    : "field-in-tree custom-select",
                }}
                styles={{
                  multiValue: (provided, state) => {
                    const { color, id } = state?.data
                    const bgColor = color !== null ? COLORS[color]?.color : COLORS[id]?.color
                    return {
                      ...provided,
                      display: "flex",
                      backgroundColor: bgColor || "RGBA(230.1375, 221.3625, 221.3625, 1)",
                      padding: "2px",
                      borderRadius: "16px",
                      color: "black",
                      fontWeight: "600"
                    }
                  },
                  multiValueRemove: (provided) => ({
                    ...provided,
                    backgroundColor: "transparent !important",
                    color: "black !important"
                  }),
                  option: (provided, { isFocused, isSelected }) => ({
                    ...provided,
                    backgroundColor: isSelected ? "#007bff" : isFocused ? "#f1f1f1" : "white",
                    color: isSelected ? "white" : "",
                    padding: "10px",
                    cursor: "pointer",
                    transition: "all 0.2s ease",
                  }),
                }}
                // isDisabled={readonly}
                components={isUser ? { MultiValue: CustomMultiValue } : {}}
                placeholder={t("choose_place")}
                required={!invisible && required}
              />
              {error && <p className="text-[#de4747] text-sm mt-1">{error.message}</p>}
            </>
          )
        }}
      />
    )
  }
}

export default Many2ManyTag
