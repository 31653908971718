import React, { useCallback, useMemo, useState } from 'react'
import TableHead from './table-head'
import TableBody from './table-body'
import TableFooter from './table-footer'
import ResizableHeader from './ResizableHeader'
import TableFilter from './table-filter'
import { COMPONENT_TYPE } from '../../../constant/componen-type'
import {
  closestCenter,
  DndContext, DragOverlay, KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core"
import { restrictToVerticalAxis } from "@dnd-kit/modifiers"
import {
  arrayMove
} from "@dnd-kit/sortable"
import { useTable } from "react-table"
import { StaticTableRow } from './dragdrop/StaticTableRow'
import { createPortal } from 'react-dom'
import { getOffSet, resequence } from '../../../util/util'
import ModelService from '../../../core/services/model-service/model-service'

const TableView = ({
  dataSource,
  columns,
  visibleOptionalFields,
  model,
  isForm = false,
  funcAddLine,
  rootField,
  dataResponseLength,
  funcRemoveLine,
  funcRefetch,
  handleOnchange,
  viewData,
  resModel,
  tabIndex,
  type,
  append,
  typeTable,
  specification,
  showCheckBox = true,
  typeCo,
  showFooterTable = false,
  funcChoose,
  context,
  setOrder,
  order,
  setDataSource,
  setOnchangeData
}) => {
  if (!columns || !dataSource) return
  const initialColumnWidths = useMemo(() => columns?.map(() => 50), [columns])
  const [columnWidths, setColumnWidths] = useState(initialColumnWidths)

  const columnsFormat = useMemo(() => {
    return columns?.map((col, index) => {
      return {
        ...col,
        id: index,
        Header: col?.title,
        accessor: col?.key
      }
    }
    )
  }, [])

  const [activeId, setActiveId] = useState();
  const items = useMemo(() => dataSource?.map(({ sequence, id }) => id), [dataSource]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columnsFormat,
    data: dataSource,
  })
  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  }


  const handleDragEnd = async (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      setDataSource((data) => {
        return arrayMove(data, oldIndex, newIndex);
      });

      if (typeCo !== COMPONENT_TYPE.TREE) {
        return
      }

      const resIds = resequence(items, oldIndex, newIndex)

      const offset = getOffSet(items, oldIndex, newIndex)

      await ModelService.resequence({
        model: model,
        ids: resIds,
        context: context,
        offset: offset
      })
    }

    setActiveId(null);
  }

  const handleDragCancel = () => {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }

    const row = rows.find(({ original }) => original.id === activeId);

    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  // useEffect(() => {
  //   setColumnWidths(initialColumnWidths)
  // }, [columns, initialColumnWidths])

  const handleResize = useCallback(
    (index) =>
      (e, { size }) => {
        setColumnWidths((prev) => {
          const newWidths = [...prev]
          newWidths[index] = size.width
          return newWidths
        })
      },
    []
  )
  const memoizedColumnWidths = useMemo(() => columnWidths, [columnWidths])

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <div className={`card relative w-full ${isForm ? "!border-none" : "overflow-hidden"}`}>
          <div className='card-body flex flex-col gap-[16px] w-full'>
            <div className={`relative w-full `}>
              <div className={`flex w-full items-center ${isForm ? "" : "overflow-x-auto"} `}>
                <div className='w-full max-w-full'>
                  {memoizedColumnWidths && (
                    <>
                      <table {...getTableProps()} className='relative w-full bg-white custom-dropdown'>
                        <TableHead
                          headerGroups={headerGroups}
                          model={model}
                          isForm={isForm}
                          columns={columns}
                          dataSource={dataSource}
                          visibleOptionalFields={visibleOptionalFields}
                          columnWidths={memoizedColumnWidths}
                          handleResize={handleResize}
                          ResizableHeader={ResizableHeader}
                          tabIndex={tabIndex}
                          type={type}
                          showCheckBox={showCheckBox}
                          typeCo={typeCo}
                          setOrder={setOrder}
                          order={order}
                        />
                        <TableBody
                          items={items}
                          prepareRow={prepareRow}
                          viewData={viewData}
                          resModel={resModel}
                          model={model}
                          columns={columns}
                          isForm={isForm}
                          dataSource={rows}
                          rootField={rootField}
                          dataResponseLength={dataResponseLength}
                          columnWidths={memoizedColumnWidths}
                          funcRemoveLine={funcRemoveLine}
                          funcAddLine={funcAddLine}
                          handleOnchange={handleOnchange}
                          visibleOptionalFields={visibleOptionalFields}
                          append={append}
                          type={type}
                          typeTable={typeTable}
                          specification={specification}
                          level={0}
                          showCheckBox={showCheckBox}
                          typeCo={typeCo}
                          funcChoose={funcChoose}
                          context={context}
                          tabIndex={tabIndex}
                          setDataSource={setDataSource}
                          setOnchangeData={setOnchangeData}
                        />
                        {/* {isForm && typeCo !== COMPONENT_TYPE.TREE && dataResponseLength > 0 && <div className='h-[60px]' />} */}
                        {typeCo !== COMPONENT_TYPE.TREE && showFooterTable && <TableFooter dataResponseLength={dataResponseLength} columns={columns} funcAddLine={funcAddLine} />}
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <TableFilter model={model} tabIndex={tabIndex} type={type} visibleOptionalFields={visibleOptionalFields} />
        {createPortal(
          <DragOverlay>
            {activeId && (
              <table style={{ width: "100%", height: "60px" }}>
                <StaticTableRow
                  row={selectedRow}
                  items={items}
                  prepareRow={prepareRow}
                  viewData={viewData}
                  resModel={resModel}
                  model={model}
                  columns={columns}
                  isForm={isForm}
                  dataSource={rows}
                  rootField={rootField}
                  dataResponseLength={dataResponseLength}
                  columnWidths={memoizedColumnWidths}
                  funcRemoveLine={funcRemoveLine}
                  funcAddLine={funcAddLine}
                  handleOnchange={handleOnchange}
                  visibleOptionalFields={visibleOptionalFields}
                  append={append}
                  type={type}
                  typeTable={typeTable}
                  specification={specification}
                  level={0}
                  showCheckBox={showCheckBox}
                  typeCo={typeCo}
                  funcChoose={funcChoose}
                  context={context}
                  tabIndex={tabIndex}
                  setDataSource={setDataSource}
                />
              </table>
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    </>
  )
}

export default TableView
