import React, { useState } from 'react';
import { convertFloatToTime, convertTimeToFloat } from '../../util/util';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';

const FloatTime = ({ name, control, defaultValue = 0, isForm, value, required, string, handleOnchange,readonly }) => {
    const { t } = useTranslation()

    if (!isForm) {
        const formatValue = value ?? 0
        return <span>{convertFloatToTime(formatValue)}</span>
    }

    return (
        <div className="relative field !min-w-[150px]">
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={{
                    required: required ? { value: true, message: `${string} ${t("must_required")}` } : false,
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
                    const [input, setInput] = useState(convertFloatToTime(value));
                    const [formattedTime, setFormattedTime] = useState('');
                    const [errors, setErrors] = useState('');

                    const formatTime = (input) => {
                        if (!input || input.trim() === '') {
                            return null;
                        }
                        // Handle case when input is just ":minutes" (e.g., ":03")
                        if (/^:\d+$/.test(input)) {
                            const minutes = parseInt(input.substring(1), 10);
                            if (minutes >= 60) {
                                const hours = Math.floor(minutes / 60);
                                const remainingMinutes = minutes % 60;
                                return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
                            }
                            return `00:${minutes.toString().padStart(2, '0')}`;
                        }

                        if (/^\d+$/.test(input)) {
                            return `${input}:00`;
                        }
                        let [hours, minutes] = input.split(':').map(num => parseInt(num, 10));

                        if (isNaN(hours)) {
                            return null;
                        } else if (isNaN(minutes)) {
                            return `${hours}:00`
                        }

                        if (minutes >= 60) {
                            const additionalHours = Math.floor(minutes / 60);
                            hours += additionalHours;
                            minutes = minutes % 60;
                        }
                        return `${hours}:${minutes.toString().padStart(2, '0')}`;
                    };


                    const handleInputChange = (e) => {
                        const value = e.target.value;
                        setInput(value);
                        // Kiểm tra format
                        const timeRegex = /^[\d:]*$/;
                        if (!timeRegex.test(value) && value !== '') {
                            setErrors('Vui lòng nhập định dạng hợp lệ');
                            setFormattedTime('');
                            return;
                        }
                        const formatted = formatTime(value);
                        if (formatted === null && value !== '') {
                            setErrors('Thời gian không hợp lệ');
                            setFormattedTime('');
                        } else {
                            setErrors('');
                            setFormattedTime(formatted || '');
                        }
                    };
                    const handleBlur = () => {
                        if (formattedTime && !errors) {
                            setInput(formattedTime)
                            const floatVal = convertTimeToFloat(formattedTime)

                            onChange(floatVal)
                            handleOnchange(name, floatVal)
                        } else if (input == '') {
                            setInput(convertFloatToTime(value))
                        }
                    }
                    return (
                        <>
                            <input
                                type="text"
                                value={input}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                className={`w-full bg-transparent rounded-md`}
                                placeholder="00:00"
                                readOnly={readonly}
                            />
                            {error && (
                                <div className="text-red-500 text-sm">
                                    {errors}
                                </div>
                            )}
                        </>
                    )
                }}
            />

        </div>
    );
};

export default FloatTime;
