import { callPath } from '../../../constant/endpoint'
import requests from '../AxiosClient'

const TranslateService = {
    getInstalledLang: async ({ context }) => {
        const jsonData = {
            model: "res.lang",
            method: 'get_installed',
            with_context: context,
            args: [],
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    getFieldTranslations: async ({ model, context, field, id }) => {
        const jsonData = {
            model: model,
            method: 'get_field_translations',
            with_context: context,
            ids: [id],
            args: field,
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
    updateFieldTranslations: async ({ model, context, field, value, id }) => {
        const jsonData = {
            model: model,
            method: 'update_field_translations',
            with_context: context,
            ids: [id],
            args: [
                field,
                value
            ],
        }
        return requests.post(callPath, jsonData, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
    },
}

export default TranslateService
