import { useState, useRef } from 'react';

const VideoPlayer = ({ index, src, filename, handleFileDownload, handleRemoveFile }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

    const handleFullScreen = () => {
        if (videoRef.current) {
            if (videoRef.current.requestFullscreen) {
                videoRef.current.requestFullscreen();
            } else if (videoRef.current.webkitRequestFullscreen) { // Safari
                videoRef.current.webkitRequestFullscreen();
            } else if (videoRef.current.msRequestFullscreen) { // IE11
                videoRef.current.msRequestFullscreen();
            }
        }
    };

    return (
        <div className="relative flex aspect-square h-[120px] w-[120px] items-center justify-center overflow-hidden rounded-lg max-sm:h-auto max-sm:w-full">
            <video
                ref={videoRef}
                src={src}
                className="w-full rounded-lg"
                onClick={handlePlayPause}
                width={300}
                height={300}
            >
                Your browser does not support the video tag.
            </video>

            <div className="absolute inset-0 flex items-center justify-center bg-black/50 p-2">
            </div>
            <div className='absolute left-[50%] top-[50%] flex h-8 w-8 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-[#FFFFFF33] p-[6px]'>
                <button
                    type='button'
                    onClick={handleFullScreen}
                >
                    <img src="/assets/img/avatar/playIcon.svg" alt="avatar" className="object-cover" />
                </button>
            </div>
        </div>
    );
};
export default VideoPlayer