import React, { useEffect } from 'react'
import useHeaderStore from '../../../store/header'
import ViewServices from '../../../core/services/view-service/view-service'
import { useQuery } from '@tanstack/react-query'
import Form from '../../../pages/form-view/form'

const DetailForm = ({ id, vid, resModel, context, setActionID, actionID, setActionData }) => {
    const { lang } = useHeaderStore()

    const { data: actionReponse } = useQuery({
        queryKey: [`get-form-view-${resModel}-${lang}`, id, resModel, lang],
        queryFn: () => ViewServices.getFormView({ id: id, model: resModel, context: context }),
        refetchOnWindowFocus: false,
    })

    const actionData = actionReponse

    const { data: viewResponse } = useQuery({
        queryKey: [`view-${resModel}`, actionData?.id, lang],
        queryFn: () => ViewServices.getFieldView({
            resModel: actionData?.res_model,
            views: [...actionData?.views, [actionData?.search_view_id ?? false, 'search']],
            context: context
        }),
        enabled: !!actionData,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (actionReponse) {
            if (actionReponse?.action_id) {
                setActionID(actionReponse?.action_id)
            }
            setActionData(actionReponse)

        }
    }, [actionReponse])

    return (
        <Form resModel={resModel} actionData={actionData} viewData={viewResponse} id={id} isDisplayBreadcrumbs={false} vid={actionID ?? vid} />
    )
}

export default DetailForm
