import React, { memo } from 'react';

const OptionDuration = memo(({ id, label, isActive, isFirst, isLast, handleClick, disabled }) => {
  return (
    <label
      onClick={() => !disabled && handleClick(id)}
      className={`relative flex-shrink-0 py-[10px] px-8 font-semibold cursor-pointer bg-[#dee2e6] hover:bg-[#ced4da] group
        ${isActive ? 'bg-primary text-white' : 'bg-[#F1F6F1] text-[#121212]'}
        ${isFirst ? 'rounded-l-xl ' : 'rounded-none'} 
        ${isLast ? 'rounded-r-xl' : 'rounded-none'}
      `}
    >
      <input
        type='radio'
        checked={isActive}
        className='hidden'
        disabled
      />
      {label}
      {!isLast && (
        <div
          className={`absolute top-[5px] bg-[#dee2e6] z-[2] -right-4 w-[30px] h-[30px] border-t-4 border-r-4 border-white transform rotate-45 group-hover:bg-[#ced4da]
            ${isActive ? 'bg-primary' : 'bg-[#F1F6F1]'}
          `}
        ></div>
      )}
    </label>
  );
});

export default OptionDuration;