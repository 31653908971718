import React from 'react'
import { Download } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { downloadFile } from '../../util/util'

const BinaryFieldDownload = (props) => {

    const { value, defaultValue, onchangeData } = props
    const { t } = useTranslation()

    const handleFileDownload = async (e) => {
        e.stopPropagation()
        await downloadFile(value || defaultValue, onchangeData?.name)
    }

    return (
        <button type='button' onClick={handleFileDownload} className='flex items-center gap-1 text-blue-700'>
            <Download size={18} />
            <span className='text-sm italic underline'>{t('download_file')}</span>
        </button>
    )
}

export default BinaryFieldDownload
