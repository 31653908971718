import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../constant/color-type";

const ColorPicker = (props) => {
    const { savePickColor, defaultColor } = props
    const { t } = useTranslation()
    const [selectedColor, setSelectedColor] = useState(COLORS[defaultColor]);
    const [showFullColors, setIsShowFullColor] = useState(false)

    useEffect(() => {
        setSelectedColor(COLORS[defaultColor])
    }, [defaultColor])

    const handleShowFullColors = () => {
        setIsShowFullColor(!showFullColors)
    }

    const pickColorsRef = useClickOutside(() => {
        setIsShowFullColor(false)
    })

    return (
        <div ref={pickColorsRef}>
            {
                showFullColors ?
                    <div className="flex gap-2">
                        {COLORS.map((color) => (
                            <>
                                <button
                                    data-tooltip-id={`${color?.color}${color?.id}`}
                                    className={`w-5 h-[17px] cursor-pointer  ${selectedColor?.id === color?.id ? "shadow-[0_0_0_2px_#71639e]" : "shadow-[0_0_0_1px_#adb5bd]"}`}
                                    style={{ backgroundColor: color?.color }}
                                    key={color?.id}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setSelectedColor(color)
                                        handleShowFullColors()
                                        savePickColor(color)
                                    }}
                                />
                                <Tooltip className='' place="top" id={`${color?.color}${color?.id}`} content={t(color?.name)} />
                            </>
                        ))}
                    </div> :
                    <>
                        <button
                            data-tooltip-id={`${selectedColor?.id}${selectedColor?.name}`}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleShowFullColors()
                            }}
                            type="button"
                            className="w-5 h-[17px] shadow-[0_0_0_1px_#adb5bd] cursor-pointer"
                            style={{ backgroundColor: selectedColor?.color }}
                        />
                        <Tooltip place="top" id={`${selectedColor?.id}${selectedColor?.name}`} content={t(selectedColor?.name)} />
                    </>
            }
        </div>
    );
};

export default ColorPicker;
