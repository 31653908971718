import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
const ButtonLoading = ({ func, className, loading = false, content, disable, type = 'submit' }) => {
  const { t } = useTranslation()
  return (
    <>
      {!loading ? (
        <button type={type} className={`${className}`} disabled={disable} onClick={func}>
          {t(content)}
        </button>
      ) : (
        <button type={type} className={className} disabled>
          <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> {t('loading')}
        </button>
      )}
    </>
  )
}
ButtonLoading.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  content: PropTypes.string,
  disable: PropTypes.bool,
  func: PropTypes.func,
}

export default ButtonLoading
