import React from 'react'
import { Download } from 'react-feather'
import { FaRegTrashAlt } from 'react-icons/fa'
import { checkIsImageLink, downloadFile, formatFileSize, isBase64File } from '../../util/util'
import { useQuery } from '@tanstack/react-query'
import ChatterAction from '../../core/services/chatter'
import VideoPlayer from './VideoPlayer'

const ImageItem = ({ id, filename, checksum, index, handleRemoveFile, handleFileDownload, src = null }) => {
    const { data: image } = useQuery({
        queryKey: [`get-image-${''}`,],
        queryFn: () => ChatterAction.getImage({ filename, checksum }),
        refetchOnWindowFocus: false,
        enabled: !src && !isBase64File(src)
    })
    return (
        <div
            key={index}
            className="relative group group/file"
        >
            <img src={image || src} alt={filename} className="w-32 h-32 object-cover rounded-md" />
            {/* {!handleRemoveFile &&
                <span onClick={(e) => handleFileDownload(e, src, filename)} className='absolute top-0 right-0 z-10 hidden group-hover/file:block cursor-pointer p-2 text-white bg-[rgba(0,0,0,0.2)]'>
                    <Download size={22} />
                </span>
            } */}
        </div>
    )
}

const FileItem = (props) => {
    const { filename, index, handleRemoveFile, handleFileDownload, src, mimetype, size } = props

    const fileTypeIcon = {
        'application/pdf': "pdf.svg",
        'application/zip': "zip.svg",
        'application/x-zip-compressed': "zip.svg",
        'application/vnd.ms-excel': "excel.svg",
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': "excel.svg",
    }

    const renderFile = () => {
        if (mimetype?.includes("image") || mimetype?.includes("img") || checkIsImageLink(src)) {
            return <ImageItem {...props} />
        } else if (mimetype?.includes("video")) {
            return <VideoPlayer {...props} />
        } else {
            return <div className='flex bg-gray-200 items-center p-2 gap-2 overflow-hidden'>
                <img src={`/assets/img/icons/${fileTypeIcon[mimetype]}`} alt={filename} className="object-cover drop-shadow-xl" />
                <div>
                    <p className='text-[#0A0D14] font-medium'>{filename}</p>
                    <span className='text-[12px]'>{formatFileSize(size)}</span>
                </div>
            </div>
        }
    }

    return (
        <div
            key={index}
            className="flex relative items-center overflow-hidden justify-between group rounded-md w-fit group/file"
        >
            {renderFile()}
            {handleRemoveFile && <div className="cursor-pointer absolute right-0 top-0 p-2 hidden group-hover/file:block bg-[rgba(0,0,0,0.2)]">
                <FaRegTrashAlt
                    size={18}
                    color='white'
                    onClick={() => handleRemoveFile(index)}
                />
            </div>}
            {
                !handleRemoveFile && <span onClick={(e) => handleFileDownload(e, src, filename)} className='absolute top-0 right-0 z-10 hidden group-hover/file:block cursor-pointer p-2 text-white bg-[rgba(0,0,0,0.2)]'>
                    <Download size={18} />
                </span>
            }
        </div>
    )
}

const RenderFiles = ({ selectedFiles, setSelectedFiles }) => {
    const handleRemoveFile = (index) => {
        if (setSelectedFiles) {
            // setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
            const files = selectedFiles.filter((_, i) => i !== index)
            setSelectedFiles(files);
        }
    };

    const handleFileDownload = async (e, url, name) => {
        e.stopPropagation()
        await downloadFile(url, name)
    }

    return (
        <div className="mt-3 flex flex-wrap w-full gap-2">
            {selectedFiles.map((file, index) => {
                return <div className='relative group/file'>
                    <FileItem key={index} {...file} src={file?.datas} filename={file?.display_name ?? file?.name} index={index} handleRemoveFile={setSelectedFiles && handleRemoveFile} handleFileDownload={handleFileDownload} />
                </div>
            })}
        </div>
    )
}

export default RenderFiles
