import { useForm } from 'react-hook-form'
import { useEffect, useState, useContext } from 'react'
import { SidebarContext } from '../util/context/SidebarContext'
import UserService from '../core/services/auth'
import useHeaderStore from '../store/header'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import ModelService from '../core/services/model-service/model-service'
import useProfileStore from '../store/profile'
import { CustomToastMessage } from '../util/toast/customToastMsg'

const useUserSubmit = () => {
  const { setIsLoading } = useContext(SidebarContext)
  const { setRootContext, setLang, setUid } = useHeaderStore()
  const { setDataUser } = useProfileStore()
  const [colorPalette, setColorPalette] = useState('#ED1C24')

  const [profileUpdateTrigger, setProfileUpdateTrigger] = useState(0)
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false)
  const [imageSrc, setImageSrc] = useState()
  const [imageUpload, setImageUpload] = useState(false)
  const { i18n, t } = useTranslation()

  const { register, setValue, getValues, handleSubmit, reset } = useForm()

  const handleGetColor = (color) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'

    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      canvas.width = img.naturalWidth
      canvas.height = img.naturalHeight

      ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

      try {
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data

        let r = 0,
          g = 0,
          b = 0
        const pixelCount = imageData.length / 4

        for (let i = 0; i < imageData.length; i += 4) {
          r += imageData[i]
          g += imageData[i + 1]
          b += imageData[i + 2]
        }

        r = Math.floor(r / pixelCount)
        g = Math.floor(g / pixelCount)
        b = Math.floor(b / pixelCount)

        const dominantColor = `rgb(${[r, g, b].join(',')})`
        setColorPalette(dominantColor)
      } catch (e) {
        console.error('Failed to get color:', e)
        setColorPalette('#ED1C24')
      }
    }
    img.src = color
  }
  const onUpdateProfile = async (data) => {
    setIsUpdatingProfile(true)
    const userData = {
      model: 'res.users',
      domain: [['login', '=', `${data?.username}`]],
      values: {
        name: data?.name,
        email: data?.email,
      },
    }

    try {
      await UserService.updateProfile(userData)
      CustomToastMessage.success(t("profile_update_success"))
      getUserInfor()
    } catch (error) {
      CustomToastMessage.error(t("profile_update_failure"))
    } finally {
      setIsUpdatingProfile(false)
    }
  }

  const handleLanguageChange = (lang) => {
    const langLowerCase = String(lang).split("_")[0]
    Cookies.set('i18next', langLowerCase)
    i18n.changeLanguage(langLowerCase);
    setLang(lang);
    setRootContext({ lang: lang })
  };

  const onImageUpload = async (file) => {
    setIsUpdatingProfile(true)
    const formData = new FormData()
    formData.append('ufile', file)
    const queryParams = {
      model: 'res.users',
      id: 2,
      field: 'image_1920',
    }
    try {
      const data = await UserService.setImage(formData, queryParams)
      return data
    } catch (error) {
      console.log(error);
    }
  }

  const getUserInfor = async () => {
    try {
      const profileRes = await UserService.profile();
      if (profileRes) {
        setValue('email', profileRes?.email)
        setValue('username', profileRes?.username)
        setValue('name', profileRes?.name)
        setValue('sub', profileRes?.sub)
        setRootContext({ uid: profileRes?.sub })
        setUid(profileRes?.sub)
        handleLanguageChange(profileRes?.locale)
      }
      if (!profileRes?.sub) console.log("User profile missing 'sub'");

      const userPicture = await ModelService.getDetailData({
        model: "res.users",
        ids: [profileRes.sub],
        specification: { image_256: {} },
      });

      setImageSrc(userPicture?.[0]?.image_256)
      setValue('picture', userPicture?.[0]?.image_256)
      handleGetColor(userPicture?.[0]?.image_256)
      setDataUser({ ...profileRes, picture: userPicture?.[0]?.image_256 })
    } catch (error) {
      console.error("Error fetching user info:", error);
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    setIsLoading(true)
    getUserInfor()
  }, [profileUpdateTrigger, setValue])

  const onCreateUser = (data) => {
    console.log(data)
    const userData = {
      model: 'res.users',
      domain: [],
      values: {
        name: data?.name,
        phone: data?.phone,
        email: data?.email,
        login: data?.email,
        groups_id: data?.permission,
      },
    }
    UserService.createUser(userData)
      .then((userId) => {
        if (imageUpload) {
          const formData = new FormData()
          formData.append('ufile', imageUpload)
          const queryParams = {
            model: 'res.users',
            id: userId[0],
            field: 'image_1920',
          }
          UserService.setImage(formData, queryParams)
            .then(() => {
              setImageUpload(false)
              CustomToastMessage.success('sucess')
            })
            .catch((err) => {
            })
        }
      })
      .catch((err) => {
        console.log('🚀 ~ onImageUpload ~ err:', err)
      })
  }

  const onUpdateUser = (data) => {
    console.log(data)
    const userData = {
      model: 'res.users',
      domain: [['id', '=', parseInt(data?.edit_id)]],
      values: {
        name: data?.edit_name,
        phone: data?.edit_phone,
        email: data?.edit_email,
        password: data?.edit_password,
        groups_id: [data?.edit_permission],
      },
    }
    UserService.updateUser(userData)
      .then((userId) => {
        // console.log(userId);
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return {
    onUpdateProfile,
    onImageUpload,
    register,
    handleSubmit,
    setValue,
    getValues,
    imageSrc,
    setImageSrc,
    imageUpload,
    setImageUpload,
    colorPalette,
    onCreateUser,
    onUpdateUser,
    getUserInfor,
    reset,
    isUpdatingProfile
  }
}

export default useUserSubmit
