import React from 'react'

const Tag = ({ text, className }) => {
    return (
        <div className='absolute top-0 right-0 size-[160px] overflow-hidden z-[10]'>
            <span className={`w-[140%] bg-red-500 text-center select-none text-white uppercase font-semibold text-lg rotate-45 py-2 absolute left-[-10%] top-[25%] ${className}`}>{text}</span>
        </div>
    )
}

export default Tag
